import { BaseModel, User } from './index';
import { City } from './places';

export enum CityTeamMemberType {
  SUPERVISOR = 'supervisor',
  MANAGER = 'manager',
}

export type CityTeamMember = BaseModel & {
  user: User;
  city: City;
  type: CityTeamMemberType;
}
