import { NewBroadcastNotificationInput } from '../redux/reducers/views/notifications/SendNotificationsReducer';
import { apolloClient } from '../clients/ApolloClient';
import { gql } from '@apollo/client';

export const NEW_BROADCAST_NOTIFICATION = gql`
  mutation NewBroadcastNotification($input: NewBroadcastNotificationInput!) {
    broadcastNotification(input: $input) {
      status
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation CreateNotification($input: NotificationInput!) {
    createNotification(input: $input) {
      id
      title
      message
    }
  }
`;

export type UserNotificationInput = {
  user: string;
  title: string;
  message: string;
}

export class NotificationService {
  static broadcast(input: NewBroadcastNotificationInput): Promise<void> {
    return apolloClient.mutate<{ broadcastNotification: { status: string } }>({
      mutation: NEW_BROADCAST_NOTIFICATION,
      variables: { input },
    }).then();
  }

  static sendToUser(input: UserNotificationInput): Promise<void> {
    return apolloClient.mutate<{ sendNotification: Notification }>({
      mutation: CREATE_NOTIFICATION,
      variables: { input },
    }).then();
  }
}
