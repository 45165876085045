import { all, call, cancel, cancelled, fork, put, take, takeLatest } from 'redux-saga/effects';
import { Task } from 'redux-saga';
import {
  USER_LOCKED_ACTION_CANCEL,
  USER_LOCKED_ACTION_CONFIRMED, USER_LOCKED_LOCK_START, USER_LOCKED_UNLOCK_START,
} from '../../../../constants/views/users/components/UserLockConstants';
import {
  askUserLockedConfirmation,
  dismissUserLockedActionConfirmationModal, userLockedActionErrored, userLockedActionSucceeded,
} from '../../../../actions/views/users/components/UserLockedAction';
import { UserService } from '../../../../../services/UserService';
import { User } from '../../../../../models';

function handleUserLockedAction(apiCall: (userId: string) => Promise<User | undefined>, successMessage: string) {
  return function* () {
    try {
      yield put(askUserLockedConfirmation());
      let wasSuccessful = false;
      while (!wasSuccessful) {
        const { userId } = yield take(USER_LOCKED_ACTION_CONFIRMED);
        yield call(apiCall, userId);
        yield put(dismissUserLockedActionConfirmationModal());
        yield put(userLockedActionSucceeded(successMessage));
        wasSuccessful = true;
      }
    } catch (e) {
      yield put(userLockedActionErrored());
    } finally {
      const isCancelled: boolean = yield cancelled();
      if (isCancelled) {
        yield put(dismissUserLockedActionConfirmationModal());
      }
    }
  };
}

function* lockUserSaga() {
  const task: Task = yield fork(handleUserLockedAction(
    UserService.lock,
    'notification.user_locked.locked_successfully'),
  );
  yield take(USER_LOCKED_ACTION_CANCEL);
  yield cancel(task);
}

function* unlockUserSaga() {
  const task: Task = yield fork(handleUserLockedAction(
    UserService.unlock,
    'notification.user_locked.unlocked_successfully',
  ));
  yield take(USER_LOCKED_ACTION_CANCEL);
  yield cancel(task);
}

export default function* userLockedSagas() {
  yield all([
    takeLatest(USER_LOCKED_LOCK_START, lockUserSaga),
    takeLatest(USER_LOCKED_UNLOCK_START, unlockUserSaga),
  ]);
}
