import { env } from '../configs/EnvironmentConfig';
import { ApolloClient, createHttpLink, from, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { AUTH_TOKEN } from '../redux/constants/Auth';
import { onError } from '@apollo/client/link/error';
import store from '../redux/store';
import { unauthorizedError } from '../redux/actions';
import { GRAPHQL_PATH } from '../configs/AppConfig';

const httpLink = createHttpLink({
  uri: `${env.API_ENDPOINT_URL}${GRAPHQL_PATH}`,
});

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem(AUTH_TOKEN);
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const logoutLink = onError(({ graphQLErrors}) => {
  if (graphQLErrors !== undefined) {
    for (let err of graphQLErrors) {
      if (err.message === 'Unauthorized') {
        store.dispatch(unauthorizedError(window.location.pathname));
      }
    }
  }
});

const links = from([
  authLink,
  logoutLink,
  httpLink,
]);

export const apolloClient = new ApolloClient({
  link: links,
  cache: new InMemoryCache(),
});
