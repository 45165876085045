import {
  ADD_CITY_AUTOCOMPLETE_RESPONDED,
  ADD_CITY_DISMISS_MODAL,
  ADD_CITY_NEW_CITY_ENDED_WITH_ERRORS,
  ADD_CITY_NEW_CITY_SUCCEEDED,
  ADD_CITY_OPEN_MODAL,
  ADD_CITY_SUBMIT_NEW_CITY,
} from '../../../constants/views/components/AddCityModalViewConstants';
import { AutocompletePlaceResponse } from '../../../../views/app-views/cities/components/AddCityModal';

export type AddCityModalViewAction = ReturnType<typeof openNewCityModal
  | typeof dismissNewCityModal
  | typeof addNewCityFromPlaceResponse
  | typeof addNewCityEndedWithErrors
  | typeof addNewCityPlaceResponded
  | typeof addNewCitySucceeded>

export function openNewCityModal() {
  return <const>{
    type: ADD_CITY_OPEN_MODAL,
    visible: true,
  };
}

export function dismissNewCityModal() {
  return <const>{
    type: ADD_CITY_DISMISS_MODAL,
    visible: false,
    submitting: false,
  };
}

export function addNewCityPlaceResponded(response: AutocompletePlaceResponse) {
  return <const>{
    type: ADD_CITY_AUTOCOMPLETE_RESPONDED,
    autocompletePlaceResponse: response,
  };
}

export function addNewCityFromPlaceResponse(response: AutocompletePlaceResponse) {
  return <const>{
    type: ADD_CITY_SUBMIT_NEW_CITY,
    autocompletePlaceResponse: response,
    submitting: true,
  };
}

export function addNewCitySucceeded() {
  return <const>{
    type: ADD_CITY_NEW_CITY_SUCCEEDED,
    autocompletePlaceResponse: null,
    submitting: false,
  };
}

export function addNewCityEndedWithErrors(error: string) {
  return <const>{
    type: ADD_CITY_NEW_CITY_ENDED_WITH_ERRORS,
    errorMessage: error,
    submitting: false,
  };
}
