import { ExportTask } from '../models/exportIssues';
import { apolloClient } from '../clients/ApolloClient';
import { CREATE_EXPORT_TASK, GET_EXPORT_TASK, GET_EXPORT_TASKS } from '../graphql/exportIssues';
import { FETCH_POLICY_CACHE_FIRST, FETCH_POLICY_NETWORK_ONLY } from '../redux/reducers/Data';

export class ExportTaskService {
  static get(id: string, cached = true): Promise<ExportTask | null> {
    const fetchPolicy = cached ? FETCH_POLICY_CACHE_FIRST : FETCH_POLICY_NETWORK_ONLY;

    return apolloClient.query<{ exportTask: ExportTask | null }>({
      query: GET_EXPORT_TASK,
      variables: { id },
      fetchPolicy,
    }).then(({ data }) => data?.exportTask)
  }

  static create(): Promise<ExportTask | undefined> {
    return apolloClient.mutate<{ createExportTask: ExportTask }>({
      mutation: CREATE_EXPORT_TASK,
      refetchQueries: [{ query: GET_EXPORT_TASKS }],
    }).then(({ data }) => data?.createExportTask);
  }
}
