import {
  USER_ROLE_DISMISS_EDIT,
  USER_ROLE_START_EDIT, USER_ROLE_SUBMIT, USER_ROLE_SUBMIT_FAILED, USER_ROLE_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/users/components/UserRoleConstants';
import { UserRole } from '../../../../../models';

export type UserRoleAction = ReturnType<typeof startUserRoleEdit
  | typeof dismissUserRoleEdit
  | typeof submitNewUserRole
  | typeof submitNewUserRoleSucceeded
  | typeof submitNewUserRoleFailed>

export function startUserRoleEdit() {
  return <const>{
    type: USER_ROLE_START_EDIT,
  }
}

export function dismissUserRoleEdit() {
  return <const>{
    type: USER_ROLE_DISMISS_EDIT,
  }
}
export function submitNewUserRole(userId: string, newRole: UserRole) {
  return <const>{
    type: USER_ROLE_SUBMIT,
    userId,
    newRole,
  }
}

export function submitNewUserRoleSucceeded() {
  return <const>{
    type: USER_ROLE_SUBMIT_SUCCEEDED,
  }
}

export function submitNewUserRoleFailed() {
  return <const>{
    type: USER_ROLE_SUBMIT_FAILED,
  }
}
