import { gql } from '@apollo/client';

export const GET_ADMIN_USERS = gql`
  query GetAdminUsers {
    adminUsers {
      id
      name
      email
    }
  }
`;

export const GET_ADMIN_USER = gql`
  query GetAdminUser($id: ID!) {
    adminUser(id: $id) {
      id
      name
      email
    }
  }
`;
