import { CityTeamMembersComponentAction } from '../../../../actions/views/cities/components/CityTeamMembersComponentActions';
import {
  CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_ERRORED,
  CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_SUCCEEDED,
  CITY_TEAM_MEMBERS_ASK_DELETE_CONFIRMATION,
  CITY_TEAM_MEMBERS_CANCEL_DELETE,
  CITY_TEAM_MEMBERS_DELETE_ERRORED,
  CITY_TEAM_MEMBERS_DELETE_SUCCEEDED,
  CITY_TEAM_MEMBERS_DISMISS_ADD_TEAM_MEMBER,
  CITY_TEAM_MEMBERS_OPEN_ADD_TEAM_MEMBER,
  CITY_TEAM_MEMBERS_SUBMIT_ADD_TEAM_MEMBER,
} from '../../../../constants/views/cities/components/CityTeamMembersComponentConstants';
import {
  ADD_TEAM_MEMBER_MODAL_NAME,
  CONFIRM_DELETE_TEAM_MEMBER_MODAL_NAME,
} from '../../../../../views/app-views/cities/components/CityTeamMembersComponent';
import { CityTeamMemberInput } from '../../../../../graphql/teamMembers';
import { CityTeamMember } from '../../../../../models/teamMembers';
import { ROUTER_LOCATION_CHANGE } from '../../../../Router';

export type CityTeamMembersComponentState = {
  visibleModal: typeof ADD_TEAM_MEMBER_MODAL_NAME
    | typeof CONFIRM_DELETE_TEAM_MEMBER_MODAL_NAME
    | null;
  submitting: boolean;
  input: CityTeamMemberInput | null;
  errorMessage: string | null;
  cityTeamMemberToDelete: CityTeamMember | null;
}

const initState: CityTeamMembersComponentState = {
  visibleModal: null,
  submitting: false,
  input: null,
  errorMessage: null,
  cityTeamMemberToDelete: null,
};

const cityTeamMembersComponentReducer = (state = initState, action: CityTeamMembersComponentAction): CityTeamMembersComponentState => {
  switch (action.type) {
    case CITY_TEAM_MEMBERS_OPEN_ADD_TEAM_MEMBER:
      return {
        ...state,
        visibleModal: action.visibleModal,
      };
    case CITY_TEAM_MEMBERS_DISMISS_ADD_TEAM_MEMBER:
      return {
        ...state,
        visibleModal: action.visibleModal,
        errorMessage: action.errorMessage,
        input: action.input,
      };
    case CITY_TEAM_MEMBERS_SUBMIT_ADD_TEAM_MEMBER:
      return {
        ...state,
        submitting: action.submitting,
        input: action.input,
        errorMessage: action.errorMessage,
      };
    case CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_SUCCEEDED:
      return {
        ...state,
        submitting: action.submitting,
        input: action.input,
      };
    case CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
        errorMessage: action.errorMessage,
      };
    case CITY_TEAM_MEMBERS_ASK_DELETE_CONFIRMATION:
    case CITY_TEAM_MEMBERS_CANCEL_DELETE:
      return {
        ...state,
        visibleModal: action.visibleModal,
        cityTeamMemberToDelete: action.cityTeamMemberToDelete,
      };
    case CITY_TEAM_MEMBERS_DELETE_SUCCEEDED:
      return {
        ...state,
        submitting: action.submitting,
      }
    case CITY_TEAM_MEMBERS_DELETE_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
      }
    case ROUTER_LOCATION_CHANGE:
      return {
        ...initState,
      }
    default:
      return state;
  }
};

export default cityTeamMembersComponentReducer;
