import { NormativeDetailAction } from '../../actions';
import {
  NORMATIVE_DETAIL_CANCEL_NAME_EDIT,
  NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_CHANGED, NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_RESET,
  NORMATIVE_DETAIL_ISSUE_GROUP_VISIBILITY_CHANGED,
  NORMATIVE_DETAIL_START_NAME_EDIT,
  NORMATIVE_DETAIL_SUBMIT_ERROR,
  NORMATIVE_DETAIL_SUBMIT_NAME,
  NORMATIVE_DETAIL_SUBMIT_SUCCESSFUL,
  NORMATIVE_FETCHED,
} from '../../constants/views/NormativeDetailConstants';
import { IssueGroupVisibility, Normative } from '../../../models';
import { FORM_SUBMISSION_ENDED_WITH_ERRORS, FORM_SUBMISSION_SUCCEEDED } from '../../constants/Form';

export type NewIssueGroupInput = {
  normative: string,
  name: string,
  icon: File,
}

export type NormativeDetailState = {
  submitting: boolean,
  editName: boolean,
  errorMessage: string | null,
  normative: Normative | null,
  data: NewIssueGroupInput | null,
  issueGroupFilter?: string,
  issueGroupsVisibility: IssueGroupVisibility,
};

const initState: NormativeDetailState = {
  submitting: false,
  editName: false,
  errorMessage: null,
  normative: null,
  data: null,
  issueGroupFilter: undefined,
  issueGroupsVisibility: IssueGroupVisibility.ONLY_VISIBLE,
};

const normativeDetailReducer = (state = initState, action: NormativeDetailAction): NormativeDetailState => {
  switch (action.type) {
    case NORMATIVE_FETCHED:
      return {
        ...state,
        normative: action.normative,
      };
    case NORMATIVE_DETAIL_START_NAME_EDIT:
      return {
        ...state,
        editName: true,
      };
    case NORMATIVE_DETAIL_CANCEL_NAME_EDIT:
      return {
        ...state,
        editName: false,
      };
    case NORMATIVE_DETAIL_SUBMIT_NAME:
      return {
        ...state,
        submitting: true,
        editName: true,
      };
    case NORMATIVE_DETAIL_SUBMIT_SUCCESSFUL:
      return {
        ...state,
        submitting: false,
        editName: false,
      };
    case NORMATIVE_DETAIL_SUBMIT_ERROR:
      return {
        ...state,
        submitting: false,
      };
    case FORM_SUBMISSION_SUCCEEDED:
      return {
        ...state,
        data: null,
        submitting: false,
      }
    case FORM_SUBMISSION_ENDED_WITH_ERRORS:
      return {
        ...state,
        errorMessage: action.error,
        submitting: action.submitting,
      }
    case NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_CHANGED:
      return {
        ...state,
        issueGroupFilter: action.issueGroupFilter,
      }
    case NORMATIVE_DETAIL_ISSUE_GROUP_VISIBILITY_CHANGED:
      return {
        ...state,
        issueGroupsVisibility: action.showAllIssueGroups,
        issueGroupFilter: undefined,
      }
    case NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_RESET:
      return {
        ...state,
        issueGroupFilter: undefined,
      }
    default:
      return {
        ...state,
      };
  }
};

export default normativeDetailReducer;
