import { Image } from '../../models';
import { API_BASE_URL } from '../../configs/AppConfig';

type ImageProps = {
  image: Image;
  width?: number | string;
}

export const getAppImageUrl = (imagePath: string) => `${API_BASE_URL}${imagePath}`;

const AppImage = ({ image, ...rest }: ImageProps) => {
  return (
    <img src={`${getAppImageUrl(image.path)}`}  alt={image.filename} {...rest} />
  )
}

export default AppImage;
