import { all, call, cancel, cancelled, fork, put, take, takeEvery } from 'redux-saga/effects';
import {
  CITY_NORMATIVE_INFO_EDIT_CANCEL, CITY_NORMATIVE_INFO_EDIT_CONFIRM, CITY_NORMATIVE_INFO_EDIT_DISMISS_CONFIRMATION,
  CITY_NORMATIVE_INFO_EDIT_START, CITY_NORMATIVE_INFO_EDIT_SUBMIT,
} from '../../../../constants/views/cities/components/CityNormativeInfo';
import { Task } from 'redux-saga';
import { LOCATION_CHANGE } from 'connected-react-router';
import {
  askConfirmationCityNormativeInfoEdit,
  confirmCityNormativeInfoEdit,
  dismissCityNormativeInfoEdit,
  dismissConfirmationCityNormativeInfoEdit,
  submitCityNormativeInfoEdit, submitCityNormativeInfoEditFailed,
  submitCityNormativeInfoEditSucceeded,
} from '../../../../actions/views/cities/components/CityNormativeInfoAction';
import { CityService } from '../../../../../services/CityService';

type ConfirmationAction = ReturnType<typeof confirmCityNormativeInfoEdit | typeof dismissConfirmationCityNormativeInfoEdit>;

function* handleCityNormativeInfoEdition() {
  let wasSuccessful = false;
  try {
    while (!wasSuccessful) {
      const { cityId, normativeId }: ReturnType<typeof submitCityNormativeInfoEdit> = yield take(CITY_NORMATIVE_INFO_EDIT_SUBMIT);
      yield put(askConfirmationCityNormativeInfoEdit());
      const { type: confirmation }: ConfirmationAction = yield take([CITY_NORMATIVE_INFO_EDIT_CONFIRM, CITY_NORMATIVE_INFO_EDIT_DISMISS_CONFIRMATION]);
      if (confirmation === CITY_NORMATIVE_INFO_EDIT_CONFIRM) {
        try {
          yield call(CityService.edit, { id: cityId, normative: normativeId });
          yield put(submitCityNormativeInfoEditSucceeded());
          yield put(dismissCityNormativeInfoEdit());
          wasSuccessful = true;
        } catch (e) {
          yield put(submitCityNormativeInfoEditFailed());
        } finally {
          yield put(dismissConfirmationCityNormativeInfoEdit());
        }
      }
    }
  } finally {
    yield put(dismissConfirmationCityNormativeInfoEdit());
    let isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put(dismissCityNormativeInfoEdit());
    }
  }
}

function* editCityNormativeInfoSaga() {
  const task: Task = yield fork(handleCityNormativeInfoEdition);
  yield take([CITY_NORMATIVE_INFO_EDIT_CANCEL, LOCATION_CHANGE]);
  yield cancel(task);
}

export default function* cityNormativeInfoSagas() {
  yield all([
    fork(function* () {
      yield takeEvery(CITY_NORMATIVE_INFO_EDIT_START, editCityNormativeInfoSaga);
    }),
  ]);
}
