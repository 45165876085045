import {
  CITY_NAME_EDIT_DISMISS,
  CITY_NAME_EDIT_START, CITY_NAME_EDIT_SUBMIT,
  CITY_NAME_EDIT_SUBMIT_FAILED, CITY_NAME_EDIT_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/cities/components/CityName';

export type CityNameAction = ReturnType<typeof startCityNameEdit
  | typeof submitCityNameEdit
  | typeof submitCityNameEditSucceeded
  | typeof submitCityNameEditFailed
  | typeof dismissCityNameEdit>

export function startCityNameEdit() {
  return <const>{
    type: CITY_NAME_EDIT_START,
  };
}

export function submitCityNameEdit(cityId: string, newName: string) {
  return <const>{
    type: CITY_NAME_EDIT_SUBMIT,
    cityId,
    newName,
  };
}

export function submitCityNameEditSucceeded() {
  return <const>{
    type: CITY_NAME_EDIT_SUBMIT_SUCCEEDED,
  };
}

export function submitCityNameEditFailed() {
  return <const>{
    type: CITY_NAME_EDIT_SUBMIT_FAILED,
  };
}

export function dismissCityNameEdit() {
  return <const>{
    type: CITY_NAME_EDIT_DISMISS,
  };
}
