import {
  ISSUES_STATS_FETCH_COMPLETED,
  ISSUES_STATS_FETCH_FAILED,
  ISSUES_STATS_FETCH_START,
} from '../../constants/stats/issuesStats';
import { IssuesCount } from '../../reducers/stats/issuesStatsReducer';

export type IssuesStatsAction = ReturnType<typeof fetchIssuesStatsStart
  | typeof fetchIssuesStatsCompleted
  | typeof fetchIssuesStatsFailed>

export function fetchIssuesStatsStart() {
  return <const> {
    type: ISSUES_STATS_FETCH_START,
  }
}

export function fetchIssuesStatsCompleted(issuesCount: IssuesCount) {
  return <const> {
    type: ISSUES_STATS_FETCH_COMPLETED,
    issuesCount,
  }
}

export function fetchIssuesStatsFailed() {
  return <const> {
    type: ISSUES_STATS_FETCH_FAILED,
  }
}
