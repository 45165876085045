import { AddCityModalViewAction } from '../../../actions/views/components/AddCityModalViewActions';
import {
  ADD_CITY_AUTOCOMPLETE_RESPONDED,
  ADD_CITY_DISMISS_MODAL,
  ADD_CITY_NEW_CITY_ENDED_WITH_ERRORS, ADD_CITY_NEW_CITY_SUCCEEDED,
  ADD_CITY_OPEN_MODAL,
  ADD_CITY_SUBMIT_NEW_CITY,
} from '../../../constants/views/components/AddCityModalViewConstants';
import { AutocompletePlaceResponse } from '../../../../views/app-views/cities/components/AddCityModal';

export type AddCityModalViewState = {
  visible: boolean;
  submitting: boolean;
  errorMessage: string | null;
  autocompletePlaceResponse: AutocompletePlaceResponse | null;
}

const initState: AddCityModalViewState = {
  visible: false,
  submitting: false,
  errorMessage: null,
  autocompletePlaceResponse: null,
};

const addCityModalViewReducer = (state = initState, action: AddCityModalViewAction): AddCityModalViewState => {
  switch (action.type) {
    case ADD_CITY_OPEN_MODAL:
      return {
        ...state,
        visible: action.visible,
      }
    case ADD_CITY_DISMISS_MODAL:
      return {
        ...state,
        visible: action.visible,
        submitting: action.submitting,
        errorMessage: null,
      }
    case ADD_CITY_AUTOCOMPLETE_RESPONDED:
      return {
        ...state,
        autocompletePlaceResponse: action.autocompletePlaceResponse,
      }
    case ADD_CITY_SUBMIT_NEW_CITY:
      return {
        ...state,
        submitting: action.submitting,
        autocompletePlaceResponse: action.autocompletePlaceResponse,
        errorMessage: null,
      }
    case ADD_CITY_NEW_CITY_ENDED_WITH_ERRORS:
      return {
        ...state,
        submitting: action.submitting,
        errorMessage: action.errorMessage,
      }
    case ADD_CITY_NEW_CITY_SUCCEEDED:
      return {
        ...state,
        autocompletePlaceResponse: action.autocompletePlaceResponse,
        submitting: action.submitting,
        errorMessage: null,
      }
    default:
      return state;
  }
};

export default addCityModalViewReducer;
