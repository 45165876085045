import { ResetPasswordAction } from '../../actions/auth/ResetPasswordAction';
import {
  AUTH_RESET_PASSWORD_CHECK_TOKEN,
  AUTH_RESET_PASSWORD_CHECK_TOKEN_ERROR,
  AUTH_RESET_PASSWORD_CHECK_TOKEN_RESULT,
  AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD,
  AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_FAILED,
  AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCEEDED,
} from '../../constants/Auth';

export enum ResetPasswordTokenStatus {
  IDLE = 'idle',
  CHECKING = 'checking',
  OK = 'ok',
  NOT_VALID = 'not_valid',
  ERROR = 'error',
  SUBMITTING = 'submitting',
}

type ResetPasswordState = {
  status: ResetPasswordTokenStatus,
}

const initState: ResetPasswordState = {
  status: ResetPasswordTokenStatus.IDLE,
};

export const resetPasswordReducer = (state = initState, action: ResetPasswordAction): ResetPasswordState => {
  switch (action.type) {
    case AUTH_RESET_PASSWORD_CHECK_TOKEN:
      return {
        ...state,
        status: ResetPasswordTokenStatus.CHECKING,
      };
    case AUTH_RESET_PASSWORD_CHECK_TOKEN_RESULT:
      return {
        ...state,
        status: action.status,
      };
    case AUTH_RESET_PASSWORD_CHECK_TOKEN_ERROR:
      return {
        ...state,
        status: ResetPasswordTokenStatus.ERROR,
      }
    case AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD:
      return {
        ...state,
        status: ResetPasswordTokenStatus.SUBMITTING,
      }
    case AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCEEDED:
    case AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_FAILED:
      return {
        ...state,
        status: ResetPasswordTokenStatus.OK,
      }
    default:
      return {
        ...state,
      };
  }
};
