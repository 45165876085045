import { combineReducers } from 'redux';
import normativeDetailReducer from './NormativeDetailReducer';
import addCityModalViewReducer from './components/AddCityModalViewReducer';
import cityTeamMembersComponentReducer from './cities/components/CityTeamMembersComponentReducer';
import addNormativeReducer from './normative/components/AddNormativeReducer';
import issueTypesComponentReducer from './normative/components/IssueTypesComponentReducer';
import issueGroupsComponentReducer from './normative/components/IssueGroupsReducer';
import issueDetailReducer from './issues/IssueDetailReducer';
import sendNotificationsReducer from './notifications/SendNotificationsReducer';
import profileSettingsReducer from './profile/ProfileSettingsReducers';
import adminUsersListReducer from './settings/AdminUsersListReducer';
import usersReducers from './users';
import citiesReducers from './cities';
import issuesReducers from './issues';

const viewsReducers = combineReducers({
  normativeDetail: normativeDetailReducer,
  addCityModalView: addCityModalViewReducer,
  cityTeamMembersComponent: cityTeamMembersComponentReducer,
  addNormativeComponent: addNormativeReducer,
  issueTypesComponent: issueTypesComponentReducer,
  issueGroupsComponent: issueGroupsComponentReducer,
  issueDetailView: issueDetailReducer,
  sendNotificationsView: sendNotificationsReducer,
  profileSettings: profileSettingsReducer,
  issues: issuesReducers,
  users: usersReducers,
  adminUsersList: adminUsersListReducer,
  cities: citiesReducers,
});

export default viewsReducers;
