import { BaseModel } from './index';

export enum ExportTaskState {
  CREATED = 'created',
  IN_PROGRESS = 'in_progress',
  COMPLETED = 'completed',
}

export type ExportTaskProgress = {
  actual: number;
  total: number;
}

export type ExportTask = BaseModel & {
  state: ExportTaskState;
  progress: ExportTaskProgress;
}
