import {
  APP_USER_FETCHED,
  APP_USER_NOT_LOGGED_IN,
  AUTHENTICATED,
  CHECK_WHO_AM_I,
  HIDE_AUTH_MESSAGE,
  SHOW_AUTH_MESSAGE,
  SHOW_LOADING,
  SIGNIN,
  SIGNOUT,
  SIGNOUT_SUCCESS,
  SIGNUP,
  SIGNUP_SUCCESS,
  UNAUTHORIZED,
} from '../constants/Auth';
import { User } from '../../models';

export const checkWhoAmI = () => {
  return <const>{
    type: CHECK_WHO_AM_I,
  };
};

export const appUserFetched = (user: User) => {
  return <const>{
    type: APP_USER_FETCHED,
    user,
  };
};

export const appUserNotLoggedIn = () => {
  return <const>{
    type: APP_USER_NOT_LOGGED_IN,
  };
};

export const signIn = (user: User) => {
  return <const>{
    type: SIGNIN,
    payload: user,
  };
};

export const authenticated = (token: string, user: User) => {
  return <const>{
    type: AUTHENTICATED,
    token,
    user,
  };
};

export const signOut = () => {
  return <const>{
    type: SIGNOUT,
  };
};

export const signOutSuccess = () => {
  return <const>{
    type: SIGNOUT_SUCCESS,
  };
};

export const signUp = (user: User) => {
  return <const>{
    type: SIGNUP,
    payload: user,
  };
};

export const signUpSuccess = (token: string) => {
  return <const>{
    type: SIGNUP_SUCCESS,
    token,
  };
};

export const showAuthMessage = (message: string) => {
  return <const>{
    type: SHOW_AUTH_MESSAGE,
    message,
  };
};

export const hideAuthMessage = () => {
  return <const>{
    type: HIDE_AUTH_MESSAGE,
  };
};

export const showLoading = () => {
  return <const>{
    type: SHOW_LOADING,
  };
};

export const unauthorizedError = (redirect: string) => {
  return <const>{
    type: UNAUTHORIZED,
    redirect,
  };
};

