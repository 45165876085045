import { IssueType, IssueTypeVisibility } from '../models';
import { FetchPolicy, gql } from '@apollo/client/core';
import { apolloClient } from '../clients/ApolloClient';

const ISSUE_TYPE_FRAGMENT = gql`
  fragment IssueTypeFragment on IssueType {
    id 
    code
    title
    information
    issueGroup {
      id
      name
    }
  }
`;

export const GET_NORMATIVE_ISSUE_TYPES = gql`
  ${ISSUE_TYPE_FRAGMENT}

  query GetNormativeIssueTypes($normativeId: ID!, $visibility: IssueTypeVisibility) {
    normativeIssueTypes(normativeId: $normativeId, visibility: $visibility) {
      ...IssueTypeFragment
    }
  }
`;

export const ADD_ISSUE_TYPE = gql`
  ${ISSUE_TYPE_FRAGMENT}
  
  mutation CreateIssueType($input: IssueTypeInput!) {
    addIssueType(input: $input) {
      ...IssueTypeFragment
    }
  }
`;

export const EDIT_ISSUE_TYPE = gql`
  ${ISSUE_TYPE_FRAGMENT}
  
  mutation EditIssueType($id: ID!, $input: IssueTypeInput!) {
    editIssueType(id: $id, input: $input) {
      ...IssueTypeFragment
    }
  }
`;

export const REMOVE_ISSUE_TYPE = gql`
  mutation RemoveIssueType($id: ID!) {
    removeIssueType(id: $id) {
      id
    }
  }
`;

export type IssueTypeInput = {
  issueGroupId: string;
  code: string;
  title: string;
  information: string;
}

export class IssueTypeService {
  static add(input: IssueTypeInput, normativeId: string): Promise<IssueType | null> {
    return apolloClient.mutate<{ addIssueType: IssueType }>({
      mutation: ADD_ISSUE_TYPE,
      variables: { input },
      refetchQueries: [
        { query: GET_NORMATIVE_ISSUE_TYPES, variables: { normativeId } },
      ],
    }).then(({ data }) => data?.addIssueType ?? null);
  }

  static edit(id: string, input: IssueTypeInput): Promise<IssueType | null> {
    return apolloClient.mutate<{ editIssueType: IssueType | null }>({
      mutation: EDIT_ISSUE_TYPE,
      variables: { id, input },
    }).then(({ data }) => data?.editIssueType ?? null);
  }

  static getNormativeIssueTypes(normativeId: string, visibility?: IssueTypeVisibility, fetchPolicy: FetchPolicy = 'cache-first'): Promise<IssueType[]> {
    return apolloClient.query<{ getNormativeIssueTypes: IssueType[] }>({
      query: GET_NORMATIVE_ISSUE_TYPES,
      variables: { normativeId, visibility },
      fetchPolicy,
    }).then(({ data }) => data?.getNormativeIssueTypes ?? []);
  }

  static removeNormativeIssueType(issueTypeId: string, normativeId: string): Promise<void> {
    return apolloClient.mutate<{ removeIssueType: IssueType }>({
      mutation: REMOVE_ISSUE_TYPE,
      variables: { id: issueTypeId },
      refetchQueries: [{ query: GET_NORMATIVE_ISSUE_TYPES, variables: { normativeId } }],
    }).then((result) => console.log(result));
  }
}
