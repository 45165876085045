import { gql } from '@apollo/client';

export const APP_ALERT_LIST_FRAGMENT = gql`
  fragment AppAlertData on AppAlert {
    id
    subject
    at
    read
    type
  }
`;

export const GET_APP_ALERTS_UNREAD = gql`
  ${APP_ALERT_LIST_FRAGMENT}
  
  query GetAppAlertsUnread {
    appAlertsUnread {
      ...AppAlertData
    }
  }
`;

export const GET_APP_ALERTS_READ = gql`
  ${APP_ALERT_LIST_FRAGMENT}
  
  query GetAppAlertsRead {
    appAlertsRead {
      ...AppAlertData
    }
  }
`;

export const GET_APP_ALERT = gql`
  query GetAppAlert($id: ID!) {
    appAlert(id: $id) {
      id
      subject
      message
      at
      read
      type
      archived
    }
  }
`;

export const GET_UNREAD_APP_ALERTS_COUNT = gql`
  query GetAppAlertsUnreadCount {
    appAlertsUnreadCount {
      unread
    }
  } 
`;

export const MARK_ALERT_AS_READ = gql`
  mutation MarkAlertAsRead($id: ID!) {
    markAlertAsRead(id: $id) {
      id
      read
    }
  }
`;

export const MARK_MANY_ALERTS_AS_READ = gql`
  mutation MarkManyAlertAsRead($ids: [ID!]!) {
    markManyAlertsAsRead(ids: $ids) {
      id
      read
    }
  }
`;

export const MARK_MANY_ALERTS_AS_UNREAD = gql`
  mutation MarkManyAlertAsUnread($ids: [ID!]!) {
    markManyAlertsAsUnread(ids: $ids) {
      id
      read
    }
  }
`;

export const DELETE_MANY_ALERTS = gql`
  mutation DeleteManyAlerts($ids: [ID!]!) {
    deleteManyAlerts(ids: $ids) {
      deleted
    }
  }
`;
