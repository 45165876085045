import {
  ADD_CITY_TEAM_MEMBER,
  CityTeamMemberInput,
  DELETE_CITY_TEAM_MEMBER, EDIT_CITY_TEAM_MEMBER,
  GET_CITY_TEAM_MEMBERS, GET_USER_CITY_TEAM_MEMBERSHIPS,
} from '../graphql/teamMembers';
import { CityTeamMember } from '../models/teamMembers';
import { apolloClient } from '../clients/ApolloClient';

export class CityTeamMemberService {
  static add(input: CityTeamMemberInput): Promise<CityTeamMember | undefined> {
    return apolloClient.mutate<{ addCityTeamMember: CityTeamMember }>({
      mutation: ADD_CITY_TEAM_MEMBER,
      variables: { input },
      refetchQueries: [
        {
          query: GET_CITY_TEAM_MEMBERS,
          variables: { city: input.city },
        },
        {
          query: GET_USER_CITY_TEAM_MEMBERSHIPS,
          variables: { userId: input.user },
        },
      ],
    })
      .then(({ data }) => data?.addCityTeamMember);
  }

  static delete(input: CityTeamMember): Promise<undefined> {
    return apolloClient.mutate({
      mutation: DELETE_CITY_TEAM_MEMBER,
      variables: { id: input.id },
      refetchQueries: [
        {
          query: GET_CITY_TEAM_MEMBERS,
          variables: { city: input.city.id },
        },
        {
          query: GET_USER_CITY_TEAM_MEMBERSHIPS,
          variables: { userId: input.user.id },
        },
      ],
    }).then(() => undefined);
  }

  static edit(id: string, input: CityTeamMemberInput): Promise<CityTeamMember | undefined> {
    return apolloClient.mutate<{ editCityTeamMember: CityTeamMember }>({
      mutation: EDIT_CITY_TEAM_MEMBER,
      variables: { id, input },
      refetchQueries: [
        {
          query: GET_CITY_TEAM_MEMBERS,
          variables: { city: input.city },
        },
        {
          query: GET_USER_CITY_TEAM_MEMBERSHIPS,
          variables: { userId: input.user },
        },
      ],
    }).then(({ data }) => data?.editCityTeamMember);
  }
}
