import {
  ISSUE_TYPES_COMPONENT_DISMISS_TYPE_OPEN_MODAL,
  ISSUE_TYPES_COMPONENT_EDIT_EDIT_TYPE_DISMISS_MODAL,
  ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_ERRORED,
  ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_OPEN_MODAL,
  ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_SUCCEEDED,
  ISSUE_TYPES_COMPONENT_NEW_ISSUE_TYPE_SUCCEEDED,
  ISSUE_TYPES_COMPONENT_NEW_TYPE_OPEN_MODAL,
  ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE,
  ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE_ERRORED,
  ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE_SUCCEEDED,
  ISSUE_TYPES_COMPONENT_SUBMIT_EDIT_ISSUE_TYPE,
  ISSUE_TYPES_COMPONENT_SUBMIT_ISSUE_TYPE_ERRORED,
  ISSUE_TYPES_COMPONENT_SUBMIT_NEW_ISSUE_TYPE,
} from '../../../../constants/views/normative/components/IssueTypesComponent';
import { NEW_ISSUE_TYPE_MODAL_NAME } from '../../../../../views/app-views/issue-types/normatives/components/NewIssueType';
import { IssueTypeInput } from '../../../../../services/IssueTypeService';
import { EDIT_ISSUE_TYPE_MODAL_NAME } from '../../../../../views/app-views/issue-types/normatives/components/EditIssueType';
import { IssueType } from '../../../../../models';

export type IssueTypesAction = ReturnType<typeof openAddNewIssueTypeModal
  | typeof dismissAddNewIssueTypeModal
  | typeof submitNewIssueType
  | typeof newIssueTypeSucceeded
  | typeof newIssueTypeErrored
  | typeof dismissEditIssueTypeModal
  | typeof openEditIssueTypeModal
  | typeof submitEditedIssueType
  | typeof editIssueTypeSucceeded
  | typeof editIssueTypeErrored
  | typeof removeIssueTypeFromNormative
  | typeof removeIssueTypeFromNormativeSucceeded
  | typeof removeIssueTypeFromNormativeErrored>;

export function openAddNewIssueTypeModal() {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_NEW_TYPE_OPEN_MODAL,
    visibleModal: NEW_ISSUE_TYPE_MODAL_NAME,
    resetForm: false,
  };
}

export function dismissAddNewIssueTypeModal() {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_DISMISS_TYPE_OPEN_MODAL,
    visibleModal: null,
    resetForm: true,
  };
}

export function submitNewIssueType(input: IssueTypeInput, normativeId: string) {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_SUBMIT_NEW_ISSUE_TYPE,
    input,
    normativeId,
    submitting: true,
  };
}

export function newIssueTypeSucceeded() {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_NEW_ISSUE_TYPE_SUCCEEDED,
    submitting: false,
  };
}

export function newIssueTypeErrored(errorMessage: string) {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_SUBMIT_ISSUE_TYPE_ERRORED,
    submitting: false,
    errorMessage,
  };
}

export function openEditIssueTypeModal(issueType: IssueType) {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_OPEN_MODAL,
    visibleModal: EDIT_ISSUE_TYPE_MODAL_NAME,
    resetForm: false,
    selectedIssueType: issueType,
  };
}

export function dismissEditIssueTypeModal() {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_EDIT_EDIT_TYPE_DISMISS_MODAL,
    visibleModal: null,
    resetForm: true,
    selectedIssueType: null,
  };
}

export function submitEditedIssueType(id: string, input: IssueTypeInput) {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_SUBMIT_EDIT_ISSUE_TYPE,
    submitting: true,
    id,
    input,
  };
}

export function editIssueTypeSucceeded() {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_SUCCEEDED,
    submitting: false,
  };
}

export function editIssueTypeErrored(errorMessage: string) {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_ERRORED,
    submitting: false,
    errorMessage,
  };
}

export function removeIssueTypeFromNormative(issueTypeId: string, normativeId: string) {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE,
    issueTypeId,
    normativeId,
  };
}

export function removeIssueTypeFromNormativeSucceeded(successMessage?: string) {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE_SUCCEEDED,
    successMessage,
  };
}

export function removeIssueTypeFromNormativeErrored(errorMessage?: string) {
  return <const>{
    type: ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE_ERRORED,
    errorMessage,
  };
}
