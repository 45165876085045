import { gql } from '@apollo/client';

const CITY_MANAGEMENT_DATA_FRAGMENT = gql`
  fragment CityManagementDataProps on CityManagementData {
    id
    note
    contract {
      start
      end
    }
    contact {
      name
      email
      phone
      extension
    }
  }
`;

export const GET_CITY_MANAGEMENT_DATA = gql`
  ${CITY_MANAGEMENT_DATA_FRAGMENT}
  
  query GetCityManagementData($cityId: ID!) {
    cityManagementData(cityId: $cityId) {
      ...CityManagementDataProps
    }
  }
`;

export const EDIT_CITY_MANAGEMENT_DATA = gql`
  ${CITY_MANAGEMENT_DATA_FRAGMENT}
  
  mutation EditCityManagementData($id: ID!, $input: CityManagementDataInput!) {
    editCityManagementData(id: $id, input: $input) {
      ...CityManagementDataProps
    }
  }
`;
