import {
  ADD_NORMATIVE_DISMISS_MODAL, ADD_NORMATIVE_NEW_NORMATIVE_ERRORED, ADD_NORMATIVE_NEW_NORMATIVE_SUCCEEDED,
  ADD_NORMATIVE_OPEN_MODAL, ADD_NORMATIVE_SUBMIT_NEW_NORMATIVE,
} from '../../../../constants/views/normative/components/AddNormativeConstants';
import { NormativeInput } from '../../../../../graphql/normatives';

export type AddNormativeState = {
  modalVisible: boolean;
  submitting: boolean;
  normative: NormativeInput | null;
  errorMessage: string | null;
  resetForm: boolean;
}

const initState: AddNormativeState = {
  modalVisible: false,
  submitting: false,
  normative: null,
  errorMessage: null,
  resetForm: false,
}

const addNormativeReducer = (state = initState, action: any): AddNormativeState => {
  switch (action.type) {
    case ADD_NORMATIVE_OPEN_MODAL:
      return {
        ...state,
        modalVisible: action.modalVisible,
        resetForm: action.resetForm,
      }
    case ADD_NORMATIVE_DISMISS_MODAL:
      return {
        ...state,
        modalVisible: action.modalVisible,
        errorMessage: null,
        normative: null,
        resetForm: true,
      }
    case ADD_NORMATIVE_SUBMIT_NEW_NORMATIVE:
      return {
        ...state,
        submitting: action.submitting,
        normative: action.normative,
        errorMessage: action.errorMessage,
      }
    case ADD_NORMATIVE_NEW_NORMATIVE_SUCCEEDED:
      return {
        ...state,
        submitting: action.submitting,
        normative: action.normative,
      }
    case ADD_NORMATIVE_NEW_NORMATIVE_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
        errorMessage: action.errorMessage,
      }
    default:
      return {
        ...state,
      }
  }
}

export default addNormativeReducer;
