import {
  ADD_NORMATIVE_DISMISS_MODAL, ADD_NORMATIVE_NEW_NORMATIVE_ERRORED, ADD_NORMATIVE_NEW_NORMATIVE_SUCCEEDED,
  ADD_NORMATIVE_OPEN_MODAL, ADD_NORMATIVE_SUBMIT_NEW_NORMATIVE,
} from '../../../../constants/views/normative/components/AddNormativeConstants';
import { NormativeInput } from '../../../../../graphql/normatives';

export type AddNormativeAction = ReturnType<typeof openAddNormativeModal
  | typeof dismissAddNormativeModal
  | typeof submitNewNormative
  | typeof newNormativeErrored
  | typeof newNormativeSucceeded>

export function openAddNormativeModal() {
  return <const>{
    type: ADD_NORMATIVE_OPEN_MODAL,
    modalVisible: true,
    resetForm: false,
  }
}

export function dismissAddNormativeModal() {
  return <const>{
    type: ADD_NORMATIVE_DISMISS_MODAL,
    modalVisible: false,
    resetForm: true,
  }
}

export function submitNewNormative(input: NormativeInput) {
  return <const>{
    type: ADD_NORMATIVE_SUBMIT_NEW_NORMATIVE,
    submitting: true,
    normative: input,
    errorMessage: null,
  }
}

export function newNormativeErrored(errorMessage: string) {
  return <const>{
    type: ADD_NORMATIVE_NEW_NORMATIVE_ERRORED,
    submitting: false,
    errorMessage,
  }
}

export function newNormativeSucceeded() {
  return <const>{
    type: ADD_NORMATIVE_NEW_NORMATIVE_SUCCEEDED,
    submitting: false,
    normative: null,
  }
}
