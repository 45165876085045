import { Button, Modal, Select } from 'antd';
import { AuditOutlined } from '@ant-design/icons';
import IntlMessage from '../../../../components/util-components/IntlMessage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { CityNormativeInfoStatus } from '../../../../redux/reducers/views/cities/components/CityNormativeInfo';
import {
  cancelCityNormativeInfoEdit, confirmCityNormativeInfoEdit, dismissConfirmationCityNormativeInfoEdit,
  startCityNormativeInfoEdit, submitCityNormativeInfoEdit,
} from '../../../../redux/actions/views/cities/components/CityNormativeInfoAction';
import { useQuery } from '@apollo/client';
import { Normative } from '../../../../models';
import { GET_NORMATIVES } from '../../../../graphql/normatives';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { appRoutes } from '../../../../configs/AppRoutes';
import SidebarInfoRow from '../../../../components/shared-components/SidebarInfoRow';

export const ASSIGN_CITY_NORMATIVE_CONFIRMATION_MODAL = 'modal.assignCityNormativeConfirmation';

const EditCityNormativeInfo = ({ cityId }: Pick<CityNormativeInfoProps, 'cityId'>) => {
  const dispatch = useDispatch();
  const { data } = useQuery<{ normatives: Normative[] }>(GET_NORMATIVES);
  const [normative, setNormative] = useState<string | undefined>();
  const { visibleModal } = useSelector((state: RootState) => state.modal);
  const { submitting } = useSelector((state: RootState) => state.views.cities.detail.cityNormative);

  const submit = () => {
    if (normative) {
      dispatch(submitCityNormativeInfoEdit(cityId, normative));
    }
  };

  return (
    <>
      <div className='mb-2'>
        <Select style={{ width: '100%' }}
                onSelect={(value) => {
                  setNormative(value ? String(value) : undefined);
                }}>
          {(data?.normatives || []).map((normative) => (
            <Select.Option value={normative.id} key={normative.id}>{normative.name}</Select.Option>
          ))}
        </Select>
      </div>
      <div className='text-right'>
        <Button type='primary' onClick={() => submit()} loading={submitting}>
          <IntlMessage id='component.city_normative.button.assign' />
        </Button>
        <Button className='ml-2' onClick={() => dispatch(cancelCityNormativeInfoEdit())}>
          <IntlMessage id='button.cancel' />
        </Button>
      </div>
      <Modal title={<IntlMessage id='component.city_normative.confirm.title' />}
             visible={ASSIGN_CITY_NORMATIVE_CONFIRMATION_MODAL === visibleModal}
             okText={<IntlMessage id='component.city_normative.confirm.ok_button' />}
             onCancel={() => dispatch(dismissConfirmationCityNormativeInfoEdit())}
             onOk={() => dispatch(confirmCityNormativeInfoEdit())}
             okButtonProps={{ loading: submitting }}>
        <div className='text-center font-weight-bold font-size-md mb-4'>
          <IntlMessage id='component.city_normative.confirm.message' />
        </div>
      </Modal>
    </>
  );
};

type ShowCityNormativeInfoProps = Pick<CityNormativeInfoProps, 'normative'>

const ShowCityNormativeInfo = ({ normative }: ShowCityNormativeInfoProps) => {
  const dispatch = useDispatch();

  return (
    <div className='d-flex align-items-center justify-content-between'>
      <div className='font-size-md'>
        { normative ? <Link to={appRoutes.path('normativeView', { id: normative.id })}>{normative.name}</Link> : ' - ' }
      </div>
      {!normative &&
        <div>
          <Button size={'small'} onClick={() => dispatch(startCityNormativeInfoEdit())}>
            <IntlMessage id='component.city_normative.button.assign' />
          </Button>
        </div>
      }
    </div>
  );
};

type CityNormativeInfoProps = {
  cityId: string;
  normative?: Normative;
}

const CityNormativeInfo = ({ cityId, normative }: CityNormativeInfoProps) => {
  const { status } = useSelector((state: RootState) => state.views.cities.detail.cityNormative);

  return (
    <>
      <SidebarInfoRow title={<><AuditOutlined /> <IntlMessage id='component.city_normative.title' /></>}>
        {status === CityNormativeInfoStatus.SHOW && <ShowCityNormativeInfo normative={normative} />}
        {!normative && status === CityNormativeInfoStatus.EDIT && <EditCityNormativeInfo cityId={cityId} />}
      </SidebarInfoRow>
    </>
  );
};

export default CityNormativeInfo;
