import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_INFO,
  EDIT_PROFILE_SUBMIT_NEW_PROFILE_INFO,
} from '../../../constants/views/profile/ProfileSettingsConstants';
import {
  newPasswordSubmissionErrored,
  newPasswordSubmissionSucceeded,
  newProfileInfoSubmissionErrored,
  newProfileInfoSubmissionSucceeded, submitNewPassword,
  submitNewProfileInfo,
} from '../../../actions/views/profile/ProfileSettingsActions';
import { AdminUserService } from '../../../../services/AdminUserService';

function* submitNewProfileSaga() {
  yield takeLatest(EDIT_PROFILE_SUBMIT_NEW_PROFILE_INFO, function* (payload: ReturnType<typeof submitNewProfileInfo>) {
    try {
      yield call(AdminUserService.editProfile, payload.id, payload.input);
      yield put(newProfileInfoSubmissionSucceeded());
    } catch (e) {
      yield put(newProfileInfoSubmissionErrored());
    }
  });
}

function* submitNewPasswordSaga() {
  yield takeLatest(CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_INFO, function* ({ id, input }: ReturnType<typeof submitNewPassword>) {
    try {
      yield call(AdminUserService.changeAdminUserPassword, id, input);
      yield put(newPasswordSubmissionSucceeded());
    } catch (e) {
      yield put(newPasswordSubmissionErrored(e.message));
    }
  });
}

export default function* profileSagas() {
  yield all([
    fork(submitNewProfileSaga),
    fork(submitNewPasswordSaga),
  ]);
}
