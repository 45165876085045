import { gql } from '@apollo/client/core';

export const GET_CITY_ISSUES = gql`
  query GetCityIssues($id: ID!, $fromRole: String, $status: String) {
    cityIssues(id: $id, fromRole: $fromRole, status: $status) {
      id
      created
      start
      end
      status
      issueGroup {
        id
        name
      }
      route {
        name
      }
      fromRole
    }
  }
`

export const GET_ALL_ISSUES = gql`
  query GetIssues($fromRole: String, $status: String) {
    issues(fromRole: $fromRole, status: $status) {
      id
      created
      start
      end
      status
      issueGroup {
        id
        name
      }
      route {
        name
      }
      city {
        id
        name
      }
    }
  }
`

export const GET_ISSUE = gql`
  query GetIssue($id: ID!) {
    issue(id: $id) {
      id
      created
      updated
      start
      end
      status
      locationType
      description
      issueTypes {
        id
        code
        title
      }
      issueGroup {
        id
        name
      }
      route {
        name
      }
      user {
        id
        name
        email
      }
      images {
        filename
        path
      }
      mark {
        lat
        lng
      }
      resolvedIssueTypes {
        description
        issueTypes {
          code
          title
        }
        images {
          filename
          path
        }
      }
    }
  }
`;

export const GET_CITY_OUT_OF_ZONE_ISSUES = gql`
  query GetCityIssuesOutOfZoone($city: ID!) {
    cityIssuesOutOfZone(city: $city) {
      id
      number
      route {
        name
      }
      user {
        id
        name
        email
      }
      created
    }
  }
`;

export const GET_ISSUES_STATS = gql`
  query GetIssuesStats {
    issueStats {
      mapping
      new
      toValidate
      validated
    }
  }
`;
