import { all, call, takeEvery, takeLatest, fork, put } from 'redux-saga/effects';
import {
  AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL, AUTH_RESET_PASSWORD_CHECK_TOKEN, AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD,
  AUTH_TOKEN,
  AUTHENTICATED,
  CHECK_WHO_AM_I, SIGNOUT, UNAUTHORIZED,
} from '../constants/Auth';
import { AuthService } from '../../services/AuthService';
import { appUserFetched, appUserNotLoggedIn, authenticated, checkWhoAmI, forgotPasswordSubmitEmail } from '../actions';
import { User } from '../../models';
import { notification } from 'antd';
import { LOCATION_CHANGE, push } from 'connected-react-router';
import { appRoutes } from '../../configs/AppRoutes';
import { EDIT_PROFILE_SUBMIT_NEW_PROFILE_SUCCEEDED } from '../constants/views/profile/ProfileSettingsConstants';
import {
  forgotPasswordSubmitEmailFailed, forgotPasswordSubmitEmailResetState,
  forgotPasswordSubmitEmailSucceeded,
} from '../actions/auth/ForgotPasswordAction';
import { AdminUserService } from '../../services/AdminUserService';
import {
  checkResetPasswordToken,
  checkResetPasswordTokenFailed,
  checkResetPasswordTokenResult, submitNewPassword, submitNewPasswordFailed, submitNewPasswordSucceeded,
} from '../actions/auth/ResetPasswordAction';

export function* whoAmI() {
  yield takeLatest(CHECK_WHO_AM_I, function* () {
    try {
      const user: User = yield call(AuthService.whoAmI);
      yield put(appUserFetched(user));
    } catch (err) {
      yield put(appUserNotLoggedIn());
    }
  });
}

export function* authenticateWithToken() {
  yield takeEvery(AUTHENTICATED, function(payload: ReturnType<typeof authenticated>) {
    const { token } = payload;
    localStorage.setItem(AUTH_TOKEN, token);
  });
}

export function* unauthorized() {
  yield takeLatest(UNAUTHORIZED, function* () {
    localStorage.removeItem(AUTH_TOKEN);
    notification.error({
      message: 'Authorization error',
      description: 'Please login again',
    });
    yield put(push('/auth/login'));
  });
}

export function* signOut() {
  yield takeLatest(SIGNOUT, function* () {
    localStorage.removeItem(AUTH_TOKEN);
    yield put(push(appRoutes.path('login')));
  });
}

export function* loggedInProfileEdited() {
  yield takeLatest(EDIT_PROFILE_SUBMIT_NEW_PROFILE_SUCCEEDED, function* () {
    yield put(checkWhoAmI());
  });
}

function* handleSubmitEmailForgotPassword({ email }: ReturnType<typeof forgotPasswordSubmitEmail>) {
  try {
    yield call(AdminUserService.resetPassword, email);
    yield put(forgotPasswordSubmitEmailSucceeded());
  } catch (e) {
    yield put(forgotPasswordSubmitEmailFailed());
  }
}

function* forgotPasswordSagas() {
  yield all([
    takeLatest(AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL, handleSubmitEmailForgotPassword),
    takeEvery(LOCATION_CHANGE, function* () {
      yield put(forgotPasswordSubmitEmailResetState());
    }),
  ]);
}

function* handleResetPasswordTokenCheck({ token }: ReturnType<typeof checkResetPasswordToken>) {
  try {
    const isValid: boolean = yield call(AdminUserService.checkResetPasswordToken, token);
    yield put(checkResetPasswordTokenResult(isValid));
  } catch (e) {
    yield put(checkResetPasswordTokenFailed());
  }
}

function* resetPasswordSagas() {
  yield all([
    takeLatest(AUTH_RESET_PASSWORD_CHECK_TOKEN, handleResetPasswordTokenCheck),
  ]);
}

function* submitNewPasswordSaga({ token, newPassword }: ReturnType<typeof submitNewPassword>) {
  try {
    const { user, accessToken } = yield call(AdminUserService.setNewPasswordWithToken, token, newPassword);
    yield put(submitNewPasswordSucceeded());
    yield put(authenticated(accessToken, user));
    yield put(push(appRoutes.path('dashboard')));
  } catch (e) {
    yield put(submitNewPasswordFailed());
  }
}

export default function* rootSaga() {
  yield all([
    fork(authenticateWithToken),
    fork(whoAmI),
    fork(unauthorized),
    fork(signOut),
    fork(loggedInProfileEdited),
    fork(forgotPasswordSagas),
    fork(resetPasswordSagas),
    fork(function* () { yield takeEvery(AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD, submitNewPasswordSaga) })
  ]);
}
