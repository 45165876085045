import { combineReducers } from 'redux';
import cityManagementDataReducer from './components/CityManagementDataReducer';
import cityNormativeInfoReducer from './components/CityNormativeInfo';
import cityStatusInfoReducer from './components/CityStatusInfo';
import cityNameReducer from './components/CityNameReducer';

const citiesReducers = combineReducers({
  detail: combineReducers({
    cityManagementData: cityManagementDataReducer,
    cityNormative: cityNormativeInfoReducer,
    cityStatus: cityStatusInfoReducer,
    cityName: cityNameReducer,
  }),
});

export default citiesReducers;
