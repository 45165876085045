import { Alert, Button, Divider, Form, Input, Switch, Upload } from 'antd';
import { Normative } from '../../../../../models';
import { intlFormatMessage } from '../../../../../utils/intl';
import { useForm } from 'antd/es/form/Form';
import { useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import IntlMessage from '../../../../../components/util-components/IntlMessage';
import { useEffect } from 'react';
import AppImage from '../../../../../components/util-components/AppImage';
import { getRequiredRule } from '../../../../../utils/forms';

type IssueGroupFormProps = {
  normative?: Normative;
  onFinish: (values: any) => void;
  onCancel: () => void;
  okLabel: string;
}

const IssueGroupForm = ({ normative, onFinish, onCancel, okLabel }: IssueGroupFormProps) => {
  const [form] = useForm();
  const {
    submitting,
    resetForm,
    errorMessage,
    selectedIssueGroup: issueGroup,
  } = useSelector((state: RootState) => state.views.issueGroupsComponent);

  const isEdit = issueGroup !== null;

  useEffect(() => {
    if (resetForm) {
      form.resetFields();
    }

    form.setFieldsValue({
      name: issueGroup?.name,
      visible: issueGroup?.visible !== undefined ? issueGroup.visible : true,
    });
  }, [resetForm, form, issueGroup]);

  return (
    <Form onFinish={onFinish} form={form}
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}>
      {errorMessage && (
        <Alert message={errorMessage} type='error' className='mb-4' />
      )}
      <Form.Item label={intlFormatMessage('form.label.normative')}>
        {normative?.name}
      </Form.Item>
      {issueGroup?.image && (
        <Form.Item label={intlFormatMessage('form.label.current_icon')}>
          <AppImage image={issueGroup?.image} width={100} />
        </Form.Item>
      )}
      <Form.Item
        name='icon'
        label={intlFormatMessage('form.label.new_icon')}
        rules={!isEdit ? [getRequiredRule()] : undefined}
      >
        <Upload beforeUpload={() => false} listType='picture'>
          <Button>Select file</Button>
        </Upload>
      </Form.Item>
      <Form.Item
        name='name'
        label={intlFormatMessage('form.label.name')}
        rules={[getRequiredRule()]}
      >
        <Input />
      </Form.Item>
      <Form.Item name='visible'
                 valuePropName='checked'
                 label={intlFormatMessage('form.label.visible')}>
        <Switch />
      </Form.Item>
      <Divider />
      <div className='text-right'>
        <Button className='mr-2'
                onClick={onCancel}>
          <IntlMessage id='button.cancel' />
        </Button>
        <Button className='mr-2' type='primary' htmlType='submit' loading={submitting}>
          <IntlMessage id={okLabel} />
        </Button>
      </div>
    </Form>
  );
};

export default IssueGroupForm;
