import { IssuesStatsAction } from '../../actions/stats/issues';
import {
  ISSUES_STATS_FETCH_COMPLETED,
  ISSUES_STATS_FETCH_FAILED,
  ISSUES_STATS_FETCH_START,
} from '../../constants/stats/issuesStats';
import { FetchState } from '../../constants/fetch';

export type IssuesCount = {
  mappingIssues: number | null,
  usersNew: number | null,
  usersToValidate: number | null,
  usersValidated: number | null,
};

export type IssuesStatsState = {
  status: FetchState;
  issuesCount: IssuesCount,
}

const initState: IssuesStatsState = {
  status: FetchState.IDLE,
  issuesCount: {
    mappingIssues: null,
    usersNew: null,
    usersToValidate: null,
    usersValidated: null,
  }
};

export const issuesStatsReducer = (state = initState, action: IssuesStatsAction): IssuesStatsState => {
  switch (action.type) {
    case ISSUES_STATS_FETCH_START:
      return {
        ...state,
        status: FetchState.LOADING,
      }
    case ISSUES_STATS_FETCH_COMPLETED:
      return {
        ...state,
        status: FetchState.SUCCEEDED,
        issuesCount: action.issuesCount,
      }
    case ISSUES_STATS_FETCH_FAILED:
      return {
        ...state,
        status: FetchState.FAILED,
      }
    default:
      return {
        ...state,
      };
  }
};
