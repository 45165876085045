import {
  SEND_NOTIFICATION_TO_USER_CANCEL,
  SEND_NOTIFICATION_TO_USER_DISMISS_MODAL,
  SEND_NOTIFICATION_TO_USER_OPEN_MODAL,
  SEND_NOTIFICATION_TO_USER_START,
  SEND_NOTIFICATION_TO_USER_SUBMIT,
  SEND_NOTIFICATION_TO_USER_SUBMIT_FAILED,
  SEND_NOTIFICATION_TO_USER_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/users/components/SendNotificationToUserConstants';
import { User } from '../../../../../models';
import { SEND_NOTIFICATION_TO_USER_MODAL_NAME } from '../../../../../views/app-views/users/components/SendNotificationToUser';
import { UserNotificationInput } from '../../../../../services/NotificationService';

export type SendNotificationToUserAction = ReturnType<typeof startSendNotificationToUser
  | typeof openSendNotificationToUserModal
  | typeof cancelSendNotificationToUser
  | typeof dismissSendNotificationToUserModal
  | typeof submitNewNotificationToUser
  | typeof submitNewNotificationToUserSucceeded
  | typeof submitNewNotificationToUserFailed>

export function startSendNotificationToUser(user: User) {
  return <const>{
    type: SEND_NOTIFICATION_TO_USER_START,
    user,
  };
}

export function openSendNotificationToUserModal() {
  return <const>{
    type: SEND_NOTIFICATION_TO_USER_OPEN_MODAL,
    visibleModal: SEND_NOTIFICATION_TO_USER_MODAL_NAME,
  };
}

export function cancelSendNotificationToUser() {
  return <const>{
    type: SEND_NOTIFICATION_TO_USER_CANCEL,
  };
}

export function dismissSendNotificationToUserModal() {
  return <const>{
    type: SEND_NOTIFICATION_TO_USER_DISMISS_MODAL,
  };
}

export function submitNewNotificationToUser(input: UserNotificationInput) {
  return <const>{
    type: SEND_NOTIFICATION_TO_USER_SUBMIT,
    input,
  };
}

export function submitNewNotificationToUserSucceeded() {
  return <const>{
    type: SEND_NOTIFICATION_TO_USER_SUBMIT_SUCCEEDED,
  };
}

export function submitNewNotificationToUserFailed() {
  return <const>{
    type: SEND_NOTIFICATION_TO_USER_SUBMIT_FAILED,
  };
}
