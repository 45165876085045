import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { CONFIG_VALUE_SUBMIT_NEW_VALUE } from '../../../constants/views/settings/GeneralSettingsConstants';
import {
  setConfigValueErrored,
  setConfigValueSucceeded,
  submitConfigValue,
} from '../../../actions/views/settings/GeneralSettingsActions';
import { ConfigValueService } from '../../../../services/ConfigValueService';

function* submitConfigValueSaga() {
  yield takeLatest(CONFIG_VALUE_SUBMIT_NEW_VALUE, function* (payload: ReturnType<typeof submitConfigValue>) {
    try {
      yield call(ConfigValueService.set, payload.key, payload.value);
      yield put(setConfigValueSucceeded(payload.key));
    } catch (e) {
      yield put(setConfigValueErrored(payload.key));
    }
  });
}

export default function* generalSettingsSagas() {
  yield all([
    fork(submitConfigValueSaga),
  ]);
}
