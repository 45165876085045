import { createIntl } from 'react-intl';
import AppLocale from '../lang';
import { THEME_CONFIG } from '../configs/AppConfig';

const intl = createIntl({
  locale: THEME_CONFIG.locale,
  messages: AppLocale[THEME_CONFIG.locale as 'es' | 'en'].messages,
});

export const intlFormatMessage = (id: string, values?: {[key: string]: string}): string => {
  return intl.formatMessage({ id }, values);
}

export default intl;
