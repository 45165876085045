import { ModalAction } from '../actions/ModalActions';
import { MODAL_CLEAR_VISIBLE_MODAL_NAME, MODAL_SET_VISIBLE_MODAL_NAME } from '../constants/views/ModalsConstants';

export type ModalState = {
  visibleModal: string | null;
}

const initState: ModalState = {
  visibleModal: null,
};

const modalsReducer = <T extends ModalAction>(state = initState, action: T): ModalState  => {
  switch (action.type) {
    case MODAL_SET_VISIBLE_MODAL_NAME:
    case MODAL_CLEAR_VISIBLE_MODAL_NAME:
      return {
        ...state,
        visibleModal: action.visibleModal,
      }
    default:
      return {
        ...state,
      }
  }
}

export default modalsReducer;
