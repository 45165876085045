import { all, call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ISSUES_STATS_FETCH_START } from '../../constants/stats/issuesStats';
import { fetchIssuesStatsCompleted, fetchIssuesStatsFailed, fetchIssuesStatsStart } from '../../actions/stats/issues';
import { IssueStats } from '../../../models/issues';
import { IssueService } from '../../../services/IssueService';
import { ISSUE_STATUS_CHANGE_SUCCEEDED } from '../../constants/views/issues/components/IssueStatusConstants';

function* fetchIssuesStatsSaga() {
  try {
    const stats: IssueStats = yield call(IssueService.stats);
    yield put(fetchIssuesStatsCompleted({
      mappingIssues: stats.mapping,
      usersNew: stats.new,
      usersToValidate: stats.toValidate,
      usersValidated: stats.validated,
    }));
  } catch (e) {
    yield put(fetchIssuesStatsFailed());
  }
}

function* updateIssueStatsSaga() {
  yield delay(500);
  yield put(fetchIssuesStatsStart());
}

export default function* issuesStatsSagas() {
  yield all([
    takeLatest(ISSUES_STATS_FETCH_START, fetchIssuesStatsSaga),
    takeEvery(ISSUE_STATUS_CHANGE_SUCCEEDED, updateIssueStatsSaga),
  ]);
}
