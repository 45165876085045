import { UserLockedAction } from '../../../../actions/views/users/components/UserLockedAction';
import {
  USER_LOCKED_ACTION_CONFIRMED,
  USER_LOCKED_ACTION_ERRORED,
  USER_LOCKED_ACTION_SUCCEEDED,
} from '../../../../constants/views/users/components/UserLockConstants';

export type UserLockedState = {
  lockedSubmitting: boolean;
}

const initState: UserLockedState = {
  lockedSubmitting: false,
}

export const userLockedReducer = (state = initState, action: UserLockedAction) => {
  switch (action.type) {
    case USER_LOCKED_ACTION_CONFIRMED:
    case USER_LOCKED_ACTION_SUCCEEDED:
    case USER_LOCKED_ACTION_ERRORED:
      return {
        ...state,
        lockedSubmitting: action.submitting,
      }
    default:
      return {
        ...state,
      }
  }
}
