import {
  CONFIG_VALUE_FINISH_EDIT,
  CONFIG_VALUE_SET_ERRORED, CONFIG_VALUE_SET_SUCCEEDED, CONFIG_VALUE_START_EDIT,
  CONFIG_VALUE_SUBMIT_NEW_VALUE,
} from '../../../constants/views/settings/GeneralSettingsConstants';
import { ConfigValueType } from '../../../../models/config';

export type GeneralSettingsActions = ReturnType<typeof submitConfigValue
  | typeof startEditConfigValue
  | typeof finishEditConfigValue
  | typeof setConfigValueSucceeded
  | typeof setConfigValueErrored>;

export function startEditConfigValue(key: ConfigValueType) {
  return <const>{
    type: CONFIG_VALUE_START_EDIT,
    key,
    editing: true,
  }
}

export function finishEditConfigValue(key: ConfigValueType) {
  return <const>{
    type: CONFIG_VALUE_FINISH_EDIT,
    key,
    editing: false,
  }
}

export function submitConfigValue(key: ConfigValueType, value: string) {
  return <const>{
    type: CONFIG_VALUE_SUBMIT_NEW_VALUE,
    key,
    value,
    submitting: true,
  };
}

export function setConfigValueErrored(key: ConfigValueType) {
  return <const>{
    type: CONFIG_VALUE_SET_ERRORED,
    key,
    submitting: false,
  };
}

export function setConfigValueSucceeded(key: ConfigValueType) {
  return <const>{
    type: CONFIG_VALUE_SET_SUCCEEDED,
    key,
    submitting: false,
    editing: false,
  }
}
