import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  CITY_TEAM_MEMBERS_SUBMIT_ADD_TEAM_MEMBER,
  CITY_TEAM_MEMBERS_SUBMIT_DELETE,
} from '../../../../constants/views/cities/components/CityTeamMembersComponentConstants';
import {
  addNewCityTeamMember,
  addNewCityTeamMemberErrored,
  addNewCityTeamMemberSucceeded,
  cancelCityTeamMemberDeletion,
  deleteCityTeamMember,
  deleteCityTeamMemberErrored,
  deleteCityTeamMemberSucceeded,
  dismissAddTeamMemberModal,
} from '../../../../actions/views/cities/components/CityTeamMembersComponentActions';
import { CityTeamMemberService } from '../../../../../services/CityTeamMemberService';

function* addNewCityTeamMemberSaga() {
  yield takeLatest(CITY_TEAM_MEMBERS_SUBMIT_ADD_TEAM_MEMBER, function* (payload: ReturnType<typeof addNewCityTeamMember>) {
    try {
      yield call(CityTeamMemberService.add, payload.input);
      yield put(addNewCityTeamMemberSucceeded());
      yield put(dismissAddTeamMemberModal());
    } catch (e) {
      yield put(addNewCityTeamMemberErrored(e.message));
    }
  });
}

function* deleteCityTeamMemberSaga() {
  yield takeLatest(CITY_TEAM_MEMBERS_SUBMIT_DELETE, function* (payload: ReturnType<typeof deleteCityTeamMember>) {
    try {
      yield call(CityTeamMemberService.delete, payload.cityTeamMemberToDelete);
      yield put(deleteCityTeamMemberSucceeded());
    } catch (e) {
      yield put(deleteCityTeamMemberErrored(e.message));
    }

    yield put(cancelCityTeamMemberDeletion());
  });
}

export default function* cityTeamMemberSagas() {
  yield all([
    fork(addNewCityTeamMemberSaga),
    fork(deleteCityTeamMemberSaga),
  ]);
}
