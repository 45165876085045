import { Alert } from 'antd';
import {CSSProperties} from 'react';
import {ApolloError} from '@apollo/client';

const DisplayError = ({ errors, style }: { errors?: (ApolloError | undefined)[] , style?: CSSProperties  }) => {
  const errorMessage = errors?.filter((error) => error?.message)
    .map((error) => error?.message);


  const message = errorMessage?.map((message) => <div>{message}</div>);

  return (
    <>
      {(errorMessage !== undefined && errorMessage?.length > 0) ? (
        <Alert
          message="Error"
          type="error"
          description={message}
          showIcon
          style={{marginBottom: '1rem', ...style}}
        />
      ) : (
        <></>
      )}
    </>
  );
};

export default DisplayError;
