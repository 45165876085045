import {
  ADD_USER_CITY_TEAM_MEMBERSHIP_CANCEL,
  ADD_USER_CITY_TEAM_MEMBERSHIP_CREATED,
  ADD_USER_CITY_TEAM_MEMBERSHIP_DISMISS_MODAL, ADD_USER_CITY_TEAM_MEMBERSHIP_ERRORED,
  ADD_USER_CITY_TEAM_MEMBERSHIP_OPEN_MODAL,
  ADD_USER_CITY_TEAM_MEMBERSHIP_START,
  ADD_USER_CITY_TEAM_MEMBERSHIP_SUBMIT,
} from '../../../../constants/views/users/components/AddUserCityTeamMembershipConstants';
import { ADD_USER_CITY_TEAM_MEMBERSHIP_MODAL } from '../../../../../views/app-views/users/components/AddUserCityTeamMembershipButton';
import { CityTeamMemberInput } from '../../../../../graphql/teamMembers';

export type AddUserCityTeamMembershipActions = ReturnType<typeof startAddingUserCityTeamMembership
  | typeof openAddUserCityTeamMembershipModal
  | typeof submitNewUserCityTeamMembership
  | typeof newUserCityTeamMembershipCreated
  | typeof newUserCityTeamMembershipErrored
  | typeof cancelAddUserCityTeamMembership
  | typeof dismissAddUserCityTeamMembershipModal>;

export function startAddingUserCityTeamMembership(userId: string) {
  return <const>{
    type: ADD_USER_CITY_TEAM_MEMBERSHIP_START,
    userId,
  };
}

export function openAddUserCityTeamMembershipModal() {
  return <const>{
    type: ADD_USER_CITY_TEAM_MEMBERSHIP_OPEN_MODAL,
    visibleModal: ADD_USER_CITY_TEAM_MEMBERSHIP_MODAL,
  };
}

export function submitNewUserCityTeamMembership(input: CityTeamMemberInput) {
  return <const>{
    type: ADD_USER_CITY_TEAM_MEMBERSHIP_SUBMIT,
    submitting: true,
    input,
  }
}

export function newUserCityTeamMembershipCreated() {
  return <const>{
    type: ADD_USER_CITY_TEAM_MEMBERSHIP_CREATED,
    submitting: false,
  };
}

export function newUserCityTeamMembershipErrored(errorMessage?: string) {
  return <const>{
    type: ADD_USER_CITY_TEAM_MEMBERSHIP_ERRORED,
    submitting: false,
    errorMessage,
  };
}

export function cancelAddUserCityTeamMembership() {
  return <const>{
    type: ADD_USER_CITY_TEAM_MEMBERSHIP_CANCEL,
    userId: null,
  };
}

export function dismissAddUserCityTeamMembershipModal() {
  return <const>{
    type: ADD_USER_CITY_TEAM_MEMBERSHIP_DISMISS_MODAL,
  }
}

