import {gql} from '@apollo/client';

export const GET_USERS = gql`
  query GetUsers {
    users {
      id
      email
      name
      role
      locked
      created
      updated
    }
  }
`;

export const GET_USER = gql`
  query GetUser($id: ID!) {
    user(id: $id) {
      id
      name
      email
      role
      enabled
      locked
      created
      updated
      profileImage {
        path
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser($input: UserInput!, $id: ID!) {
    updateUser(input: $input, id: $id) {
      id
      name
      role
      created
      updated
    }
  }
`;

export const LOCK_USER = gql`
  mutation LockUser($id: ID!) {
    lockUser(id: $id) {
      id
      locked
      updated
    }
  }
`;

export const UNLOCK_USER = gql`
  mutation UnlockUser($id: ID!) {
    unlockUser(id: $id) {
      id
      locked
      updated
    }
  }
`;

export const DELETE_USER = gql`
  mutation DeleteUser($id: ID!) {
    deleteUser(id: $id) {
      id
      name
      email
      locked
    }
  }
`;

export const DELETE_USER_AVATAR = gql`
  mutation DeleteUserAvatar($id: ID!) {
    deleteUserAvatar(id: $id) {
      id
      profileImage {
        path
      }
    }
  }
`;
