import {
  EXPORT_ISSUE_CREATE_NEW_TASK, EXPORT_ISSUE_CREATE_NEW_TASK_FAILED,
  EXPORT_ISSUE_CREATE_NEW_TASK_SUCCEEDED, EXPORT_ISSUE_IN_PROGRESS_RECEIVED,
} from '../../../constants/views/issues/ExportIssuesConstants';

export type ExportIssuesAction = ReturnType<typeof createNewExportTask
  | typeof newExportTaskSucceeded
  | typeof newExportTaskFailed
  | typeof exportTaskInProgressReceived>

export function createNewExportTask() {
  return <const>{
    type: EXPORT_ISSUE_CREATE_NEW_TASK,
  };
}

export function newExportTaskSucceeded(successMessage: string) {
  return <const>{
    type: EXPORT_ISSUE_CREATE_NEW_TASK_SUCCEEDED,
    successMessage,
  };
}

export function newExportTaskFailed() {
  return <const>{
    type: EXPORT_ISSUE_CREATE_NEW_TASK_FAILED,
  };
}

export function exportTaskInProgressReceived(exportTaskId: string) {
  return <const>{
    type: EXPORT_ISSUE_IN_PROGRESS_RECEIVED,
    exportTaskId,
  }
}
