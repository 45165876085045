export const ISSUE_GROUPS_COMPONENT_OPEN_NEW_ISSUE_GROUP_MODAL = 'component/issue_groups/action/modal/open/NEW_ISSUE_GROUP_MODAL';
export const ISSUE_GROUPS_COMPONENT_DISMISS_NEW_ISSUE_GROUP_MODAL = 'component/issue_groups/action/modal/dismiss/NEW_ISSUE_GROUP_MODAL';
export const ISSUE_GROUPS_COMPONENT_SUBMIT_NEW_ISSUE_GROUP = 'component/issue_groups/edit/submit/NEW_ISSUE_GROUP';
export const ISSUE_GROUPS_COMPONENT_NEW_ISSUE_GROUP_SUCCEEDED = 'component/issue_groups/edit/success/NEW_ISSUE_GROUP';
export const ISSUE_GROUPS_COMPONENT_SUBMIT_ISSUE_GROUP_ERRORED = 'component/issue_groups/edit/error/NEW_ISSUE_GROUP';

export const ISSUE_GROUPS_COMPONENT_OPEN_EDIT_ISSUE_GROUP_MODAL = 'component/issue_groups/action/modal/open/EDIT_ISSUE_GROUP_MODAL';
export const ISSUE_GROUPS_COMPONENT_DISMISS_EDIT_ISSUE_GROUP_MODAL = 'component/issue_groups/action/modal/dismiss/EDIT_ISSUE_GROUP_MODAL';
export const ISSUE_GROUPS_COMPONENT_SUBMIT_EDIT_ISSUE_GROUP = 'component/issue_groups/edit/submit/EDIT_ISSUE_GROUP';
export const ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_SUCCEEDED = 'component/issue_groups/edit/success/EDIT_ISSUE_GROUP';
export const ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_ERRORED = 'component/issue_groups/edit/error/EDIT_ISSUE_GROUP';
