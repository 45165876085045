import React from 'react';
import AppBreadcrumb from '../../components/layout-components/AppBreadcrumb';
import IntlMessage from '../util-components/IntlMessage';

export const PageHeader = ({ title, display, showBreadcrumb, className }) => {
  return (
    display ? (
        <div className={ className }>
          <h3 className='mb-0 mr-3 font-weight-semibold'>
            { typeof title === 'string' ? <IntlMessage id={title ? title : 'home'} /> : title}
          </h3>
          {showBreadcrumb ? (<AppBreadcrumb />) : ''}
        </div>
      )
      : null
  );
};

PageHeader.defaultProps = {
  showBreadcrumb: false,
  className: 'app-page-header',
};

export default PageHeader;
