import { Normative } from '../models';
import { gql } from '@apollo/client/core';
import { apolloClient } from '../clients/ApolloClient';
import { GET_NORMATIVES, NormativeInput } from '../graphql/normatives';

const ADD_NORMATIVE = gql`
  mutation AddNormative($input: NormativeInput!) {
    addNormative(input: $input) {
      id
      name
    }
  }
`;

const UPDATE_NORMATIVE = gql`
  mutation UpdateNormative($id: ID!, $input: NormativeInput!) {
    editNormative(id: $id, input: $input) {
      id
      name
    }
  }
`;

export const GET_NORMATIVE = gql`
  query GetNormative($id: String!) {
    normative(id: $id) {
      id
      name
    }
  }
`;

export class NormativeService {
  static add(input: NormativeInput): Promise<Normative | undefined> {
    return apolloClient.mutate<{ addNormative: Normative }>({
      mutation: ADD_NORMATIVE,
      variables: { input },
      refetchQueries: [
        { query: GET_NORMATIVES },
      ],
    }).then(({ data }) => data?.addNormative);
  }

  static edit(id: string, input: NormativeInput): Promise<Normative> {
    return apolloClient.mutate({
      mutation: UPDATE_NORMATIVE,
      variables: {
        id,
        input,
      },
      refetchQueries: [{
        query: GET_NORMATIVE, variables: { id },
      }],
    }).then(({ data }) => data.editNormative);
  }
}
