import {
  ALERTS_DELETE,
  ALERTS_DELETE_MANY_SUBMIT,
  ALERTS_DELETE_MANY_SUCCESSFULLY,
  ALERTS_MARK_AS_READ_MANY_MARKED_SUCCESSFULLY,
  ALERTS_MARK_AS_READ_MANY_SUBMIT,
  ALERTS_MARK_AS_READ_MARKED,
  ALERTS_MARK_AS_READ_SUBMIT, ALERTS_MARK_AS_UNREAD,
  ALERTS_MARK_AS_UNREAD_MANY_MARKED_SUCCESSFULLY,
  ALERTS_MARK_AS_UNREAD_MANY_SUBMIT,
  ALERTS_UPDATE_UNREAD,
} from '../constants/Alerts';

export type AlertsAction = ReturnType<typeof updateUnreadAlerts
  | typeof markAlertAsReadSubmit
  | typeof markAlertAsReadMarked
  | typeof deleteManyAlerts
  | typeof deletedManyAlertsSuccessfully
  | typeof markAsReadMultipleAlerts
  | typeof markedAsReadManyAlertsSuccessfully
  | typeof markAsUnreadMultipleAlerts
  | typeof markedAsUnreadManyAlertsSuccessfully
  | typeof markAlertAsUnread
  | typeof deleteAlert>;

export function updateUnreadAlerts(unreadCount: number) {
  return <const>{
    type: ALERTS_UPDATE_UNREAD,
    unreadCount,
  };
}

export function markAlertAsReadSubmit(alertId: string) {
  return <const>{
    type: ALERTS_MARK_AS_READ_SUBMIT,
    alertId,
  };
}

export function markAlertAsReadMarked() {
  return <const>{
    type: ALERTS_MARK_AS_READ_MARKED,
  };
}

export function deleteManyAlerts(alertIds: string[]) {
  return <const>{
    type: ALERTS_DELETE_MANY_SUBMIT,
    alertIds,
  }
}

export function deletedManyAlertsSuccessfully() {
  return <const>{
    type: ALERTS_DELETE_MANY_SUCCESSFULLY,
  }
}

export function markAsReadMultipleAlerts(alertIds: string[]) {
  return <const>{
    type: ALERTS_MARK_AS_READ_MANY_SUBMIT,
    alertIds,
  }
}

export function markedAsReadManyAlertsSuccessfully() {
  return <const>{
    type: ALERTS_MARK_AS_READ_MANY_MARKED_SUCCESSFULLY,
  }
}

export function markAsUnreadMultipleAlerts(alertIds: string[]) {
  return <const>{
    type: ALERTS_MARK_AS_UNREAD_MANY_SUBMIT,
    alertIds,
  }
}

export function markedAsUnreadManyAlertsSuccessfully() {
  return <const>{
    type: ALERTS_MARK_AS_UNREAD_MANY_MARKED_SUCCESSFULLY,
  }
}

export function markAlertAsUnread(alertId: string) {
  return <const>{
    type: ALERTS_MARK_AS_UNREAD,
    alertId,
  }
}

export function deleteAlert(alertId: string) {
  return <const>{
    type: ALERTS_DELETE,
    alertId,
  }
}
