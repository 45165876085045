import { all } from 'redux-saga/effects';
import normativeDetailSagas from './NormativeDetail';
import addCityModalViewSagas from './components/AddCityModalViewSagas';
import cityDetailViewSagas from './cities/CityDetailSagas';
import cityTeamMemberSagas from './cities/components/CityTeamMemberSagas';
import addNormativeSagas from './normative/components/AddNormativeSagas';
import issueTypesSagas from './normative/components/IssueTypesSagas';
import issueGroupsSagas from './normative/components/IssueGroupsSagas';
import issueDetailSagas from './issues/IssueDetailSagas';
import issueStatusSagas from './issues/component/IssueStatusSagas';
import generalSettingsSagas from './settings/GeneralSettingsSagas';
import sendNotificationsSagas from './notifications/SendNotificationsSagas';
import profileSagas from './profile';
import addUserCityTeamMembershipSagas from './users/components/AddUserCityTeamMembershipSagas';
import userCityTeamMembershipSagas from './users/components/UserCityTeamMembershipSagas';
import userLockedSagas from './users/components/UserLockedSagas';
import adminUsersListSagas from './settings/AdminUsersListSagas';
import deleteUserSagas from './users/components/DeleteUserSagas';
import cityManagementDataSagas from './cities/components/CityManagementDataSagas';
import { removableAvatarSagas } from './users/components/RemovableAvatarSagas';
import userNameSagas from './users/components/UserNameSagas';
import userRoleSagas from './users/components/UserRoleSagas';
import sendNotificationToUserSagas from './users/components/SendNotificationToUserSagas';
import exportIssuesSagas from './issues/ExportIssuesSagas';

export default function* viewsSagas() {
  yield all([
    normativeDetailSagas(),
    addCityModalViewSagas(),
    cityDetailViewSagas(),
    cityTeamMemberSagas(),
    addNormativeSagas(),
    issueTypesSagas(),
    issueGroupsSagas(),
    issueDetailSagas(),
    issueStatusSagas(),
    generalSettingsSagas(),
    sendNotificationsSagas(),
    profileSagas(),
    addUserCityTeamMembershipSagas(),
    userCityTeamMembershipSagas(),
    userLockedSagas(),
    adminUsersListSagas(),
    deleteUserSagas(),
    cityManagementDataSagas(),
    removableAvatarSagas(),
    userNameSagas(),
    userRoleSagas(),
    sendNotificationToUserSagas(),
    exportIssuesSagas(),
  ]);
}
