import {
  CITY_NORMATIVE_INFO_EDIT_ASK_CONFIRMATION,
  CITY_NORMATIVE_INFO_EDIT_CANCEL,
  CITY_NORMATIVE_INFO_EDIT_CONFIRM,
  CITY_NORMATIVE_INFO_EDIT_DISMISS,
  CITY_NORMATIVE_INFO_EDIT_DISMISS_CONFIRMATION,
  CITY_NORMATIVE_INFO_EDIT_START,
  CITY_NORMATIVE_INFO_EDIT_SUBMIT, CITY_NORMATIVE_INFO_EDIT_SUBMIT_FAILED, CITY_NORMATIVE_INFO_EDIT_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/cities/components/CityNormativeInfo';
import { ASSIGN_CITY_NORMATIVE_CONFIRMATION_MODAL } from '../../../../../views/app-views/cities/components/CityNormativeInfo';

export type CityNormativeInfoAction = ReturnType<typeof startCityNormativeInfoEdit
  | typeof cancelCityNormativeInfoEdit
  | typeof dismissCityNormativeInfoEdit
  | typeof submitCityNormativeInfoEdit
  | typeof askConfirmationCityNormativeInfoEdit
  | typeof confirmCityNormativeInfoEdit
  | typeof dismissConfirmationCityNormativeInfoEdit
  | typeof submitCityNormativeInfoEditSucceeded
  | typeof submitCityNormativeInfoEditFailed>

export function startCityNormativeInfoEdit() {
  return <const>{
    type: CITY_NORMATIVE_INFO_EDIT_START,
  };
}

export function cancelCityNormativeInfoEdit() {
  return <const>{
    type: CITY_NORMATIVE_INFO_EDIT_CANCEL,
  };
}

export function dismissCityNormativeInfoEdit() {
  return <const>{
    type: CITY_NORMATIVE_INFO_EDIT_DISMISS,
  };
}

export function submitCityNormativeInfoEdit(cityId: string, normativeId: string) {
  return <const>{
    type: CITY_NORMATIVE_INFO_EDIT_SUBMIT,
    cityId,
    normativeId,
  };
}

export function askConfirmationCityNormativeInfoEdit() {
  return <const>{
    type: CITY_NORMATIVE_INFO_EDIT_ASK_CONFIRMATION,
    visibleModal: ASSIGN_CITY_NORMATIVE_CONFIRMATION_MODAL,
  };
}

export function confirmCityNormativeInfoEdit() {
  return <const>{
    type: CITY_NORMATIVE_INFO_EDIT_CONFIRM,
  };
}

export function dismissConfirmationCityNormativeInfoEdit() {
  return <const>{
    type: CITY_NORMATIVE_INFO_EDIT_DISMISS_CONFIRMATION,
  };
}

export function submitCityNormativeInfoEditSucceeded() {
  return <const>{
    type: CITY_NORMATIVE_INFO_EDIT_SUBMIT_SUCCEEDED,
  };
}

export function submitCityNormativeInfoEditFailed() {
  return <const>{
    type: CITY_NORMATIVE_INFO_EDIT_SUBMIT_FAILED,
  };
}
