import { UserRoleAction } from '../../../../actions/views/users/components/UserRoleAction';
import {
  USER_ROLE_DISMISS_EDIT,
  USER_ROLE_START_EDIT,
  USER_ROLE_SUBMIT,
  USER_ROLE_SUBMIT_FAILED,
  USER_ROLE_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/users/components/UserRoleConstants';

export type UserRoleState = {
  edit: boolean;
  submitting: boolean;
}

const initState: UserRoleState = {
  edit: false,
  submitting: false,
};

const userRoleReducer = (state = initState, action: UserRoleAction): UserRoleState => {
  switch (action.type) {
    case USER_ROLE_START_EDIT:
      return {
        ...state,
        edit: true,
      }
    case USER_ROLE_DISMISS_EDIT:
      return {
        ...state,
        edit: false,
      }
    case USER_ROLE_SUBMIT:
      return {
        ...state,
        submitting: true,
      }
    case USER_ROLE_SUBMIT_SUCCEEDED:
    case USER_ROLE_SUBMIT_FAILED:
      return {
        ...state,
        submitting: false,
      }
    default:
      return {
        ...state,
      };
  }
};

export default userRoleReducer;
