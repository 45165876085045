import React, { lazy, Suspense } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import Loading from '../../components/shared-components/Loading';
import { APP_PREFIX_PATH } from '../../configs/AppConfig';
import PrivateRoute from '../../components/util-components/PrivateRoute';
import CityViews from './cities';
import { appRoutes } from '../../configs/AppRoutes';
import IssuesViews from './issues';

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content" />}>
      <Switch>
        <PrivateRoute
          path={appRoutes.pathDefinition('dashboard')}
          exact
          component={lazy(() => import('./home'))}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('profileSettings')}
          component={lazy(
            () => import('./profile/OwnProfileEdit')
          )}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('alerts')}
          component={lazy(
            () => import('./alerts')
          )}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('users')}
          exact
          component={lazy(() => import('./users/UsersList'))}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('userDetail')}
          exact
          component={lazy(() => import('./users/UserDetail'))}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('normatives')}
          exact
          component={lazy(
            () => import('./issue-types/normatives/NormativeList'),
          )}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('normativeView')}
          component={lazy(
            () => import('./issue-types/normatives/NormativeDetail'),
          )}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('sendNotifications')}
          exact
          component={lazy(
            () => import ('./notifications/SendNotificationView')
          )}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('testingNotifications')}
          exact
          component={lazy(
            () => import ('./notifications/TestingNotificationsView')
          )}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('settings')}
          exact
          component={lazy(
            () => import ('./settings/GeneralSettings')
          )}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('adminUsers')}
          exact
          component={lazy(
            () => import ('./settings/AdminUsersList')
          )}
        />

        <PrivateRoute
          path={appRoutes.pathDefinition('adminUserEdit')}
          component={lazy(
            () => import ('./settings/EditAdminUser')
          )}
        />

        <Route path='/issues' component={IssuesViews} />

        <Route path='/cities' component={CityViews} />

        <Redirect
          from={`${APP_PREFIX_PATH}`}
          to={`${APP_PREFIX_PATH}/dashboard`}
        />
      </Switch>
    </Suspense>
  );
};

export default React.memo(AppViews);
