import { User, UserRole } from '../models';
import { apolloClient } from '../clients/ApolloClient';
import {
  DELETE_USER,
  DELETE_USER_AVATAR,
  GET_USERS,
  LOCK_USER,
  UNLOCK_USER, UPDATE_USER,
} from '../views/app-views/users/UserQueries';

export class UserService {
  static lock(userId: string): Promise<User | undefined> {
    return apolloClient.mutate<{ lockUser: User }>({
      mutation: LOCK_USER,
      variables: { id: userId },
    }).then(({ data }) => data?.lockUser);
  }

  static unlock(userId: string): Promise<User | undefined> {
    return apolloClient.mutate<{ unlockUser: User }>({
      mutation: UNLOCK_USER,
      variables: { id: userId },
    }).then(({ data }) => data?.unlockUser);
  }

  static deleteUser(userId: string): Promise<User | undefined> {
    return apolloClient.mutate<{ deleteUser: User }>({
      mutation: DELETE_USER,
      variables: { id: userId },
      refetchQueries: [{ query: GET_USERS }],
    }).then(({ data }) => data?.deleteUser);
  }

  static deleteUserAvatar(userId: string): Promise<User | undefined> {
    return apolloClient.mutate<{ deleteUserAvatar: User }>({
      mutation: DELETE_USER_AVATAR,
      variables: { id: userId },
    }).then(({ data }) => data?.deleteUserAvatar);
  }

  static edit(userId: string, input: { name?: string, role?: UserRole }): Promise<User | undefined> {
    return apolloClient.mutate<{ updateUser: User}>({
      mutation: UPDATE_USER,
      variables: { id: userId, input }
    }).then(({ data }) => data?.updateUser);
  }
}
