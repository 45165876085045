import { all, fork, put, cancelled, take, cancel, takeEvery, call } from 'redux-saga/effects';
import {
  ADD_USER_CITY_TEAM_MEMBERSHIP_CANCEL,
  ADD_USER_CITY_TEAM_MEMBERSHIP_START, ADD_USER_CITY_TEAM_MEMBERSHIP_SUBMIT,
} from '../../../../constants/views/users/components/AddUserCityTeamMembershipConstants';
import {
  dismissAddUserCityTeamMembershipModal, newUserCityTeamMembershipCreated, newUserCityTeamMembershipErrored,
  openAddUserCityTeamMembershipModal,
} from '../../../../actions/views/users/components/AddUserCityTeamMembershipActions';
import { Task } from 'redux-saga';
import { CityTeamMemberService } from '../../../../../services/CityTeamMemberService';

function* handleAddUserCityTeamMembership() {
  try {
    yield put(openAddUserCityTeamMembershipModal());
    let wasSuccessful = false;
    while (!wasSuccessful) {
      const { input } = yield take(ADD_USER_CITY_TEAM_MEMBERSHIP_SUBMIT)
      try {
        yield call(CityTeamMemberService.add, input);
        yield put(newUserCityTeamMembershipCreated());
        yield put(dismissAddUserCityTeamMembershipModal());
        wasSuccessful = true;
      } catch (e) {
        yield put(newUserCityTeamMembershipErrored(e.message));
      }
    }
  } finally {
    let isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put(dismissAddUserCityTeamMembershipModal());
    }
  }
}

function* addUserCityTeamMembershipSaga() {
  const task: Task = yield fork(handleAddUserCityTeamMembership);
  yield take(ADD_USER_CITY_TEAM_MEMBERSHIP_CANCEL);
  yield cancel(task);
}

export default function* addUserCityTeamMembershipSagas() {
  yield all([
    fork(function* () {
      yield takeEvery(ADD_USER_CITY_TEAM_MEMBERSHIP_START, addUserCityTeamMembershipSaga);
    }),
  ]);
}
