import { Divider } from 'antd';
import * as React from 'react';

type SidebarInfoRowProps = {
  title: React.ReactNode,
  children?: React.ReactNode,
  className?: string,
}

const SidebarInfoRow = ({ title, children, ...props }: SidebarInfoRowProps) => {
  return (
    <div {...props}>
      <h3 className='font-size-md mb-0'>{title}</h3>
      <Divider className='mt-0 mb-3' />
      {children}
    </div>
  );
};

export default SidebarInfoRow;
