import { ExportIssuesAction } from '../../../actions/views/issues/ExportIssuesAction';
import {
  EXPORT_ISSUE_CREATE_NEW_TASK,
  EXPORT_ISSUE_CREATE_NEW_TASK_FAILED,
  EXPORT_ISSUE_CREATE_NEW_TASK_SUCCEEDED,
} from '../../../constants/views/issues/ExportIssuesConstants';

export type ExportIssuesState = {
  submitting: boolean;
}

const initState: ExportIssuesState = {
  submitting: false,
};

export default function exportIssuesReducer(state = initState, action: ExportIssuesAction): ExportIssuesState {
  switch (action.type) {
    case EXPORT_ISSUE_CREATE_NEW_TASK:
      return {
        ...state,
        submitting: true,
      };
    case EXPORT_ISSUE_CREATE_NEW_TASK_SUCCEEDED:
    case EXPORT_ISSUE_CREATE_NEW_TASK_FAILED:
      return {
        ...state,
        submitting: false,
      };
    default:
      return {
        ...state,
      };
  }
}
