import {
  DashboardOutlined,
  UserOutlined,
  AuditOutlined,
  ShopOutlined,
  SettingOutlined,
  NotificationOutlined, WarningOutlined, BellOutlined,
} from '@ant-design/icons';
import { appRoutes } from './AppRoutes';

const dashBoardNavTree = [
  {
    key: 'home',
    path: appRoutes.path('dashboard'),
    title: 'menu.dashboard',
    icon: DashboardOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'issues',
    path: appRoutes.path('userIssues'),
    title: 'menu.issues',
    icon: WarningOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'issues-users',
        path: appRoutes.path('userIssues'),
        title: 'menu.users_issues',
        // icon: ShopOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'issues-mapping',
        path: appRoutes.path('mappingIssues'),
        title: 'menu.mapping_issues',
        // icon: ShopOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'issues-export',
        path: appRoutes.path('exportIssues'),
        title: 'menu.export_issues',
        // icon: ShopOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'cities',
    path: appRoutes.path('cities'),
    title: 'menu.cities',
    icon: ShopOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'cities-associated',
        path: appRoutes.path('cities'),
        title: 'menu.associated_cities',
        // icon: ShopOutlined,
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'cities-not-associated',
        path: appRoutes.path('notAssociatedCities'),
        title: 'menu.not_associated_cities',
        // icon: ShopOutlined,
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
  {
    key: 'normatives',
    path: appRoutes.path('normatives'),
    title: 'menu.normatives',
    icon: AuditOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'users',
    path: appRoutes.path('users'),
    title: 'menu.users',
    icon: UserOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'sendNotifications',
    path: appRoutes.path('sendNotifications'),
    title: 'menu.send_notifications',
    icon: NotificationOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'alerts',
    path: appRoutes.path('alerts'),
    title: 'menu.alerts',
    icon: BellOutlined,
    breadcrumb: false,
    submenu: [],
  },
  {
    key: 'settings',
    path: appRoutes.path('settings'),
    title: 'menu.settings',
    icon: SettingOutlined,
    breadcrumb: false,
    submenu: [
      {
        key: 'settings',
        path: appRoutes.path('settings'),
        title: 'menu.config',
        breadcrumb: false,
        submenu: [],
      },
      {
        key: 'adminUsers',
        path: appRoutes.path('adminUsers'),
        title: 'menu.adminUsers',
        breadcrumb: false,
        submenu: [],
      },
    ],
  },
];

const navigationConfig = [...dashBoardNavTree];

export default navigationConfig;
