import { all, call, delay, put, takeLatest } from 'redux-saga/effects';
import { PUBLISHED_CITIES_FETCH_START } from '../../constants/stats/publishedCities';
import {
  fetchPublishedCitiesCount,
  fetchPublishedCitiesCountCompleted,
  fetchPublishedCitiesCountFailed,
} from '../../actions/stats/publishedCities';
import { CityService } from '../../../services/CityService';
import { CITY_STATUS_INFO_SUBMIT_SUCCEEDED } from '../../constants/views/cities/components/CityStatusInfo';

export default function* publishedCitiesSagas() {
  yield all([
    takeLatest(PUBLISHED_CITIES_FETCH_START, function* () {
      try {
        const citiesCount: number = yield call(CityService.countPublishedCities);
        yield put(fetchPublishedCitiesCountCompleted(citiesCount));
      } catch (e) {
        yield put(fetchPublishedCitiesCountFailed());
      }
    }),
    takeLatest(CITY_STATUS_INFO_SUBMIT_SUCCEEDED, function* () {
      yield delay(500);
      yield put(fetchPublishedCitiesCount());
    }),
  ]);
}
