import { UserCityTeamMembershipAction } from '../../../../actions/views/users/components/UserCityTeamMembershipAction';
import {
  EDIT_USER_CITY_TEAM_MEMBERSHIP_CANCEL,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_START,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_ERRORED,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_SUCCEEDED,
  USER_CITY_TEAM_MEMBERSHIP_DELETE_SUCCEEDED,
} from '../../../../constants/views/users/components/UserCityTeamMembershipConstants';
import { CityTeamMember } from '../../../../../models/teamMembers';

export type UserCityTeamMembershipState = {
  numberRemoved: number;
  dataToEdit: CityTeamMember | null;
  editSubmitting: boolean;
}

const initState: UserCityTeamMembershipState = {
  numberRemoved: 0,
  dataToEdit: null,
  editSubmitting: false,
};

export const userCityTeamMembershipReducer = (state = initState, action: UserCityTeamMembershipAction): UserCityTeamMembershipState => {
  switch (action.type) {
    case USER_CITY_TEAM_MEMBERSHIP_DELETE_SUCCEEDED:
      return {
        ...state,
        numberRemoved: state.numberRemoved + 1,
      };
    case EDIT_USER_CITY_TEAM_MEMBERSHIP_START:
      return {
        ...state,
        dataToEdit: action.cityTeamMember,
      }
    case EDIT_USER_CITY_TEAM_MEMBERSHIP_CANCEL:
      return {
        ...state,
        dataToEdit: null,
      }
    case EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA:
    case EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_SUCCEEDED:
    case EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_ERRORED:
      return {
        ...state,
        editSubmitting: action.editSubmitting,
      }
    default:
      return {
        ...state,
      };
  }
};
