import {
  SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_ERRORED,
  SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_FORM_RESET,
  SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_SUCCEEDED,
  SEND_NOTIFICATIONS_SUBMIT_NEW_BROADCAST_NOTIFICATION,
} from '../../../constants/views/notifications/SendNotificationsConstants';
import { NewBroadcastNotificationInput } from '../../../reducers/views/notifications/SendNotificationsReducer';
import { NotificationAudience } from '../../../../models/notifications';

export type SendNotificationsActions = ReturnType<typeof submitNewBroadcastNotification
  | typeof submitNewBroadcastNotificationSucceeded
  | typeof submitNewBroadcastNotificationErrored
  | typeof newBroadcastNotificationReset>

export function submitNewBroadcastNotification(data: NewBroadcastNotificationInput) {
  return <const>{
    type: SEND_NOTIFICATIONS_SUBMIT_NEW_BROADCAST_NOTIFICATION,
    submitting: true,
    data,
  };
}

export function submitNewBroadcastNotificationErrored() {
  return <const>{
    type: SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_ERRORED,
    submitting: false,
  };
}

export function submitNewBroadcastNotificationSucceeded(audience: NotificationAudience) {
  return <const>{
    type: SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_SUCCEEDED,
    submitting: false,
    successMessage: `notification.new_broadcast.${audience}.success`,
    resetForm: true,
  };
}

export function newBroadcastNotificationReset() {
  return <const>{
    type: SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_FORM_RESET,
    resetForm: false,
  };
}
