import { QUERY_DEFAULT_POLICY, QUERY_THROUGH_NETWORK } from '../constants/Data';

export const queryThroughNetwork = () => {
  return {
    type: QUERY_THROUGH_NETWORK,
  };
}

export const defaultCachePolicy = () => {
  return {
    type: QUERY_DEFAULT_POLICY,
  }
}
