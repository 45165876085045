type EnvironmentConfig = {
  API_ENDPOINT_URL: string;
  gMapsApiKey: string;
}

const fromEnv = {
  API_ENDPOINT_URL: process.env.REACT_APP_API_ENDPOINT_URL || '',
};

const test = {
  API_ENDPOINT_URL: 'https://api.test.com',
};

const getEnv = (): Pick<EnvironmentConfig, 'API_ENDPOINT_URL'> => {
  switch (process.env.NODE_ENV) {
    case 'development':
      return fromEnv;
    case 'production':
      return fromEnv;
    case 'test':
      return test;
    default:
      return fromEnv;
  }
};

export const env: EnvironmentConfig = ((): EnvironmentConfig => {
  return {
    ...getEnv(),
    gMapsApiKey: process.env.REACT_APP_GMAPS_API_KEY || '',
  };
})();
