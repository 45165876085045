import { FetchState } from '../../../../constants/fetch';
import { CityManagementDataAction } from '../../../../actions/views/cities/components/CityManagementData';
import {
  CITY_MANAGEMENT_DATA_FETCH_COMPLETED,
  CITY_MANAGEMENT_DATA_FETCH_FAILED,
  CITY_MANAGEMENT_DATA_FETCH_START,
  CITY_MANAGEMENT_DATA_RESET,
  CITY_MANAGEMENT_DATA_SUBMIT_FAILED,
  CITY_MANAGEMENT_DATA_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/cities/components/CityManagementData';
import { CityManagementData } from '../../../../../models/admin';

type CityManagementDataState = {
  status: FetchState,
  data: CityManagementData | null,
}

const initState: CityManagementDataState = {
  status: FetchState.IDLE,
  data: null,
};

const cityManagementDataReducer = (state = initState, action: CityManagementDataAction) => {
  switch (action.type) {
    case CITY_MANAGEMENT_DATA_FETCH_START:
      return {
        ...state,
        status: FetchState.LOADING,
      }
    case CITY_MANAGEMENT_DATA_FETCH_COMPLETED:
    case CITY_MANAGEMENT_DATA_SUBMIT_SUCCEEDED:
      return {
        ...state,
        status: FetchState.SUCCEEDED,
        data: action.cityManagementData,
      }
    case CITY_MANAGEMENT_DATA_FETCH_FAILED:
    case CITY_MANAGEMENT_DATA_SUBMIT_FAILED:
      return {
        ...state,
        status: FetchState.FAILED,
      }
    case CITY_MANAGEMENT_DATA_RESET:
      return {
        ...initState,
      }
    default:
      return {
        ...state,
      };
  }
};

export default cityManagementDataReducer;
