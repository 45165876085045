import { applyMiddleware, compose, createStore, PreloadedState } from 'redux';
import createReducers from '../reducers';
import createSagaMiddleware from 'redux-saga';
import rootSaga from '../sagas/index';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

declare const window: any;
declare const module: any;

export const history = createBrowserHistory();

const sagaMiddleware = createSagaMiddleware();

const middlewares = [routerMiddleware(history), sagaMiddleware];

function configureStore(preloadedState?: PreloadedState<{}>) {

  const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  const store = createStore(
    createReducers(history),
    preloadedState,
    composeEnhancers(
      applyMiddleware(...middlewares),
    ),
  );

  sagaMiddleware.run(rootSaga);

  if (module.hot) {
    module.hot.accept('../reducers/index', () => {
      const nextRootReducer = require('../reducers/index');
      store.replaceReducer(nextRootReducer(history));
    });
  }

  return store;
}

const store = configureStore();

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;

