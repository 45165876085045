import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { SEND_NOTIFICATIONS_SUBMIT_NEW_BROADCAST_NOTIFICATION } from '../../../constants/views/notifications/SendNotificationsConstants';
import {
  submitNewBroadcastNotification,
  submitNewBroadcastNotificationErrored, submitNewBroadcastNotificationSucceeded,
} from '../../../actions/views/notifications/SendNotificationsActions';
import { NotificationService } from '../../../../services/NotificationService';

function* sendNewBroadcastNotificationSaga() {
  yield takeLatest(SEND_NOTIFICATIONS_SUBMIT_NEW_BROADCAST_NOTIFICATION, function* (payload: ReturnType<typeof submitNewBroadcastNotification>) {
    try {
      yield call(NotificationService.broadcast, payload.data);
      yield put(submitNewBroadcastNotificationSucceeded(payload.data.audience));
    } catch (e) {
      yield put(submitNewBroadcastNotificationErrored());
    }
  });
}

export default function* sendNotificationsSagas() {
  yield all([
    fork(sendNewBroadcastNotificationSaga),
  ]);
}
