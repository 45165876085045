import { all, call, cancel, cancelled, fork, put, take, takeLatest } from 'redux-saga/effects';
import { Task } from 'redux-saga';
import {
  DELETE_USER_CANCEL,
  DELETE_USER_START, DELETE_USER_SUBMIT,
} from '../../../../constants/views/users/components/DeleteUserConstants';
import {
  deleteUserSubmissionErrored,
  deleteUserSubmissionSucceeded,
  dismissDeleteUserConfirmationModal,
  openDeleteUserConfirmationModal, startDeleteUser,
} from '../../../../actions/views/users/components/DeleteUserAction';
import { User } from '../../../../../models';
import { UserService } from '../../../../../services/UserService';

function* handleDeleteUser(user: User) {
  try {
    yield put(openDeleteUserConfirmationModal());
    let wasSuccessful = false;
    while (!wasSuccessful) {
      yield take(DELETE_USER_SUBMIT);
      yield call(UserService.deleteUser, user.id);
      yield put(deleteUserSubmissionSucceeded());
      yield put(dismissDeleteUserConfirmationModal());
      wasSuccessful = true;
    }
  } catch (e) {
    yield put(deleteUserSubmissionErrored());
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put(dismissDeleteUserConfirmationModal());
    }
  }
}

function* deleteUserSaga({ user }: ReturnType<typeof startDeleteUser>) {
  const task: Task = yield fork(handleDeleteUser, user);
  yield take(DELETE_USER_CANCEL);
  yield cancel(task);
}

export default function* deleteUserSagas() {
  yield all([
    takeLatest(DELETE_USER_START, deleteUserSaga),
  ]);
}
