import { Alert, Modal } from 'antd';
import IntlMessage from '../../../../components/util-components/IntlMessage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  cancelDeleteAdminUser,
  confirmDeleteAdminUser,
} from '../../../../redux/actions/views/settings/DeleteAdminUserAction';

export const CONFIRM_DELETE_ADMIN_USER_MODAL = 'modal.confirmDeleteAdminUser';

const DeleteAdminUser = () => {
  const dispatch = useDispatch();
  const { adminUser, submitting } = useSelector((state: RootState) => state.views.adminUsersList.delete);
  const { visibleModal } = useSelector((state: RootState) => state.modal);

  return (
    <Modal title={<IntlMessage id='modal.title.confirm_delete_admin_user' />}
           visible={visibleModal === CONFIRM_DELETE_ADMIN_USER_MODAL}
           okButtonProps={{ danger: true, loading: submitting }}
           cancelButtonProps={{ disabled: submitting }}
           okText={<IntlMessage id='button.remove' />}
           onOk={() => adminUser && dispatch(confirmDeleteAdminUser(adminUser.id))}
           onCancel={() => dispatch(cancelDeleteAdminUser())}
    >
      <div className='text-center font-weight-bold font-size-md mb-4'>
        <div className='mb-4'>
          <IntlMessage id='modal.content.remove_admin_user' values={{ user: adminUser?.name, email: adminUser?.email }} />
        </div>

        <Alert message={(
          <div>
            <IntlMessage id='component.delete_user.warning' />
            <span className='font-weight-bold'><IntlMessage id='warning.operation_undoable' /></span>
          </div>
        )} type={'error'} showIcon />
      </div>
    </Modal>
  )
}

export default DeleteAdminUser;
