export type BaseModel = {
  id: string;
  created: Date;
  updated: Date;
}

export type User = {
  id: string;
  email: string;
  name: string;
  role: UserRole;
  enabled: boolean;
  locked: boolean;
  created: Date;
  updated: Date;
  profileImage?: Image;
}

export enum UserRole {
  USER = 'role.user',
  TEAM_MEMBER = 'role.team_member',
}

export type Normative = BaseModel & {
  name: string;
  issueGroups: IssueGroup[];
}

export type IssueType = BaseModel & {
  issueGroup: IssueGroup;
  code: string;
  title: string;
  information: string;
}

export type IssueGroup = BaseModel & {
  name: string;
  image: Image;
  visible: boolean;
  issueTypes: IssueType[];
}

export enum IssueGroupVisibility {
  ALL = 'ALL',
  ONLY_VISIBLE = 'ONLY_VISIBLE',
  ONLY_NOT_VISIBLE = 'ONLY_NOT_VISIBLE',
}

export enum IssueTypeVisibility {
  ALL = 'ALL',
  ONLY_VISIBLE = 'ONLY_VISIBLE',
  ONLY_NOT_VISIBLE = 'ONLY_NOT_VISIBLE',
}

export type Image = {
  filename: string;
  path: string;
}

export type ResolvedIssue = {
  description: string;
  issueTypes: IssueType[];
  images: Image[];
}
