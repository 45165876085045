import { CityNameAction } from '../../../../actions/views/cities/components/CityNameAction';
import {
  CITY_NAME_EDIT_DISMISS,
  CITY_NAME_EDIT_START,
  CITY_NAME_EDIT_SUBMIT,
  CITY_NAME_EDIT_SUBMIT_FAILED,
  CITY_NAME_EDIT_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/cities/components/CityName';

type CityNameState = {
  edit: boolean,
  submitting: boolean,
}

const initState: CityNameState = {
  edit: false,
  submitting: false,
};

const cityNameReducer = (state = initState, action: CityNameAction): CityNameState => {
  switch (action.type) {
    case CITY_NAME_EDIT_START:
      return {
        ...state,
        edit: true,
      }
    case CITY_NAME_EDIT_SUBMIT:
      return {
        ...state,
        submitting: true,
      }
    case CITY_NAME_EDIT_SUBMIT_SUCCEEDED:
    case CITY_NAME_EDIT_SUBMIT_FAILED:
      return {
        ...state,
        submitting: false,
      }
    case CITY_NAME_EDIT_DISMISS:
      return {
        ...state,
        edit: false,
      }
    default:
      return {
        ...state,
      };
  }
};

export default cityNameReducer;
