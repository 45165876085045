import {
  ISSUE_GROUP_COMPONENT_DISMISS_EDIT,
  ISSUE_GROUP_COMPONENT_START_EDIT,
  ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES,
  ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES_ERRORED,
  ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES_SUCCEEDED,
} from '../../../../constants/views/issues/components/IssueGroupComponentConstants';
import { IssueInput } from '../../../../../services/IssueService';

export type IssueGroupComponentAction = ReturnType<typeof startIssueGroupComponentEdit
  | typeof dismissIssueGroupComponentEdit
  | typeof submitNewIssueGroupAndTypes
  | typeof newIssueGroupAndTypesSubmissionSucceeded
  | typeof newIssueGroupAndTypesSubmissionErrored>

export function startIssueGroupComponentEdit() {
  return <const>{
    type: ISSUE_GROUP_COMPONENT_START_EDIT,
    editing: true,
  }
}

export function dismissIssueGroupComponentEdit() {
  return <const>{
    type: ISSUE_GROUP_COMPONENT_DISMISS_EDIT,
    editing: false,
  }
}

export function submitNewIssueGroupAndTypes(issueId: string, input: Pick<IssueInput, 'issueGroup' | 'issueTypes'>) {
  return <const>{
    type: ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES,
    submitting: true,
    issueId,
    input,
  }
}

export function newIssueGroupAndTypesSubmissionSucceeded() {
  return <const>{
    type: ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES_SUCCEEDED,
    submitting: false,
  }
}

export function newIssueGroupAndTypesSubmissionErrored(errorMessage: string) {
  return <const>{
    type: ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES_ERRORED,
    submitting: false,
    errorMessage,
  }
}
