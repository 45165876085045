import { Issue, IssueStats } from '../models/issues';
import { gql } from '@apollo/client/core';
import { apolloClient } from '../clients/ApolloClient';
import { GET_ISSUE, GET_ISSUES_STATS } from '../graphql/issues';
import fetch from '../auth/FetchInterceptor';
import { GRAPHQL_PATH } from '../configs/AppConfig';

export type IssueInput = {
  description?: string;
  issueGroup?: string;
  issueTypes?: string[];
}

export const ISSUE_ACCEPT_FOR_VALIDATION = gql`
  mutation AcceptIssueForValidation($id: ID!) {
    acceptIssue(id: $id) {
      id
      status
    }
  }
`;

export const DISCARD_ISSUE = gql`
  mutation DiscardIssue($id: ID!) {
    discardIssue(id: $id) {
      id
      status
    }
  }
`;

export const VALIDATE_ISSUE = gql`
  mutation ValidateIssue($id: ID!) {
    validateIssue(id: $id) {
      id
      status
    }
  }
`;

export const EDIT_ISSUE = gql`
  mutation EditIssue($id: ID!, $input: IssueInput!) {
    editIssue(id: $id, input: $input) {
      id
      description
      issueGroup {
        id
        name
      }
      issueTypes {
        id
        title
      }
    }
  }
`;

export const REMOVE_ISSUE_IMAGE = gql`
  mutation RemoveIssueImage($id: ID!, $input: RemoveIssueImageInput!) {
    removeImage(id: $id, input: $input) {
      id
      images {
        filename
        path
      }
    }
  }
`;

const ADD_IMAGE = `
  mutation AddIssueImage($id: ID!, $input: AddIssueImageInput!) {
    addImage(id: $id, input: $input) {
      id
      images {
        filename
        path
      }
    }
  }
`;

export class IssueService {
  static acceptForValidation(issueId: string): Promise<Issue | undefined> {
    return apolloClient.mutate<{ acceptIssue: Issue }>({
      mutation: ISSUE_ACCEPT_FOR_VALIDATION,
      variables: { id: issueId },
      refetchQueries: [{ query: GET_ISSUES_STATS }],
    }).then(({ data }) => data?.acceptIssue);
  }

  static discard(issueId: string): Promise<Issue | undefined> {
    return apolloClient.mutate<{ discardIssue: Issue }>({
      mutation: DISCARD_ISSUE,
      variables: { id: issueId },
      refetchQueries: [{ query: GET_ISSUES_STATS }],
    }).then(({ data }) => data?.discardIssue);
  }

  static validate(issueId: string): Promise<Issue | undefined> {
    return apolloClient.mutate<{ validateIssue: Issue }>({
      mutation: VALIDATE_ISSUE,
      variables: { id: issueId },
      refetchQueries: [{ query: GET_ISSUES_STATS }],
    }).then(({ data }) => data?.validateIssue);
  }

  static edit(issueId: string, input: IssueInput): Promise<Issue | undefined> {
    return apolloClient.mutate<{ editIssue: Issue }>({
      mutation: EDIT_ISSUE,
      variables: { id: issueId, input },
    }).then(({ data }) => data?.editIssue);
  }

  static addImage(issueId: string, image: File): Promise<Issue | undefined> {
    const variables = {
      id: issueId,
      input: { image: null },
    };

    const map = JSON.stringify({
      '0': ['variables.input.image'],
    });

    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: ADD_IMAGE,
      variables,
    }));
    formData.append('map', map);
    formData.append('0', image as Blob);

    return fetch({
      method: 'POST',
      url: GRAPHQL_PATH,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }).then(({ data, errors }: { data?: { addImage: Issue }, errors?: { message: string }[]}): Issue | null => {
      if (errors) {
        throw new Error(errors[0]?.message);
      }

      return data?.addImage ?? null;
    }).then((issue: Issue | null) => {
      if (null === issue) {
        return issue;
      }

      return apolloClient.query({
        query: GET_ISSUE,
        variables: { id: issueId },
        fetchPolicy: 'no-cache',
      })
        .then(({ data }) => data?.issue)
    });
  }

  static removeImage(issueId: string, filename: string): Promise<Issue | undefined> {
    return apolloClient.mutate({
      mutation: REMOVE_ISSUE_IMAGE,
      variables: { id: issueId, input: { filename }}
    }).then(({ data }) => data?.removeImage)
  }

  static stats(): Promise<IssueStats | undefined> {
    return apolloClient.query<{ issueStats: IssueStats }>({
      query: GET_ISSUES_STATS,
    }).then(({ data }) => data?.issueStats);
  }
}
