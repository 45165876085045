import { Tag } from 'antd';
import IntlMessage from '../util-components/IntlMessage';

const UserLockedTag = ({ locked, ...props }: { locked?: boolean, className?: string }) => {
  if (undefined === locked) {
    return (<></>);
  }

  return (
    <Tag color={locked ? 'red' : 'green'} {...props}>
      <IntlMessage id={locked ? 'tag.user.locked' : 'tag.user.unlocked'} />
    </Tag>
  )
}

export default UserLockedTag;
