import {
  CITY_STATUS_INFO_SUBMIT,
  CITY_STATUS_INFO_SUBMIT_FAILED,
  CITY_STATUS_INFO_SUBMIT_SUCCEEDED,
  CITY_STATUS_INFO_EDIT_CANCEL,
  CITY_STATUS_INFO_EDIT_START,
} from '../../../../constants/views/cities/components/CityStatusInfo';
import { CityStatus } from '../../../../../models/places';

export type CityStatusInfoAction = ReturnType<typeof submitNewCityStatus
  | typeof submitNewCityStatusSucceeded
  | typeof submitNewCityStatusFailed
  | typeof startCityStatusInfoEdit
  | typeof cancelCityStatusInfoEdit>


export function submitNewCityStatus(cityId: string, newStatus: CityStatus) {
  return <const>{
    type: CITY_STATUS_INFO_SUBMIT,
    cityId,
    newStatus,
  };
}

export function submitNewCityStatusSucceeded() {
  return <const>{
    type: CITY_STATUS_INFO_SUBMIT_SUCCEEDED,
  };
}

export function submitNewCityStatusFailed() {
  return <const>{
    type: CITY_STATUS_INFO_SUBMIT_FAILED,
  };
}

export function startCityStatusInfoEdit() {
  return <const>{
    type: CITY_STATUS_INFO_EDIT_START,
  }
}

export function cancelCityStatusInfoEdit() {
  return <const>{
    type: CITY_STATUS_INFO_EDIT_CANCEL,
  }
}
