import {
  ISSUE_IMAGE_COMPONENT_IMAGES_LOADED,
  ISSUE_IMAGE_COMPONENT_REMOVE_IMAGE,
  ISSUE_IMAGE_COMPONENT_REMOVE_IMAGE_ERRORED,
  ISSUE_IMAGE_COMPONENT_REMOVE_IMAGE_SUCCEEDED,
  ISSUE_IMAGE_COMPONENT_START_UPLOAD,
  ISSUE_IMAGE_COMPONENT_UPLOAD_ERRORED,
  ISSUE_IMAGE_COMPONENT_UPLOAD_SUCCEEDED,
  RESOLVED_ISSUE_IMAGE_COMPONENT_IMAGES_LOADED
} from '../../../../constants/views/issues/components/IssueImageConstants';
import { Image } from '../../../../../models';

export type IssueImageComponentAction = ReturnType<typeof issueImagesLoaded
  | typeof startIssueImageUpload
  | typeof issueImageUploadErrored
  | typeof resolvedssueImagesLoaded>

export function issueImagesLoaded(images: Image[]) {
  return <const>{
    type: ISSUE_IMAGE_COMPONENT_IMAGES_LOADED,
    images,
  };
}

export function resolvedssueImagesLoaded(images: Image[]) {
  return <const>{
    type: RESOLVED_ISSUE_IMAGE_COMPONENT_IMAGES_LOADED,
    images,
  };
}

export function startIssueImageUpload(issueId: string, image: File) {
  return <const>{
    type: ISSUE_IMAGE_COMPONENT_START_UPLOAD,
    issueId,
    image,
  };
}

export function issueImageUploadErrored(errorMessage: string) {
  return <const>{
    type: ISSUE_IMAGE_COMPONENT_UPLOAD_ERRORED,
    errorMessage,
  };
}


export function issueImageUploadSucceeded(successMessage: string) {
  return <const>{
    type: ISSUE_IMAGE_COMPONENT_UPLOAD_SUCCEEDED,
    successMessage,
  };
}

export function removeIssueImage(issueId: string, filename: string) {
  return <const>{
    type: ISSUE_IMAGE_COMPONENT_REMOVE_IMAGE,
    issueId,
    filename,
  };
}

export function removeIssueImageSucceeded(successMessage: string) {
  return <const>{
    type: ISSUE_IMAGE_COMPONENT_REMOVE_IMAGE_SUCCEEDED,
    successMessage,
  };
}

export function removeIssueImageErrored(errorMessage: string) {
  return <const>{
    type: ISSUE_IMAGE_COMPONENT_REMOVE_IMAGE_ERRORED,
    errorMessage,
  };
}
