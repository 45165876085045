import { CityNormativeInfoAction } from '../../../../actions/views/cities/components/CityNormativeInfoAction';
import {
  CITY_NORMATIVE_INFO_EDIT_CONFIRM,
  CITY_NORMATIVE_INFO_EDIT_DISMISS,
  CITY_NORMATIVE_INFO_EDIT_START,
  CITY_NORMATIVE_INFO_EDIT_SUBMIT_FAILED,
  CITY_NORMATIVE_INFO_EDIT_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/cities/components/CityNormativeInfo';

export enum CityNormativeInfoStatus {
  SHOW = 'SHOW',
  EDIT = 'EDIT',
}

type CityNormativeInfoState = {
  status: CityNormativeInfoStatus,
  submitting: boolean,
}

const initState: CityNormativeInfoState = {
  status: CityNormativeInfoStatus.SHOW,
  submitting: false,
};

const cityNormativeInfoReducer = (state = initState, action: CityNormativeInfoAction): CityNormativeInfoState => {
  switch (action.type) {
    case CITY_NORMATIVE_INFO_EDIT_START:
      return {
        ...state,
        status: CityNormativeInfoStatus.EDIT,
      };
    case CITY_NORMATIVE_INFO_EDIT_DISMISS:
      return {
        ...state,
        status: CityNormativeInfoStatus.SHOW,
      };
    case CITY_NORMATIVE_INFO_EDIT_CONFIRM:
      return {
        ...state,
        submitting: true,
      };
    case CITY_NORMATIVE_INFO_EDIT_SUBMIT_SUCCEEDED:
    case CITY_NORMATIVE_INFO_EDIT_SUBMIT_FAILED:
      return {
        ...state,
        submitting: false,
      };
    default:
      return {
        ...state,
      };
  }
};

export default cityNormativeInfoReducer;
