import { User } from '../../../../../models';
import {
  DELETE_USER_CANCEL, DELETE_USER_DISMISS_CONFIRMATION_MODAL, DELETE_USER_OPEN_CONFIRMATION_MODAL,
  DELETE_USER_START, DELETE_USER_SUBMIT, DELETE_USER_SUBMIT_ERRORED, DELETE_USER_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/users/components/DeleteUserConstants';
import { CONFIRM_DELETE_USER_MODAL } from '../../../../../views/app-views/users/components/DeleteUser';

export type DeleteUserAction = ReturnType<typeof startDeleteUser
  | typeof cancelDeleteUser
  | typeof openDeleteUserConfirmationModal
  | typeof dismissDeleteUserConfirmationModal
  | typeof submitDeleteUser
  | typeof deleteUserSubmissionSucceeded
  | typeof deleteUserSubmissionErrored>

export function startDeleteUser(user: User) {
  return <const>{
    type: DELETE_USER_START,
    user,
  };
}

export function cancelDeleteUser() {
  return <const>{
    type: DELETE_USER_CANCEL,
    user: null,
  };
}

export function openDeleteUserConfirmationModal() {
  return <const>{
    type: DELETE_USER_OPEN_CONFIRMATION_MODAL,
    visibleModal: CONFIRM_DELETE_USER_MODAL,
  };
}

export function dismissDeleteUserConfirmationModal() {
  return <const>{
    type: DELETE_USER_DISMISS_CONFIRMATION_MODAL,
    user: null,
    visibleModal: null,
  };
}

export function submitDeleteUser() {
  return <const>{
    type: DELETE_USER_SUBMIT,
    submitting: true,
  };
}

export function deleteUserSubmissionSucceeded(successMessage?: string) {
  return <const>{
    type: DELETE_USER_SUBMIT_SUCCEEDED,
    submitting: false,
    successMessage,
  };
}

export function deleteUserSubmissionErrored(errorMessage?: string) {
  return <const>{
    type: DELETE_USER_SUBMIT_ERRORED,
    submitting: false,
    errorMessage,
  };
}
