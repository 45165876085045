import {
  AUTH_RESET_PASSWORD_CHECK_TOKEN,
  AUTH_RESET_PASSWORD_CHECK_TOKEN_ERROR,
  AUTH_RESET_PASSWORD_CHECK_TOKEN_RESULT,
  AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD, AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_FAILED,
  AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCEEDED,
} from '../../constants/Auth';
import { ResetPasswordTokenStatus } from '../../reducers/auth/resetPasswordReducer';

export type ResetPasswordAction = ReturnType<typeof checkResetPasswordToken
  | typeof checkResetPasswordTokenResult
  | typeof checkResetPasswordTokenFailed
  | typeof submitNewPassword
  | typeof submitNewPasswordSucceeded
  | typeof submitNewPasswordFailed>;

export const checkResetPasswordToken = (token: string) => {
  return <const>{
    type: AUTH_RESET_PASSWORD_CHECK_TOKEN,
    token,
  };
};

export const checkResetPasswordTokenResult = (isValid: boolean) => {
  return <const>{
    type: AUTH_RESET_PASSWORD_CHECK_TOKEN_RESULT,
    status: isValid ? ResetPasswordTokenStatus.OK : ResetPasswordTokenStatus.NOT_VALID,
  };
};

export const checkResetPasswordTokenFailed = () => {
  return <const>{
    type: AUTH_RESET_PASSWORD_CHECK_TOKEN_ERROR,
  };
};

export const submitNewPassword = (token: string, newPassword: string) => {
  return <const>{
    type: AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD,
    token,
    newPassword,
  }
}

export const submitNewPasswordSucceeded = () => {
  return <const>{
    type: AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCEEDED,
  }
}

export const submitNewPasswordFailed = (errorMessage?: string) => {
  return <const>{
    type: AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_FAILED,
    errorMessage,
  }
}
