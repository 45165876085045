export const USER_CITY_TEAM_MEMBERSHIP_DELETE = 'component/user_city_team_membership/submit/REMOVE';
export const USER_CITY_TEAM_MEMBERSHIP_DELETE_SUCCEEDED = 'component/user_city_team_membership/form/success/REMOVE_SUCCEEDED';
export const USER_CITY_TEAM_MEMBERSHIP_DELETE_ERRORED = 'component/user_city_team_membership/submit/error/REMOVE_ERRORED';

export const EDIT_USER_CITY_TEAM_MEMBERSHIP_START = 'component/edit_user_city_team_membership/START';
export const EDIT_USER_CITY_TEAM_MEMBERSHIP_OPEN_MODAL = 'component/edit_user_city_team_membership/modal/open/OPEN';
export const EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA = 'component/edit_user_city_team_membership/form/submit/SUBMIT_EDIT';
export const EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_SUCCEEDED = 'component/edit_user_city_team_membership/form/success/SUBMIT_EDIT_SUCCEEDED';
export const EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_ERRORED = 'component/edit_user_city_team_membership/form/error/SUBMIT_EDIT_ERRORED';
export const EDIT_USER_CITY_TEAM_MEMBERSHIP_CANCEL = 'component/edit_user_city_team_membership/CANCEL';
export const EDIT_USER_CITY_TEAM_MEMBERSHIP_DISMISS_MODAL = 'component/edit_user_city_team_membership/modal/dismiss/DISMISS';
