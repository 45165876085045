import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
  dismissEditIssueTypeModal,
  submitEditedIssueType,
} from '../../../../../redux/actions/views/normative/components/IssueTypesActions';
import { RootState } from '../../../../../redux/store';
import intl from '../../../../../utils/intl';
import { Normative } from '../../../../../models';
import { IssueTypeInput } from '../../../../../services/IssueTypeService';
import IssueTypeForm from './IssueTypeForm';

export const EDIT_ISSUE_TYPE_MODAL_NAME = 'modal/edit_issue_type';

type EditIssueTypeProps = {
  normative?: Normative,
}

const EditIssueType = ({ normative }: EditIssueTypeProps) => {
  const dispatch = useDispatch();
  const { visibleModal } = useSelector((state: RootState) => state.modal);
  const { selectedIssueType } = useSelector((state: RootState) => state.views.issueTypesComponent);

  const onFinish = (input: IssueTypeInput) => {
    if (selectedIssueType) {
      dispatch(submitEditedIssueType(selectedIssueType?.id, input))
    }
  };

  return (
    <>
      <Modal title={intl.formatMessage({ id: 'modal.title.edit_issue_type' }, { title: selectedIssueType?.title })}
             visible={visibleModal === EDIT_ISSUE_TYPE_MODAL_NAME}
             onCancel={() => dispatch(dismissEditIssueTypeModal())}
             footer={false}>
        <IssueTypeForm normative={normative}
                       okLabel='button.update'
                       onFinish={onFinish}
                       onCancel={() => dispatch(dismissEditIssueTypeModal())} />
      </Modal>
    </>
  );
};

export default EditIssueType;
