import { Alert, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import { intlFormatMessage } from '../../../../utils/intl';
import { cancelDeleteUser, submitDeleteUser } from '../../../../redux/actions/views/users/components/DeleteUserAction';
import IntlMessage from '../../../../components/util-components/IntlMessage';

export const CONFIRM_DELETE_USER_MODAL = 'modal.confirmDeleteUser';

const DeleteUser = () => {
  const dispatch = useDispatch();
  const { visibleModal } = useSelector((state: RootState) => state.modal);
  const { user } = useSelector((state: RootState) => state.views.users.delete);

  return (
    <Modal title={intlFormatMessage('modal.title.confirm_delete_user')}
           visible={visibleModal === CONFIRM_DELETE_USER_MODAL}
           okButtonProps={{ danger: true }}
           okText={intlFormatMessage('button.remove')}
           onOk={() => dispatch(submitDeleteUser())}
           onCancel={() => dispatch(cancelDeleteUser())}>
      <div className='text-center font-weight-bold font-size-md mb-4'>
        <IntlMessage id='modal.content.remove_user' values={{ user: user?.name, email: user?.email }} />
      </div>

      <Alert message={(
        <div>
          <IntlMessage id='component.delete_user.warning' />
          <span className='font-weight-bold'><IntlMessage id='warning.operation_undoable' /></span>
        </div>
      )} type={'error'} showIcon />
    </Modal>
  );
};

export default DeleteUser;
