export const CITY_TEAM_MEMBERS_OPEN_ADD_TEAM_MEMBER = 'views/city_team_members/action/OPEN_ADD_TEAM_MEMBER_MODAL';
export const CITY_TEAM_MEMBERS_DISMISS_ADD_TEAM_MEMBER = 'views/city_team_members/action/DISMISS_TEAM_MEMBER_MODAL';
export const CITY_TEAM_MEMBERS_SUBMIT_ADD_TEAM_MEMBER = 'views/city_team_members/edit/submit/ADD_TEAM_MEMBER';
export const CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_SUCCEEDED = 'views/city_team_members/edit/success/ADD_TEAM_MEMBER_SUCCEEDED';
export const CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_ERRORED = 'views/city_team_members/edit/error/ADD_TEAM_MEMBER_ERRORED';

export const CITY_TEAM_MEMBERS_ASK_DELETE_CONFIRMATION = 'views/city_team_members/delete/ask/ASK_DELETE_TEAM_MEMBER_CONFIRMATION';
export const CITY_TEAM_MEMBERS_CANCEL_DELETE = 'views/city_team_members/delete/cancel/CANCEL_TEAM_MEMBER_DELETE';
export const CITY_TEAM_MEMBERS_SUBMIT_DELETE = 'views/city_team_members/delete/submit/DELETE_TEAM_MEMBER';
export const CITY_TEAM_MEMBERS_DELETE_ERRORED = 'views/city_team_members/delete/error/DELETE_TEAM_MEMBER';
export const CITY_TEAM_MEMBERS_DELETE_SUCCEEDED = 'views/city_team_members/delete/success/DELETE_TEAM_MEMBER';
