import PrivateRoute from '../../../components/util-components/PrivateRoute';
import { appRoutes } from '../../../configs/AppRoutes';
import React, { lazy } from 'react';

const IssuesViews = () => {
  return (
    <>
      <PrivateRoute
        path={appRoutes.pathDefinition('userIssues')}
        exact
        component={lazy(
          () => import ('./UsersIssues'),
        )}
      />

      <PrivateRoute
        path={appRoutes.pathDefinition('mappingIssues')}
        exact
        component={lazy(
          () => import ('./MappingIssues'),
        )}
      />

      <PrivateRoute
        path={appRoutes.pathDefinition('exportIssues')}
        exact
        component={lazy(
          () => import ('./ExportIssues'),
        )}
      />

      <PrivateRoute
        path={appRoutes.pathDefinition('issue')}
        exact
        component={lazy(
          () => import ('../cities/issues/IssueDetail'),
        )}
      />
    </>
  );
};

export default IssuesViews;
