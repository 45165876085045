import { env } from './EnvironmentConfig';
import { generatePath } from 'react-router';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from './AppConfig';

const routes = <const>{
  dashboard: `${APP_PREFIX_PATH}/`,
  users: `${APP_PREFIX_PATH}/users`,
  userDetail: `${APP_PREFIX_PATH}/users/:id`,
  normatives: `${APP_PREFIX_PATH}/issue-types/normatives`,
  normativeView: `${APP_PREFIX_PATH}/issue-types/normatives/:id`,
  cities: `${APP_PREFIX_PATH}/cities/associated`,
  notAssociatedCities: `${APP_PREFIX_PATH}/cities/not-associated`,
  cityView: `${APP_PREFIX_PATH}/cities/:id([a-f\\d]{24})`,
  sendNotifications: `${APP_PREFIX_PATH}/notifications/send`,
  testingNotifications: `${APP_PREFIX_PATH}/testing-notifications`,
  cityUsersIssues: `${APP_PREFIX_PATH}/cities/:id([a-f\\d]{24})/users-issues`,
  cityMappingIssues: `${APP_PREFIX_PATH}/cities/:id([a-f\\d]{24})/mapping-issues`,
  issue: `${APP_PREFIX_PATH}/issues/:id([a-f\\d]{24})`,
  exportIssues: `${APP_PREFIX_PATH}/issues/export`,
  userIssues: `${APP_PREFIX_PATH}/issues/users`,
  mappingIssues: `${APP_PREFIX_PATH}/issues/mapping`,
  settings: `${APP_PREFIX_PATH}/settings/config`,
  adminUsers: `${APP_PREFIX_PATH}/settings/admin-users`,
  adminUserEdit: `${APP_PREFIX_PATH}/settings/admin-users/:id([a-f\\d]{24})`,
  profileSettings: `${APP_PREFIX_PATH}/profile`,
  alerts: `${APP_PREFIX_PATH}/alerts`,
  login: `${AUTH_PREFIX_PATH}/login`,
  forgotPassword: `${AUTH_PREFIX_PATH}/forgot-password`,
}

class AppRoutes {
  constructor(private readonly routes: { [key: string]: string }) {
  }

  pathDefinition(name: keyof typeof routes): string | null {
    return this.routes[name] ?? null;
  }

  path(name: keyof typeof routes, params?: object): string {
    const pathDefinition = this.routes[name] ?? null;

    return pathDefinition ? generatePath(pathDefinition, params) : '';
  }
}

export const getDownloadExportUrl = (id: string) => `${env.API_ENDPOINT_URL}/v1/export/${id}`;

export const appRoutes = new AppRoutes(routes);
