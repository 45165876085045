import { all, call, cancel, cancelled, fork, put, take, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  EDIT_USER_CITY_TEAM_MEMBERSHIP_CANCEL,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_START, EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA,
  USER_CITY_TEAM_MEMBERSHIP_DELETE,
} from '../../../../constants/views/users/components/UserCityTeamMembershipConstants';
import {
  deleteUserCityTeamMembership,
  deleteUserCityTeamMembershipErrored,
  deleteUserCityTeamMembershipSucceeded,
  dismissEditUserCityTeamMembershipModal,
  editUserCityTeamMembershipSubmitSucceeded,
  openEditUserCityTeamMembershipModal,
} from '../../../../actions/views/users/components/UserCityTeamMembershipAction';
import { CityTeamMemberService } from '../../../../../services/CityTeamMemberService';
import { Task } from 'redux-saga';

function* removeUserCityTeamMembershipSaga({ cityTeamMember }: ReturnType<typeof deleteUserCityTeamMembership>) {
  try {
    yield call(CityTeamMemberService.delete, cityTeamMember);
    yield put(deleteUserCityTeamMembershipSucceeded());
  } catch (e) {
    yield put(deleteUserCityTeamMembershipErrored(e.message));
  }
}

function* handleEditUserTeamMembership() {
  try {
    yield put(openEditUserCityTeamMembershipModal());
    let wasSuccessful = false;
    while (!wasSuccessful) {
      const { id, input } = yield take(EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA);
      yield call(CityTeamMemberService.edit, id, input);
      yield put(editUserCityTeamMembershipSubmitSucceeded());
      yield put(dismissEditUserCityTeamMembershipModal());
      wasSuccessful = true;
    }
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put(dismissEditUserCityTeamMembershipModal());
    }
  }
}

function* editUserTeamMembershipSaga() {
  const task: Task = yield fork(handleEditUserTeamMembership);
  yield take(EDIT_USER_CITY_TEAM_MEMBERSHIP_CANCEL);
  yield cancel(task);
}


export default function* userCityTeamMembershipSagas() {
  yield all([
    fork(function* () {
      yield takeEvery(USER_CITY_TEAM_MEMBERSHIP_DELETE, removeUserCityTeamMembershipSaga);
    }),
    fork(function* () {
      yield takeLatest(EDIT_USER_CITY_TEAM_MEMBERSHIP_START, editUserTeamMembershipSaga);
    }),
  ]);
}
