import PageHeader from '../../../components/layout-components/PageHeader';
import { useQuery } from '@apollo/client';
import { City, CityStatus } from '../../../models/places';
import DisplayError from '../../../components/util-components/DisplayError';
import { Button, Card, Col, Input, Row, Select, Table, Tooltip } from 'antd';
import intl, { intlFormatMessage } from '../../../utils/intl';
import { SortOrder } from 'antd/es/table/interface';
import { ColumnType } from 'antd/lib/table';
import { Link } from 'react-router-dom';
import { ExceptionOutlined, EyeOutlined, SearchOutlined, SolutionOutlined } from '@ant-design/icons';
import AddCityModal from './components/AddCityModal';
import { useState } from 'react';
import { Normative } from '../../../models';
import { appRoutes } from '../../../configs/AppRoutes';
import { GET_ASSOCIATED_CITIES, GET_NOT_ASSOCIATED_CITIES } from '../../../services/CityService';
import CityStatusTag from '../../../components/shared-components/CityStatusTag';
import { IssueStatus } from '../../../models/issues';

export enum CitiesListType {
  ASSOCIATED = 'associated',
  NOT_ASSOCIATED = 'not_associated',
}

const getTableColumns = (type: CitiesListType): ColumnType<City>[] => {
  const associatedCityColumns = [
    {
      title: intl.formatMessage({ id: 'table.header.normative' }),
      dataIndex: 'normative',
      key: 'normative',
      render: (normative: Normative) => {
        return normative
          ? <Link to={appRoutes.path('normativeView', { id: normative.id })}>{normative.name}</Link>
          : ' - ';
      },
    },
    {
      title: intl.formatMessage({ id: 'table.header.new' }),
      dataIndex: 'newIssues',
      key: 'newIssues',
      width: 80,
      render: (newIssues: number, city: City) => (
        <Link to={appRoutes.path('cityUsersIssues', { id: city.id })}>{newIssues}</Link>
      ),
      sorter: (a: City, b: City) => a.newIssues < b.newIssues ? -1 : 1,
    },
    {
      title: intl.formatMessage({ id: 'table.header.issues_to_validate' }),
      dataIndex: 'issuesToValidate',
      key: 'issuesToValidate',
      width: 120,
      render: (issuesToValidate: number, city: City) => (
        <Link to={appRoutes.path('cityUsersIssues', { id: city.id }) + `#${IssueStatus.TO_VALIDATE}`}>
          {issuesToValidate}
        </Link>
      ),
      sorter: (a: City, b: City) => a.issuesToValidate < b.issuesToValidate ? -1 : 1,
    },
    {
      title: intl.formatMessage({ id: 'table.header.status' }),
      dataIndex: 'status',
      key: 'status',
      width: 90,
      sorter: (a: City, b: City) => a.status.localeCompare(b.status),
      render: (status: CityStatus) => (<CityStatusTag status={status} />),
    },
  ];

  const notAssociatedCityColumns = [
    {
      title: intl.formatMessage({ id: 'table.header.issues' }),
      dataIndex: 'outOfZoneIssuesCount',
      key: 'outOfZoneIssuesCount',
      width: 80,
      sorter: (a: City, b: City) => a.outOfZoneIssuesCount < b.outOfZoneIssuesCount ? -1 : 1,
    },
  ];

  return [
    {
      title: intl.formatMessage({ id: 'table.header.name' }),
      dataIndex: 'name',
      key: 'name',
      defaultSortOrder: 'ascend' as SortOrder,
      sortDirections: ['ascend', 'descend', 'ascend'],
      width: type === CitiesListType.ASSOCIATED ? 240 : undefined,
      sorter: (a: City, b: City) => a.name.localeCompare(b.name),
      render: (text: string, city: City) => <Link to={appRoutes.path('cityView', { id: city.id })}>{text}</Link>,
    },
    ...(type === CitiesListType.ASSOCIATED ? associatedCityColumns : []),
    ...(type === CitiesListType.NOT_ASSOCIATED ? notAssociatedCityColumns : []),
    {
      title: intl.formatMessage({ id: 'table.header.actions' }),
      key: 'actions',
      width: 160,
      align: 'center',
      render: (text: string, city: City) => (
        <div className='text-center'>
          <Tooltip title={intlFormatMessage('tooltip.city.see_city_detail')}>
            <Link to={appRoutes.path('cityView', { id: city.id })}>
              <Button type='link' size='small' icon={<EyeOutlined />} color='green' />
            </Link>
          </Tooltip>
          {
            type === CitiesListType.ASSOCIATED ? (
              <>
                <Tooltip title={intlFormatMessage('tooltip.issues.see_users_issues')}>
                  <Link to={appRoutes.path('cityUsersIssues', { id: city.id })}>
                    <Button type='link' size='small' icon={<SolutionOutlined />} color='green' />
                  </Link>
                </Tooltip>
                <Tooltip title={intlFormatMessage('tooltip.issues.see_mapping_issues')}>
                  <Link to={appRoutes.path('cityMappingIssues', { id: city.id })}>
                    <Button type='link' size='small' icon={<ExceptionOutlined />} color='green' />
                  </Link>
                </Tooltip>
              </>
            ) : null
          }
        </div>
      ),
    },
  ];
};

const filterCities = (filter: string, filterStatus: string | undefined, type: CitiesListType, cities?: City[]): City[] => {
  if (!cities) {
    return [];
  }

  const filterByHasIssuesOutOfZone = (city: City): boolean => type === CitiesListType.ASSOCIATED
    || city.outOfZoneIssuesCount > 0;

  const filterByStatus = (city: City): boolean => !filterStatus || city.status === filterStatus;

  const filterByName = (city: City): boolean => !filter || [
    city.name,
    ...(type === CitiesListType.ASSOCIATED ? [
      city.normative?.name ?? '',
    ] : []),
  ].join().match(new RegExp(filter, 'i')) !== null;

  return cities
    .filter(filterByHasIssuesOutOfZone)
    .filter(filterByStatus)
    .filter(filterByName);
};

type CitiesListProps = {
  type: CitiesListType;
}

const CitiesList = ({ type }: CitiesListProps = { type: CitiesListType.ASSOCIATED }) => {
  const { loading, data, error } = useQuery<{ cities: City[] }>(
    type === CitiesListType.ASSOCIATED ? GET_ASSOCIATED_CITIES : GET_NOT_ASSOCIATED_CITIES,
  );

  const [filter, setFilter] = useState('');
  const [filterStatus, setFilterStatus] = useState<string | undefined>();

  return (
    <>
      <PageHeader title={type === CitiesListType.ASSOCIATED ? 'page.cities.title' : 'page.not_associated_cities.title'}
                  display
                  showBreadcrumb={false} />
      {!data && error ? (<DisplayError errors={[error]} />) : ('')}
      <Card>
        <Row>
          <Col flex={1} className='mb-4'>
            <Row gutter={8}>
              <Col span={16}>
                <Input placeholder={intlFormatMessage('table.filter.placeholder')} prefix={<SearchOutlined />}
                       allowClear
                       onChange={(e) => setFilter(e.target.value)} />

              </Col>
              <Col span={8}>
                {type === CitiesListType.ASSOCIATED ? (
                  <Select placeholder={intlFormatMessage('filter.placeholder.select_status')}
                          value={filterStatus} onChange={(value) => setFilterStatus(value)}
                          allowClear style={{ width: 200 }}>
                    {Object.values(CityStatus)
                      .filter((status) => status !== CityStatus.NEW)
                      .map((status) => (
                        <Select.Option key={status} value={status}><CityStatusTag status={status} /></Select.Option>
                      ))}
                  </Select>
                ) : null}
              </Col>
            </Row>
          </Col>
          <Col flex={3} className='mb-4 text-right'>
            {type === CitiesListType.ASSOCIATED ? <AddCityModal /> : null}
          </Col>
        </Row>
        <Row>
          <Col flex={12}>
            <Table loading={loading} rowKey='id'
                   columns={getTableColumns(type)}
                   dataSource={filterCities(filter, filterStatus, type, data?.cities)} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

CitiesList.defaultProps = {
  type: CitiesListType.ASSOCIATED,
};

export default CitiesList;
