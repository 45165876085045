import { Dropdown, Menu } from 'antd';
import { EditOutlined, LogoutOutlined, UserOutlined } from '@ant-design/icons';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { RootState } from '../../redux/store';
import { signOut } from '../../redux/actions';
import IntlMessage from '../util-components/IntlMessage';
import { appRoutes } from '../../configs/AppRoutes';

const UserNav = () => {
  const dispatch = useDispatch();
  const { user: loggedInUser } = useSelector((state: RootState) => state.auth);

  const profileMenu = (
    <div className='nav-profile nav-dropdown'>
      <div className='nav-profile-header'>
        <div className='d-flex'>
          <div className='pl-3'>
            <h4 className='mb-0'>{loggedInUser?.name}</h4>
          </div>
        </div>
      </div>
      <div className='nav-profile-body'>
        <Menu>
          <Menu.Item key='profile'>
            <Link to={appRoutes.path('profileSettings')}>
              <EditOutlined className='mr-3' />
              <span className='font-weight-normal'>
                <IntlMessage id='nav.profile' />
              </span>
            </Link>
          </Menu.Item>
          <Menu.Item key='logout' onClick={() => dispatch(signOut())}>
            <span>
              <LogoutOutlined className='mr-3' />
              <span className='font-weight-normal'>
                <IntlMessage id='nav.sign_out' />
              </span>
            </span>
          </Menu.Item>
        </Menu>
      </div>
    </div>

  );

  return (
    <Dropdown placement='bottomRight' overlay={profileMenu} trigger={['click']}>
      <Menu className='d-flex align-item-center' mode='horizontal'>
        <Menu.Item>
          <UserOutlined className='mr-3' />
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default UserNav;
