import {
  USER_LOCKED_ACTION_CANCEL, USER_LOCKED_ACTION_CLOSE_MODAL,
  USER_LOCKED_ACTION_CONFIRMED, USER_LOCKED_ACTION_ERRORED, USER_LOCKED_ACTION_OPEN_CONFIRMATION,
  USER_LOCKED_LOCK_START, USER_LOCKED_ACTION_SUCCEEDED, USER_LOCKED_UNLOCK_START,
} from '../../../../constants/views/users/components/UserLockConstants';
import { USER_LOCKED_CONFIRM_MODAL } from '../../../../../views/app-views/users/components/UserLockComponent';

export type UserLockedAction = ReturnType<typeof startUserLock
  | typeof askUserLockedConfirmation
  | typeof cancelUserLockedAction
  | typeof dismissUserLockedActionConfirmationModal
  | typeof userLockedActionConfirmed
  | typeof userLockedActionSucceeded
  | typeof userLockedActionErrored>

export function startUserLock() {
  return <const>{
    type: USER_LOCKED_LOCK_START,
  };
}

export function startUserUnlock() {
  return <const>{
    type: USER_LOCKED_UNLOCK_START,
  };
}

export function askUserLockedConfirmation() {
  return <const>{
    type: USER_LOCKED_ACTION_OPEN_CONFIRMATION,
    visibleModal: USER_LOCKED_CONFIRM_MODAL,
  };
}

export function cancelUserLockedAction() {
  return <const>{
    type: USER_LOCKED_ACTION_CANCEL,
  };
}

export function dismissUserLockedActionConfirmationModal() {
  return <const>{
    type: USER_LOCKED_ACTION_CLOSE_MODAL,
    visibleModal: null,
  };
}

export function userLockedActionConfirmed(userId: string) {
  return <const>{
    type: USER_LOCKED_ACTION_CONFIRMED,
    submitting: true,
    userId,
  };
}

export function userLockedActionSucceeded(successMessage: string) {
  return <const>{
    type: USER_LOCKED_ACTION_SUCCEEDED,
    submitting: false,
    successMessage,
  };
}

export function userLockedActionErrored(errorMessage?: string) {
  return <const>{
    type: USER_LOCKED_ACTION_ERRORED,
    submitting: false,
    errorMessage,
  };
}
