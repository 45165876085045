export const ALERTS_UPDATE_UNREAD = 'alerts/UPDATE_UNREAD';
export const ALERTS_MARK_AS_READ_SUBMIT = 'alerts/mark_as_read/SUBMIT';
export const ALERTS_MARK_AS_READ_MARKED = 'alerts/mark_as_read/MARKED';
export const ALERTS_MARK_AS_UNREAD = 'alerts/mark_as_unread/DO';
export const ALERTS_DELETE_MANY_SUBMIT = 'alerts/delete_many/SUBMIT';
export const ALERTS_DELETE = 'alerts/delete/DO';
export const ALERTS_DELETE_MANY_SUCCESSFULLY = 'alerts/delete_many/DELETED_SUCCESSFULLY';
export const ALERTS_MARK_AS_READ_MANY_SUBMIT = 'alerts/mark_as_read_many/SUBMIT';
export const ALERTS_MARK_AS_READ_MANY_MARKED_SUCCESSFULLY = 'alerts/mark_as_read_many/MARKED_SUCCESSFULLY';
export const ALERTS_MARK_AS_UNREAD_MANY_SUBMIT = 'alerts/mark_as_unread_many/SUBMIT';
export const ALERTS_MARK_AS_UNREAD_MANY_MARKED_SUCCESSFULLY = 'alerts/mark_as_unread_many/MARKED_SUCCESSFULLY';
