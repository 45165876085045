import { all, call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  fetchCityManagementDataCompleted,
  fetchCityManagementDataFailed,
  fetchCityManagementDataStart, resetCityManagementData,
  submitCityManagementDataFailed,
  submitCityManagementDataStart,
  submitCityManagementDataSucceeded,
} from '../../../../actions/views/cities/components/CityManagementData';
import {
  CITY_MANAGEMENT_DATA_FETCH_START,
  CITY_MANAGEMENT_DATA_SUBMIT_START,
} from '../../../../constants/views/cities/components/CityManagementData';
import { CityManagementDataService } from '../../../../../services/CityManagementDataService';
import { CityManagementData } from '../../../../../models/admin';
import { ROUTER_LOCATION_CHANGE } from '../../../../Router';

function* fetchCityManagementDataSaga({ cityId }: ReturnType<typeof fetchCityManagementDataStart>) {
  try {
    const cityManagementData: CityManagementData = yield call(CityManagementDataService.get, cityId)
    yield put(fetchCityManagementDataCompleted(cityManagementData));
  } catch (e) {
    yield put(fetchCityManagementDataFailed());
  }
}

function* submitCityManagementDataSaga({ id, input }: ReturnType<typeof submitCityManagementDataStart>) {
  try {
    const cityManagementDataResult: CityManagementData = yield call(CityManagementDataService.edit, id, input);
    yield put(submitCityManagementDataSucceeded(cityManagementDataResult));
  } catch (e) {
    yield put(submitCityManagementDataFailed());
  }
}

export default function* cityManagementDataSagas() {
  yield all([
    takeLatest(CITY_MANAGEMENT_DATA_FETCH_START, fetchCityManagementDataSaga),
    takeLatest(CITY_MANAGEMENT_DATA_SUBMIT_START, submitCityManagementDataSaga),
    takeEvery(ROUTER_LOCATION_CHANGE, function* () {
      yield put(resetCityManagementData());
    }),
  ]);
}
