import { gql } from '@apollo/client/core';

export type NormativeInput = {
  name: string;
}

export const GET_NORMATIVES = gql`
  query {
    normatives {
      id
      name
      created
      updated
    }
  }
`;
