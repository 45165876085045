import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import {
  ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE,
  ISSUE_TYPES_COMPONENT_SUBMIT_EDIT_ISSUE_TYPE,
  ISSUE_TYPES_COMPONENT_SUBMIT_NEW_ISSUE_TYPE,
} from '../../../../constants/views/normative/components/IssueTypesComponent';
import {
  dismissAddNewIssueTypeModal,
  dismissEditIssueTypeModal,
  editIssueTypeErrored,
  editIssueTypeSucceeded,
  newIssueTypeErrored,
  newIssueTypeSucceeded,
  removeIssueTypeFromNormative,
  removeIssueTypeFromNormativeErrored,
  removeIssueTypeFromNormativeSucceeded,
  submitEditedIssueType,
  submitNewIssueType,
} from '../../../../actions/views/normative/components/IssueTypesActions';
import { IssueTypeService } from '../../../../../services/IssueTypeService';
import intl from '../../../../../utils/intl';

function* submitNewIssueTypeSaga({ input, normativeId }: ReturnType<typeof submitNewIssueType>) {
  try {
    yield call(IssueTypeService.add, input, normativeId);
    yield put(newIssueTypeSucceeded());
    yield put(dismissAddNewIssueTypeModal());
  } catch (e) {
    yield put(newIssueTypeErrored(e.message));
  }
}

function* editIssueTypeSaga({ id, input }: ReturnType<typeof submitEditedIssueType>) {
  try {
    yield call(IssueTypeService.edit, id, input);
    yield put(editIssueTypeSucceeded());
    yield put(dismissEditIssueTypeModal());
  } catch (e) {
    yield put(editIssueTypeErrored(e.message));
  }
}

function* removeIssueTypeSaga({ issueTypeId, normativeId }: ReturnType<typeof removeIssueTypeFromNormative>) {
  try {
    yield call(IssueTypeService.removeNormativeIssueType, issueTypeId, normativeId);
    yield put(removeIssueTypeFromNormativeSucceeded('notification.issue_type.remove.success'));
  } catch (e) {
    yield put(removeIssueTypeFromNormativeErrored(intl.formatMessage({ id: 'notification.issue_type.remove.error' })));
  }
}

export default function* issueTypesSagas() {
  yield all([
    fork(function* () {
      yield takeLatest(
        ISSUE_TYPES_COMPONENT_SUBMIT_NEW_ISSUE_TYPE, submitNewIssueTypeSaga,
      );
    }),
    fork(function* () {
      yield takeLatest(
        ISSUE_TYPES_COMPONENT_SUBMIT_EDIT_ISSUE_TYPE, editIssueTypeSaga,
      );
    }),
    fork(function* () {
      yield takeLatest(ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE, removeIssueTypeSaga);
    }),
  ]);
}
