import fetch from '../auth/FetchInterceptor';
import { IssueGroup, IssueGroupVisibility } from '../models';
import { FetchPolicy, gql } from '@apollo/client/core';
import { GRAPHQL_PATH } from '../configs/AppConfig';
import { apolloClient } from '../clients/ApolloClient';

export const ISSUE_GROUP_FRAGMENT = gql`
  fragment IssueGroupFragment on IssueGroup {
    id
    name
    image {
      filename
      destination
      path
    }
    visible
  }
`;

export const GET_NORMATIVE_ISSUE_GROUPS = gql`
  ${ISSUE_GROUP_FRAGMENT}
  
  query GetNormativeIssueGroups ($normativeId: ID!, $visibility: IssueGroupVisibility) {
    normativeIssueGroups(normativeId: $normativeId, visibility: $visibility) {
      ...IssueGroupFragment
    }
  }
`;

export const GET_SIBLING_ISSUE_GROUPS = gql`
  query GetSiblingIssueGroups($issueGroupId: ID!) {
    siblingIssueGroups(issueGroupId: $issueGroupId) {
      id
      name
      issueTypes {
        id
        code
        title
      }
    }
  }
`;

export const EDIT_ISSUE_GROUP_PLAIN_QUERY = `
  mutation EditIssueGroup($id: ID!, $input: EditIssueGroupInput!) { 
    editIssueGroup(id: $id, input: $input) { 
      created
      updated 
      id
      name
      visible
    } 
  }
`;

export const EDIT_ISSUE_GROUP = gql`
  mutation EditIssueGroup($id: ID!, $input: EditIssueGroupInput!) { 
    editIssueGroup(id: $id, input: $input) { 
      created
      updated 
      id
      name
      visible
    } 
  }
`;

export type IssueGroupInput = {
  normative: string;
  name: string;
  icon: File;
  visible: boolean;
}

export type EditIssueGroupInput = {
  name: string;
  icon?: File;
  visible?: boolean;
}

type AddIssueGroupResponse = {
  data?: {
    addIssueGroup: IssueGroup,
  };

  errors?: {
    message: string,
  }[];
}

export class IssueGroupService {
  static createIssueGroup(issueGroupInput: IssueGroupInput) {
    const query = `
      mutation AddIssueGroup($input: IssueGroupInput!) { 
        addIssueGroup(input: $input) { 
          created 
          updated 
          id 
          name
          visible 
        } 
      }
    `;

    const variables = {
      input: {
        normative: issueGroupInput.normative,
        name: issueGroupInput.name,
        visible: issueGroupInput.visible,
        image: null,
      },
    };

    const map = JSON.stringify({
      '0': ['variables.input.image'],
    });

    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query,
      variables,
    }));
    formData.append('map', map);
    formData.append('0', issueGroupInput.icon);

    return fetch({
      method: 'POST',
      url: GRAPHQL_PATH,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }).then(({ data, errors }: AddIssueGroupResponse) => {
      if (errors) {
        throw new Error(errors[0]?.message);
      }

      return data?.addIssueGroup;
    });
  }

  static edit(id: string, input: EditIssueGroupInput): Promise<IssueGroup | undefined> {
    const issueGroupInput: {
      name: string,
      image?: null,
      visible?: boolean,
    } = {
      name: input.name,
      visible: input.visible,
    };

    if (input.icon) {
      issueGroupInput.image = null;
    }

    const variables = {
      id,
      input: issueGroupInput,
    };
    const formData = new FormData();
    formData.append('operations', JSON.stringify({
      query: EDIT_ISSUE_GROUP_PLAIN_QUERY,
      variables,
    }));
    formData.append('map', JSON.stringify(
      input.icon ? { '0': ['variables.input.image'] } : {},
    ));
    if (input.icon) {
      formData.append('0', input.icon);
    }

    return fetch({
      method: 'POST',
      url: GRAPHQL_PATH,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
      data: formData,
    }).then(({ data, errors }: { data?: { editIssueGroup: IssueGroup }, errors?: { message: string }[] }) => {
      if (errors) {
        throw new Error(errors[0]?.message);
      }

      return data?.editIssueGroup;
    });
  }

  static getNormativeIssueGroups(normativeId: string, visibility: IssueGroupVisibility = IssueGroupVisibility.ALL, fetchPolicy: FetchPolicy = 'cache-first'): Promise<IssueGroup[] | undefined> {
    return apolloClient.query<{ normativeIssueGroups: IssueGroup[] }>({
      query: GET_NORMATIVE_ISSUE_GROUPS,
      variables: { normativeId, visibility },
      fetchPolicy,
    }).then(({ data }) => data?.normativeIssueGroups)
  }
}
