import {
  USER_CITY_TEAM_MEMBERSHIP_DELETE,
  USER_CITY_TEAM_MEMBERSHIP_DELETE_ERRORED,
  USER_CITY_TEAM_MEMBERSHIP_DELETE_SUCCEEDED,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_START,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_OPEN_MODAL,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_DISMISS_MODAL,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_SUCCEEDED,
  EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_ERRORED, EDIT_USER_CITY_TEAM_MEMBERSHIP_CANCEL,
} from '../../../../constants/views/users/components/UserCityTeamMembershipConstants';
import { CityTeamMember } from '../../../../../models/teamMembers';
import { EDIT_USER_CITY_TEAM_MEMBERSHIP_MODAL } from '../../../../../views/app-views/users/components/EditUserTeamMembershipComponent';
import { CityTeamMemberInput } from '../../../../../graphql/teamMembers';

export type UserCityTeamMembershipAction = ReturnType<typeof deleteUserCityTeamMembership
  | typeof deleteUserCityTeamMembershipSucceeded
  | typeof deleteUserCityTeamMembershipErrored
  | typeof startEditUserCityTeamMembership
  | typeof openEditUserCityTeamMembershipModal
  | typeof dismissEditUserCityTeamMembershipModal
  | typeof editUserCityTeamMembershipSubmit
  | typeof editUserCityTeamMembershipSubmitSucceeded
  | typeof editUserCityTeamMembershipSubmitErrored
  | typeof cancelEditUserCityTeamMembership>

export function deleteUserCityTeamMembership(cityTeamMember: CityTeamMember) {
  return <const>{
    type: USER_CITY_TEAM_MEMBERSHIP_DELETE,
    cityTeamMember,
  };
}

export function deleteUserCityTeamMembershipSucceeded() {
  return <const>{
    type: USER_CITY_TEAM_MEMBERSHIP_DELETE_SUCCEEDED,
  };
}

export function deleteUserCityTeamMembershipErrored(errorMessage?: string) {
  return <const>{
    type: USER_CITY_TEAM_MEMBERSHIP_DELETE_ERRORED,
    errorMessage,
  };
}

export function startEditUserCityTeamMembership(cityTeamMember: CityTeamMember) {
  return <const>{
    type: EDIT_USER_CITY_TEAM_MEMBERSHIP_START,
    cityTeamMember,
  }
}

export function openEditUserCityTeamMembershipModal() {
  return <const>{
    type: EDIT_USER_CITY_TEAM_MEMBERSHIP_OPEN_MODAL,
    visibleModal: EDIT_USER_CITY_TEAM_MEMBERSHIP_MODAL,
  }
}

export function dismissEditUserCityTeamMembershipModal() {
  return <const>{
    type: EDIT_USER_CITY_TEAM_MEMBERSHIP_DISMISS_MODAL,
  }
}

export function editUserCityTeamMembershipSubmit(id: string, input: CityTeamMemberInput) {
  return <const>{
    type: EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA,
    id,
    input,
    editSubmitting: true,
  }
}

export function editUserCityTeamMembershipSubmitSucceeded() {
  return <const>{
    type: EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_SUCCEEDED,
    editSubmitting: false,
  }
}

export function editUserCityTeamMembershipSubmitErrored() {
  return <const>{
    type: EDIT_USER_CITY_TEAM_MEMBERSHIP_SUBMIT_DATA_ERRORED,
    editSubmitting: false,
  }
}

export function cancelEditUserCityTeamMembership() {
  return <const>{
    type: EDIT_USER_CITY_TEAM_MEMBERSHIP_CANCEL,
  }
}
