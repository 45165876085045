import { AddAdminUserAction } from '../../../actions/views/settings/AdminUsersListAction';
import {
  ADMIN_USERS_ADD_ADMIN_USER_SUBMIT,
  ADMIN_USERS_ADD_ADMIN_USER_SUBMIT_ERRORED,
  ADMIN_USERS_ADD_ADMIN_USER_SUBMIT_SUCCEEDED,
} from '../../../constants/views/settings/AdminUsersListConstants';
import { combineReducers } from 'redux';
import deleteAdminUserReducer from './DeleteAdminUserReducer';

export type AddAdminUserState = {
  submitting: boolean;
}

const initAddAdminUserState: AddAdminUserState = {
  submitting: false,
};

const addAdminUserReducer = (state = initAddAdminUserState, action: AddAdminUserAction): AddAdminUserState => {
  switch (action.type) {
    case ADMIN_USERS_ADD_ADMIN_USER_SUBMIT:
    case ADMIN_USERS_ADD_ADMIN_USER_SUBMIT_SUCCEEDED:
    case ADMIN_USERS_ADD_ADMIN_USER_SUBMIT_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
      };
    default:
      return {
        ...state,
      };
  }
};

const adminUsersListReducer = combineReducers({
  add: addAdminUserReducer,
  delete: deleteAdminUserReducer,
});

export default adminUsersListReducer;
