import { all, call, put, takeLatest } from 'redux-saga/effects';
import { REMOVABLE_AVATAR_DELETE } from '../../../../constants/views/users/components/RemovableAvatarConstants';
import {
  deleteUserAvatar, deleteUserAvatarFailed,
  deleteUserAvatarSucceeded,
} from '../../../../actions/views/users/components/RemovableAvatarAction';
import { UserService } from '../../../../../services/UserService';

function* removeAvatar({ userId }: ReturnType<typeof deleteUserAvatar>) {
  try {
    yield call(UserService.deleteUserAvatar, userId);
    yield put(deleteUserAvatarSucceeded());
  } catch (e) {
    yield put(deleteUserAvatarFailed());
  }
}

export function* removableAvatarSagas() {
  yield all([
    takeLatest(REMOVABLE_AVATAR_DELETE, removeAvatar),
  ]);
}
