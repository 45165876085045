import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { ISSUE_STATUS_CHANGED } from '../../../../constants/views/issues/components/IssueStatusConstants';
import {
  changeIssueErrored,
  changeIssueStatus, changeIssueStatusError,
  changeIssueStatusSucceeded,
} from '../../../../actions/views/issues/components/IssueStatusAction';
import { IssueStatus } from '../../../../../models/issues';
import { IssueService } from '../../../../../services/IssueService';
import { intlFormatMessage } from '../../../../../utils/intl';

function* changeIssueStatusSaga() {
  yield takeLatest(ISSUE_STATUS_CHANGED, function* (payload: ReturnType<typeof changeIssueStatus>) {
    try {
      switch (payload.newIssueStatus) {
        case IssueStatus.TO_VALIDATE:
          yield acceptForValidation(payload.issueId);
          break;
        case IssueStatus.DISCARDED:
          yield discard(payload.issueId);
          break;
        case IssueStatus.VALIDATED:
          yield validate(payload.issueId);
          break;
        default:
          yield put(changeIssueStatusError(
            intlFormatMessage('component.issue_status.error.change_not_implemented'),
          ));
          break;
      }
    } catch (e) {
      yield put(changeIssueErrored(e.message));
    }
  });
}

function* acceptForValidation(issueId: string) {
  yield call(IssueService.acceptForValidation, issueId);
  yield put(changeIssueStatusSucceeded());
}

function* discard(issueId: string) {
  yield call(IssueService.discard, issueId);
  yield put(changeIssueStatusSucceeded());
}

function* validate(issueId: string) {
  yield call(IssueService.validate, issueId);
  yield put(changeIssueStatusSucceeded());
}

export default function* issueStatusSagas() {
  yield all([
    fork(changeIssueStatusSaga),
  ]);
}
