import { AddUserCityTeamMembershipActions } from '../../../../actions/views/users/components/AddUserCityTeamMembershipActions';
import {
  ADD_USER_CITY_TEAM_MEMBERSHIP_CANCEL,
  ADD_USER_CITY_TEAM_MEMBERSHIP_CREATED,
  ADD_USER_CITY_TEAM_MEMBERSHIP_ERRORED,
  ADD_USER_CITY_TEAM_MEMBERSHIP_START,
  ADD_USER_CITY_TEAM_MEMBERSHIP_SUBMIT,
} from '../../../../constants/views/users/components/AddUserCityTeamMembershipConstants';

export type AddUserCityTeamMembershipState = {
  userId: string | null;
  submitting: boolean;
  numberAdded: number;
}

const initState: AddUserCityTeamMembershipState = {
  userId: null,
  submitting: false,
  numberAdded: 0,
};

export const addUserCityTeamMembershipReducer = (state = initState, action: AddUserCityTeamMembershipActions): AddUserCityTeamMembershipState => {
  switch (action.type) {
    case ADD_USER_CITY_TEAM_MEMBERSHIP_START:
    case ADD_USER_CITY_TEAM_MEMBERSHIP_CANCEL:
      return {
        ...state,
        userId: action.userId,
      }
    case ADD_USER_CITY_TEAM_MEMBERSHIP_SUBMIT:
    case ADD_USER_CITY_TEAM_MEMBERSHIP_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
      }
    case ADD_USER_CITY_TEAM_MEMBERSHIP_CREATED:
      return {
        ...state,
        submitting: action.submitting,
        numberAdded: state.numberAdded + 1,
      }
    default:
      return {
        ...state,
      };
  }
};
