import {
  PUBLISHED_CITIES_FETCH_COMPLETED,
  PUBLISHED_CITIES_FETCH_START,
  PUBLISHED_CITIES_FETCH_FAILED,
} from '../../constants/stats/publishedCities';

export type PublishedCitiesAction = ReturnType<typeof fetchPublishedCitiesCount
  | typeof fetchPublishedCitiesCountCompleted
  | typeof fetchPublishedCitiesCountFailed>;

export function fetchPublishedCitiesCount() {
  return <const>{
    type: PUBLISHED_CITIES_FETCH_START,
  }
}

export function fetchPublishedCitiesCountCompleted(count: number) {
  return <const>{
    type: PUBLISHED_CITIES_FETCH_COMPLETED,
    count,
  }
}

export function fetchPublishedCitiesCountFailed(errorMessage?: string) {
  return <const>{
    type: PUBLISHED_CITIES_FETCH_FAILED,
    errorMessage,
  }
}
