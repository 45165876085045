import { Button, Form, Modal, Select } from 'antd';
import IntlMessage from '../../../../components/util-components/IntlMessage';
import { intlFormatMessage } from '../../../../utils/intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelAddUserCityTeamMembership,
  startAddingUserCityTeamMembership, submitNewUserCityTeamMembership,
} from '../../../../redux/actions/views/users/components/AddUserCityTeamMembershipActions';
import { RootState } from '../../../../redux/store';
import { CityTeamMember, CityTeamMemberType } from '../../../../models/teamMembers';
import { useQuery } from '@apollo/client';
import { GET_ASSOCIATED_CITIES_MIN } from '../../../../services/CityService';
import { City } from '../../../../models/places';
import { getRequiredRule } from '../../../../utils/forms';
import { useForm } from 'antd/es/form/Form';
import { CityTeamMemberInput } from '../../../../graphql/teamMembers';
import { useEffect } from 'react';

type AddUserCityTeamMembershipButtonProps = {
  userId: string;
  cityTeamMemberships: CityTeamMember[];
}

export const ADD_USER_CITY_TEAM_MEMBERSHIP_MODAL = 'modal.addUserCityTeamMembership';

const AddUserCityTeamMembershipButton = ({ userId, cityTeamMemberships }: AddUserCityTeamMembershipButtonProps) => {
  const dispatch = useDispatch();
  const { visibleModal } = useSelector((state: RootState) => state.modal);
  const { submitting } = useSelector((state: RootState) => state.views.users.detail.addCityTeamMembership);
  const { data, loading } = useQuery<{ cities: City[] }>(GET_ASSOCIATED_CITIES_MIN);
  const assignedIdCities = cityTeamMemberships.map((cityTeamMembership) => cityTeamMembership.city.id);
  const associatedCities = data?.cities.filter((city) => assignedIdCities.indexOf(city.id) === -1) ?? [];

  const [form] = useForm();

  useEffect(() => {
    if (visibleModal !== null) {
      form.resetFields();
    }
  }, [form, visibleModal]);

  const onFinish = (values: Omit<CityTeamMemberInput, 'user'>) => {
    dispatch(submitNewUserCityTeamMembership({ ...values, user: userId }));
  };

  return (
    <>
      <Button type='primary' ghost onClick={() => dispatch(startAddingUserCityTeamMembership(userId))}>
        <IntlMessage id='component.user_city_memberships.button.assign' />
      </Button>
      <Modal title={intlFormatMessage('component.user_city_memberships.add_membership_modal.title')}
             visible={visibleModal === ADD_USER_CITY_TEAM_MEMBERSHIP_MODAL}
             onOk={() => form.submit()}
             okButtonProps={{ loading: submitting }}
             onCancel={() => dispatch(cancelAddUserCityTeamMembership())}>
        <Form onFinish={onFinish} form={form}>
          <Form.Item label={intlFormatMessage('form.label.city')}
                     name='city'
                     rules={[getRequiredRule()]}>
            <Select loading={loading} showSearch
                    filterOption={(input, option) => option !== undefined && option.props.children.toLowerCase().indexOf(input.toLocaleLowerCase()) >= 0}>
              {associatedCities.map((city) => (
                <Select.Option value={city.id} key={city.id}>
                  {city.name}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={intlFormatMessage('form.label.type')}
                     name='type'
                     required={true}
                     rules={[{ required: true, message: intlFormatMessage('form.errors.required') }]}>
            <Select>
              {Object.keys(CityTeamMemberType).map((key) => (
                <Select.Option value={key} key={key}>
                  <IntlMessage id={`cityTeamMember.type.${key.toLowerCase()}`} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddUserCityTeamMembershipButton;
