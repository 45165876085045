import { SIDE_NAV_LIGHT, NAV_TYPE_TOP } from '../constants/ThemeConstant';
import { env } from './EnvironmentConfig'

export const APP_NAME = 'AccessCity';
export const API_BASE_URL = env.API_ENDPOINT_URL
export const APP_PREFIX_PATH = '';
export const AUTH_PREFIX_PATH = '/auth';
export const GMAPS_API_KEY = env.gMapsApiKey;
export const GRAPHQL_PATH = '/admin';

export const THEME_CONFIG = {
	navCollapsed: false,
	sideNavTheme: SIDE_NAV_LIGHT,
	locale: 'es',
	navType: NAV_TYPE_TOP,
	topNavColor: '#4A5BE4',
	headerNavColor: '',
	mobileNav: false,
	currentTheme: 'light'
};
