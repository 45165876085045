import { Alert, Button, Divider, Form, Input, Select } from 'antd';
import { intlFormatMessage } from '../../../../../utils/intl';
import { getRequiredRule } from '../../../../../utils/forms';
import TextArea from 'antd/es/input/TextArea';
import IntlMessage from '../../../../../components/util-components/IntlMessage';
import { useSelector } from 'react-redux';
import { IssueTypeInput } from '../../../../../services/IssueTypeService';
import { useQuery } from '@apollo/client';
import { IssueGroup, Normative } from '../../../../../models';
import { GET_NORMATIVE_ISSUE_GROUPS } from '../../../../../services/IssueGroupService';
import { RootState } from '../../../../../redux/store';
import { useEffect } from 'react';

type IssueTypeFormProps = {
  normative?: Normative,
  onFinish: (input: IssueTypeInput) => void,
  onCancel: () => void,
  okLabel: string,
}

const IssueTypeForm = ({ onFinish, normative, onCancel, okLabel }: IssueTypeFormProps) => {
  const [form] = Form.useForm();

  const { data } = useQuery<{ normativeIssueGroups: IssueGroup[] }>(GET_NORMATIVE_ISSUE_GROUPS, { variables: { normativeId: normative?.id } });
  const issueGroups: IssueGroup[] = data?.normativeIssueGroups ?? [];

  const {
    resetForm,
    errorMessage,
    submitting,
    selectedIssueType: issueType,
  } = useSelector((state: RootState) => state.views.issueTypesComponent);

  const issueGroupFilter = useSelector((state: RootState) => state.views.normativeDetail.issueGroupFilter);

  useEffect(() => {
    if (resetForm) {
      form.resetFields();
    }
    form.setFieldsValue({
      ...issueType,
      issueGroupId: issueType?.issueGroup.id ?? issueGroupFilter,
    });
  }, [resetForm, form, issueType, issueGroupFilter]);

  return (
    <Form labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}
          onFinish={onFinish}
          form={form}>
      {errorMessage ? (
        <Alert message={errorMessage} type='error' className='mb-4' />
      ) : (
        ''
      )}
      <Form.Item name='issueGroupId'
                 label={intlFormatMessage('form.label.issue_group')}
                 rules={[getRequiredRule()]}>
        <Select
          showSearch
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          defaultValue={issueGroupFilter}>
          {issueGroups.map((issueGroup) => (
            <Select.Option key={issueGroup.id} value={issueGroup.id}>{issueGroup.name}</Select.Option>
          ))}
        </Select>
      </Form.Item>
      <Form.Item name='code'
                 label={intlFormatMessage('form.label.code')}
                 rules={[getRequiredRule()]}>
        <Input />
      </Form.Item>
      <Form.Item name='title'
                 label={intlFormatMessage('form.label.title')}
                 rules={[getRequiredRule()]}>
        <Input />
      </Form.Item>
      <Form.Item name='information'
                 label={intlFormatMessage('form.label.information')}
                 rules={[getRequiredRule()]}>
        <TextArea />
      </Form.Item>
      <Divider />
      <div className='text-right'>
        <Button className='mr-2'
                onClick={onCancel}>
          <IntlMessage id='button.cancel' />
        </Button>
        <Button className='mr-2' type='primary' htmlType='submit' loading={submitting}>
          <IntlMessage id={okLabel} />
        </Button>
      </div>
    </Form>
  );
};

export default IssueTypeForm;
