import {
  AUTH_FORGOT_PASSWORD_RESET_STATE,
  AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL,
  AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_FAILED,
  AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_SUCCEEDED,
} from '../../constants/Auth';

export type ForgotPasswordAction = ReturnType<typeof forgotPasswordSubmitEmail
  | typeof forgotPasswordSubmitEmailSucceeded
  | typeof forgotPasswordSubmitEmailFailed
  | typeof forgotPasswordSubmitEmailResetState>;

export const forgotPasswordSubmitEmail = (email: string) => {
  return <const>{
    type: AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL,
    email,
  };
};

export const forgotPasswordSubmitEmailSucceeded = () => {
  return <const>{
    type: AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_SUCCEEDED,
  };
};

export const forgotPasswordSubmitEmailFailed = () => {
  return <const>{
    type: AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_FAILED,
  };
};

export const forgotPasswordSubmitEmailResetState = () => {
  return <const>{
    type: AUTH_FORGOT_PASSWORD_RESET_STATE,
  };
};
