import { GeneralSettingsActions } from '../../../actions/views/settings/GeneralSettingsActions';
import {
  CONFIG_VALUE_FINISH_EDIT,
  CONFIG_VALUE_SET_ERRORED,
  CONFIG_VALUE_SET_SUCCEEDED,
  CONFIG_VALUE_START_EDIT,
  CONFIG_VALUE_SUBMIT_NEW_VALUE,
} from '../../../constants/views/settings/GeneralSettingsConstants';

const configValueInitialState = {
  editing: false,
  submitting: false,
};

export type ConfigValueState = {
  editing: boolean;
  submitting: boolean;
  value?: string;
}

export type GeneralSettingsState = {
  [key: string]: ConfigValueState;
}

const initState: GeneralSettingsState = {
  terms: {...configValueInitialState},
  'help.users': {...configValueInitialState},
  'help.team_managers': {...configValueInitialState},
  'help.team_supervisors': {...configValueInitialState},
  help: {...configValueInitialState},
  conditions: {...configValueInitialState},
  policy: {...configValueInitialState},
  android_version: {...configValueInitialState},
  ios_version: {...configValueInitialState},
};


const generalSettingsReducer = (state = initState, action: GeneralSettingsActions): GeneralSettingsState => {
  const newState = { ...state };

  switch (action.type) {
    case CONFIG_VALUE_START_EDIT:
      newState[action.key] = {
        ...newState[action.key],
        editing: action.editing,
      };
      return newState;
    case CONFIG_VALUE_FINISH_EDIT:
      newState[action.key] = {
        ...newState[action.key],
        editing: action.editing,
      };
      return newState;
    case CONFIG_VALUE_SUBMIT_NEW_VALUE:
      newState[action.key] = {
        ...newState[action.key],
        value: action.value,
        submitting: action.submitting,
      };
      return newState;
    case CONFIG_VALUE_SET_SUCCEEDED:
      newState[action.key] = {
        ...newState[action.key],
        submitting: action.submitting,
        editing: action.editing,
      };
      return newState;
    case CONFIG_VALUE_SET_ERRORED:
      newState[action.key] = {
        ...newState[action.key],
        submitting: action.submitting,
      };
      return newState;
    default:
      return {
        ...state,
      };
  }
};

export default generalSettingsReducer;
