import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  submitNewCityStatus,
  submitNewCityStatusFailed, submitNewCityStatusSucceeded,
} from '../../../../actions/views/cities/components/CityStatusInfoAction';
import { CITY_STATUS_INFO_SUBMIT } from '../../../../constants/views/cities/components/CityStatusInfo';
import { CityService } from '../../../../../services/CityService';

function* handleSubmitNewCityStatusSaga({ cityId, newStatus }: ReturnType<typeof submitNewCityStatus>) {
  try {
    yield call(CityService.edit, { id: cityId, status: newStatus });
    yield put(submitNewCityStatusSucceeded());
  } catch (e) {
    yield put(submitNewCityStatusFailed());
  }
}

export default function* cityStatusInfoSagas() {
  yield all([
    takeEvery(CITY_STATUS_INFO_SUBMIT, handleSubmitNewCityStatusSaga),
  ]);
}
