import { all, fork, takeEvery } from 'redux-saga/effects';
import { FORM_SUBMISSION_SUCCEEDED } from '../constants/Form';
import { notification } from 'antd';
import intl from '../../utils/intl';

function* notifySuccess() {
  function notifyOperationSucceeded() {
    return function({ successMessage }: { type: string, successMessage?: string }) {
      const messageId = successMessage || 'notification.operation.success.message';

      notification.success({
        message: intl.formatMessage({ id: messageId }),
      });
    };
  }

  yield takeEvery(FORM_SUBMISSION_SUCCEEDED, function() {
    notification.success({
      message: intl.formatMessage({ id: 'notification.operation.success.message' }),
    });
  });

  /*yield takeEvery(
    ({ type }: { type: string }) => /.+\/edit\/success\/.+/gm.test(type),
    function() {
      notification.success({
        message: intl.formatMessage({ id: 'notification.edit.success.message' }),
        description: intl.formatMessage({ id: 'notification.edit.success.description' }),
      });
    },
  );*/

  // yield takeEvery(
  //   ({ type }: { type: string, successMessage?: string }) => /.+\/form\/success\/.+/gm.test(type),
  //   notifyOperationSucceeded(),
  // );

  yield takeEvery(
    ({ type }: { type: string, successMessage?: string }) => /.+\/success\/.+/gm.test(type),
    notifyOperationSucceeded(),
  );

  yield takeEvery(
    ({ type }: { type: string }) => /.+\/delete\/success\/.+/gm.test(type),
    function() {
      notification.success({
        message: intl.formatMessage({ id: 'notification.delete.success.message' }),
        description: intl.formatMessage({ id: 'notification.delete.success.description' }),
      });
    },
  );
}

function* notifyError() {
  yield takeEvery(
    ({ type }: { type: string, errorMessage?: string }) => /.+\/error\/.+/gm.test(type),
    function({ errorMessage }: { type: string, errorMessage?: string }) {
      const description = errorMessage || intl.formatMessage({ id: 'notification.operation.error.description' });
      notification.error({
        message: intl.formatMessage({ id: 'notification.operation.error.message' }),
        description,
      });
    },
  );
}

export default function* rootSaga() {
  yield all([
    fork(notifySuccess),
    fork(notifyError),
  ]);
}
