export const NORMATIVE_FETCHED = 'views/data/NORMATIVE_FETCHED';

export const NORMATIVE_DETAIL_START_NAME_EDIT = 'views/edit/START_NORMATIVE_NAME_EDIT';
export const NORMATIVE_DETAIL_CANCEL_NAME_EDIT = 'views/edit/CANCEL_NORMATIVE_NAME_EDIT';
export const NORMATIVE_DETAIL_SUBMIT_NAME = 'views/edit/SUBMIT_NORMATIVE_EDIT';
export const NORMATIVE_DETAIL_SUBMIT_SUCCESSFUL = 'views/edit/success/NORMATIVE_EDIT';
export const NORMATIVE_DETAIL_SUBMIT_ERROR = 'views/edit/error/NORMATIVE_EDIT';
export const NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_CHANGED = 'views/filter/ISSUE_GROUP_FILTER_CHANGED';
export const NORMATIVE_DETAIL_ISSUE_GROUP_VISIBILITY_CHANGED = 'views/filter/ISSUE_GROUP_VISIBILITY_CHANGED';
export const NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_RESET = 'views/filter/ISSUE_GROUP_FILTER_RESET';
