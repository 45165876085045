import { User } from '../../../../../models';
import { DeleteUserAction } from '../../../../actions/views/users/components/DeleteUserAction';
import {
  DELETE_USER_DISMISS_CONFIRMATION_MODAL,
  DELETE_USER_START,
  DELETE_USER_SUBMIT,
  DELETE_USER_SUBMIT_ERRORED,
  DELETE_USER_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/users/components/DeleteUserConstants';

export type DeleteUserState = {
  user: User | null;
  submitting: boolean;
}

const initState: DeleteUserState = {
  user: null,
  submitting: false,
};

const deleteUserReducer = (state = initState, action: DeleteUserAction): DeleteUserState => {
  switch (action.type) {
    case DELETE_USER_START:
    case DELETE_USER_DISMISS_CONFIRMATION_MODAL:
      return {
        ...state,
        user: action.user,
      }
    case DELETE_USER_SUBMIT:
    case DELETE_USER_SUBMIT_SUCCEEDED:
    case DELETE_USER_SUBMIT_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
      }
    default:
      return {
        ...state,
      };
  }
};

export default deleteUserReducer;
