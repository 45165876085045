import { all, call, fork, put, takeEvery, select } from 'redux-saga/effects';
import { NormativeService } from '../../../services/NormativeService';
import {
  normativeEditSubmissionEndedSuccessfully,
  normativeEditSubmissionEndedWithError, resetIssueGroupFilter,
  submitNormativeEdit,
} from '../../actions';
import { NORMATIVE_DETAIL_SUBMIT_NAME } from '../../constants/views/NormativeDetailConstants';
import { ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_SUCCEEDED } from '../../constants/views/normative/components/IssueGroupsComponent';
import { RootState } from '../../store';
import { IssueGroupVisibility, IssueTypeVisibility, Normative } from '../../../models';
import { IssueGroupService } from '../../../services/IssueGroupService';
import { IssueTypeService } from '../../../services/IssueTypeService';

function* submitNormativeEditEffects() {
  yield takeEvery(NORMATIVE_DETAIL_SUBMIT_NAME, function* (payload: ReturnType<typeof submitNormativeEdit>) {
    try {
      yield call(NormativeService.edit, payload.id, payload.input);
      yield put(normativeEditSubmissionEndedSuccessfully());
    } catch (e) {
      yield put(normativeEditSubmissionEndedWithError(e.message));
    }
  });
}

const getNormative = (state: RootState): Normative | null => state.views.normativeDetail.normative;

function* refetchIssueGroupsAndRelatedInfo() {
  const normative: Normative | null = yield select(getNormative);
  if (normative) {
    yield all([
      call(IssueGroupService.getNormativeIssueGroups, normative.id, IssueGroupVisibility.ALL, 'network-only'),
      call(IssueGroupService.getNormativeIssueGroups, normative.id, IssueGroupVisibility.ONLY_VISIBLE, 'network-only'),
      call(IssueTypeService.getNormativeIssueTypes, normative.id, IssueTypeVisibility.ALL, 'network-only'),
      call(IssueTypeService.getNormativeIssueTypes, normative.id, IssueTypeVisibility.ONLY_VISIBLE, 'network-only'),
      put(resetIssueGroupFilter()),
    ]);
  }
}

export default function* normativeDetailSaga() {
  yield all([
    fork(submitNormativeEditEffects),
    fork(function* () {
      yield takeEvery(ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_SUCCEEDED, refetchIssueGroupsAndRelatedInfo);
    }),
  ]);
}
