import React from 'react';
import { Provider } from 'react-redux';
import store, { history } from './redux/store';
import Views from './views';
import { Route, Switch } from 'react-router-dom';
import { ThemeSwitcherProvider } from 'react-css-theme-switcher';
import { THEME_CONFIG } from './configs/AppConfig';
import { apolloClient } from './clients/ApolloClient';
import { ApolloProvider } from '@apollo/client';
import InitializeApp from './components/util-components/InitializeApp';
import { ConnectedRouter } from 'connected-react-router/immutable';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

function App() {
  return (
    <div className='App'>
      <ApolloProvider client={apolloClient}>
        <Provider store={store}>
          <ConnectedRouter history={history}>
            <InitializeApp />
            <ThemeSwitcherProvider themeMap={themes} defaultTheme={THEME_CONFIG.currentTheme}
                                   insertionPoint='styles-insertion-point'>
              <Switch>
                <Route path='/' component={Views} />
              </Switch>
            </ThemeSwitcherProvider>
          </ConnectedRouter>
        </Provider>
      </ApolloProvider>
    </div>
  );
}

export default App;
