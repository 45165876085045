import { IssueStatus } from '../../../../../models/issues';
import {
  ISSUE_STATUS_CHANGE_NOT_IMPLEMENTED_ERROR,
  ISSUE_STATUS_CHANGE_SUBMISSION_ERRORED,
  ISSUE_STATUS_CHANGE_SUCCEEDED,
  ISSUE_STATUS_CHANGED,
} from '../../../../constants/views/issues/components/IssueStatusConstants';

export type IssueStatusAction = ReturnType<typeof changeIssueStatus
  | typeof changeIssueStatusSucceeded
  | typeof changeIssueStatusError
  | typeof changeIssueErrored>;

export function changeIssueStatus(issueId: string, newIssueStatus: IssueStatus) {
  return <const>{
    type: ISSUE_STATUS_CHANGED,
    issueId,
    newIssueStatus,
    submittingStatus: true,
  };
}

export function changeIssueStatusSucceeded() {
  return <const>{
    type: ISSUE_STATUS_CHANGE_SUCCEEDED,
    submittingStatus: false,
  };
}

export function changeIssueStatusError(errorMessage: string) {
  return <const>{
    type: ISSUE_STATUS_CHANGE_NOT_IMPLEMENTED_ERROR,
    submittingStatus: false,
    errorMessage,
  }
}

export function changeIssueErrored(errorMessage: string) {
  return <const>{
    type: ISSUE_STATUS_CHANGE_SUBMISSION_ERRORED,
    submitting: false,
    errorMessage,
  };
}
