import { all, call, cancel, cancelled, fork, put, take, takeLatest } from 'redux-saga/effects';
import { Task } from 'redux-saga';
import {
  ADMIN_USERS_ADD_ADMIN_USER_CANCEL, ADMIN_USERS_ADD_ADMIN_USER_START,
  ADMIN_USERS_ADD_ADMIN_USER_SUBMIT,
} from '../../../constants/views/settings/AdminUsersListConstants';
import {
  dismissAddAdminUserModal, newAdminUserSubmissionErrored,
  newAdminUserSubmissionSucceeded,
  openAddAdminUserModal,
} from '../../../actions/views/settings/AdminUsersListAction';
import { AdminUserService } from '../../../../services/AdminUserService';
import {
  deleteAdminUserFailed,
  deleteAdminUserSucceeded,
  dismissDeleteAdminUserConfirmationModal,
  openDeleteAdminUserConfirmationModal,
} from '../../../actions/views/settings/DeleteAdminUserAction';
import {
  DELETE_ADMIN_USER_ASK_CONFIRM, DELETE_ADMIN_USER_CANCEL,
  DELETE_ADMIN_USER_CONFIRM,
} from '../../../constants/views/settings/DeleteAdminUserConstants';

function* handleAddAdminUser() {
  yield put(openAddAdminUserModal());
  try {
    let wasSuccessful = false;
    while (!wasSuccessful) {
      const { input } = yield take(ADMIN_USERS_ADD_ADMIN_USER_SUBMIT);
      yield call(AdminUserService.addAdminUser, input);
      yield put(newAdminUserSubmissionSucceeded());
      yield put(dismissAddAdminUserModal());
      wasSuccessful = true;
    }
  } catch (e) {
    yield put(newAdminUserSubmissionErrored());
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put(dismissAddAdminUserModal());
    }
  }
}

function* addAdminUserSaga() {
  const task: Task = yield fork(handleAddAdminUser);
  yield take(ADMIN_USERS_ADD_ADMIN_USER_CANCEL);
  yield cancel(task);
}

function* handleDeleteAdminUser() {
  yield put(openDeleteAdminUserConfirmationModal());
  try {
    let wasSuccessful = false;
    while (!wasSuccessful) {
      const { id } = yield take(DELETE_ADMIN_USER_CONFIRM);
      try {
        yield call(AdminUserService.deleteAdminUser, id);
        yield put(deleteAdminUserSucceeded('notification.admin_user.deleted'));
        yield put(dismissDeleteAdminUserConfirmationModal());
        wasSuccessful = true;
      } catch (e) {
        yield put(deleteAdminUserFailed());
      }
    }
  } finally {
    const isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put(dismissDeleteAdminUserConfirmationModal());
    }
  }
}

function* deleteAdminUserSaga() {
  const task: Task = yield fork(handleDeleteAdminUser);
  yield take(DELETE_ADMIN_USER_CANCEL);
  yield cancel(task);
}

export default function* adminUsersListSagas() {
  yield all([
    takeLatest(ADMIN_USERS_ADD_ADMIN_USER_START, addAdminUserSaga),
    takeLatest(DELETE_ADMIN_USER_ASK_CONFIRM, deleteAdminUserSaga),
  ]);
}
