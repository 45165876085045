import { MODAL_CLEAR_VISIBLE_MODAL_NAME, MODAL_SET_VISIBLE_MODAL_NAME } from '../constants/views/ModalsConstants';

export type ModalAction = ReturnType<typeof setModalVisibleName | typeof clearModalVisibleName>;

export function setModalVisibleName(visibleModal: string) {
  return <const>{
    type: MODAL_SET_VISIBLE_MODAL_NAME,
    visibleModal,
  }
}

export function clearModalVisibleName() {
  return <const>{
    type: MODAL_CLEAR_VISIBLE_MODAL_NAME,
    visibleModal: null,
  }
}
