import { all, fork } from 'redux-saga/effects';
import publishedCitiesSagas from './publishedCitiesSagas';
import issuesStatsSagas from './issuesStatsSagas';

export default function* statsSagas() {
  yield all([
    fork(publishedCitiesSagas),
    fork(issuesStatsSagas),
  ]);
}
