import { all, fork, put, takeLatest } from 'redux-saga/effects';
import { clearModalVisibleName, setModalVisibleName } from '../actions/ModalActions';

export default function* modalSaga() {
  yield all([
    fork(function* () {
      yield takeLatest<{ type: string, visibleModal: string }>(
        ({ type }: { type: string }) => /.+\/modal\/open\/.+/gm.test(type),
        function* ({ visibleModal }: { visibleModal: string }) {
          yield put(setModalVisibleName(visibleModal));
        }
      );
    }),
    fork(function* () {
      yield takeLatest(
        ({ type }: { type: string }) => /.+\/modal\/dismiss\/.+/gm.test(type),
        function* () {
          yield put(clearModalVisibleName());
        },
      );
    }),
  ]);
}
