import { NotificationAudience } from '../../../../models/notifications';
import { SendNotificationsActions } from '../../../actions/views/notifications/SendNotificationsActions';
import {
  SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_ERRORED,
  SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_FORM_RESET,
  SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_SUCCEEDED,
  SEND_NOTIFICATIONS_SUBMIT_NEW_BROADCAST_NOTIFICATION,
} from '../../../constants/views/notifications/SendNotificationsConstants';

export type NewBroadcastNotificationInput = {
  audience: NotificationAudience,
  title: string,
  message: string,
}

export type SendNotificationsState = {
  submitting: boolean,
  data: NewBroadcastNotificationInput | null,
  resetForm: boolean,
}

const initState: SendNotificationsState = {
  submitting: false,
  data: null,
  resetForm: false,
};

const sendNotificationsReducer = (state = initState, action: SendNotificationsActions) => {
  switch (action.type) {
    case SEND_NOTIFICATIONS_SUBMIT_NEW_BROADCAST_NOTIFICATION:
      return {
        ...state,
        submitting: action.submitting,
        data: action.data,
      };
    case SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
      };
    case SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_SUCCEEDED:
      return {
        ...state,
        submitting: action.submitting,
        resetForm: action.resetForm,
      };
    case SEND_NOTIFICATIONS_NEW_BROADCAST_NOTIFICATION_FORM_RESET:
      return {
        ...state,
        resetForm: action.resetForm,
      };
    default:
      return {
        ...state,
      };
  }
};

export default sendNotificationsReducer;
