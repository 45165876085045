import { Route } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { AuthState } from '../../../redux/reducers/Auth';
import Loading from '../../shared-components/Loading';
import { push } from 'connected-react-router';

export const PrivateRoute = ({ component: Component, ...rest }: any) => {
  const dispatch = useDispatch();
  const { user, checkedAuthentication } = useSelector(
    (state: any): AuthState => state.auth,
  );

  if (checkedAuthentication && !user) {
    dispatch(push('/auth/login'));
  }

  return (
    <>
      { checkedAuthentication ? (
        <Route
          {...rest}
          render={(props) =>
            <Component {...props} {...rest} />
          }
        />
      ) : (
        <Loading />
      )}
    </>
  );
};

export default PrivateRoute;
