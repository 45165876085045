import { all, call, put, takeEvery } from 'redux-saga/effects';
import {
  dismissCityNameEdit,
  submitCityNameEdit, submitCityNameEditFailed,
  submitCityNameEditSucceeded,
} from '../../../../actions/views/cities/components/CityNameAction';
import { CITY_NAME_EDIT_SUBMIT } from '../../../../constants/views/cities/components/CityName';
import { CityService } from '../../../../../services/CityService';

function* handleSubmitNewCityNameSaga({ cityId, newName }: ReturnType<typeof submitCityNameEdit>) {
  try {
    console.log('Lets change de name ===> ', cityId, newName);
    yield call(CityService.edit, { id: cityId, name: newName });
    yield put(submitCityNameEditSucceeded());
    yield put(dismissCityNameEdit());
  } catch (e) {
    yield put(submitCityNameEditFailed());
  }
}

export default function* cityNameSagas() {
  yield all([
    takeEvery(CITY_NAME_EDIT_SUBMIT, handleSubmitNewCityNameSaga),
  ]);
}
