import { all, call, cancel, cancelled, fork, put, take, takeEvery } from 'redux-saga/effects';
import {
  SEND_NOTIFICATION_TO_USER_CANCEL,
  SEND_NOTIFICATION_TO_USER_START, SEND_NOTIFICATION_TO_USER_SUBMIT,
} from '../../../../constants/views/users/components/SendNotificationToUserConstants';
import {
  dismissSendNotificationToUserModal,
  openSendNotificationToUserModal,
  submitNewNotificationToUser,
  submitNewNotificationToUserFailed,
  submitNewNotificationToUserSucceeded,
} from '../../../../actions/views/users/components/SendNotificationToUserAction';
import { Task } from 'redux-saga';
import { LOCATION_CHANGE } from 'connected-react-router';
import { NotificationService } from '../../../../../services/NotificationService';

function* handleSendNotificationToUserSaga() {
  try {
    yield put(openSendNotificationToUserModal());
    let wasSuccessful = false;
    while (!wasSuccessful) {
      const { input }: ReturnType<typeof submitNewNotificationToUser> = yield take(SEND_NOTIFICATION_TO_USER_SUBMIT);
      try {
        yield call(NotificationService.sendToUser, input);
        yield put(submitNewNotificationToUserSucceeded());
        yield put(dismissSendNotificationToUserModal());
        wasSuccessful = true;
      } catch (e) {
        yield put(submitNewNotificationToUserFailed());
      }
    }
  } finally {
    let isCancelled: boolean = yield cancelled();
    if (isCancelled) {
      yield put(dismissSendNotificationToUserModal());
    }
  }
}

function* startSendNotificationToUserSaga() {
  const task: Task = yield fork(handleSendNotificationToUserSaga);
  yield take([SEND_NOTIFICATION_TO_USER_CANCEL, LOCATION_CHANGE]);
  yield cancel(task);
}

export default function* sendNotificationToUserSagas() {
  yield all([
    takeEvery(SEND_NOTIFICATION_TO_USER_START, startSendNotificationToUserSaga),
  ]);
}
