import fetch from '../auth/FetchInterceptor';
import { User } from '../models';
import { GRAPHQL_PATH } from '../configs/AppConfig';

type WhoAmIResponse = {
  data?: {
    whoAmI: User,
  };

  errors?: {
    message: string,
  }[];
}

export class AuthService {
  static async whoAmI(): Promise<User | null> {
    return fetch(GRAPHQL_PATH, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'public-request': 'true',
      },
      data: JSON.stringify({ query: `
        query {
          whoAmI {
            id
            name
            email
          }
        }
      `})
    }).then(({ data, errors }: WhoAmIResponse) => {
      if (errors) {
        throw new Error(errors[0]?.message);
      }

      return data?.whoAmI ?? null;
    });
  }
}
