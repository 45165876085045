import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { ADD_NORMATIVE_SUBMIT_NEW_NORMATIVE } from '../../../../constants/views/normative/components/AddNormativeConstants';
import {
  dismissAddNormativeModal,
  newNormativeErrored,
  newNormativeSucceeded,
  submitNewNormative,
} from '../../../../actions/views/normative/components/AddNormativeActions';
import { NormativeService } from '../../../../../services/NormativeService';

function* addNewNormativeSaga() {
  yield takeLatest(ADD_NORMATIVE_SUBMIT_NEW_NORMATIVE, function* (payload: ReturnType<typeof submitNewNormative>) {
    try {
      yield call(NormativeService.add, payload.normative);
      yield put(newNormativeSucceeded());
      yield put(dismissAddNormativeModal());
    } catch (e) {
      yield put(newNormativeErrored(e.message));
    }
  });
}

export default function* addNormativeSagas() {
  yield all([
    fork(addNewNormativeSaga),
  ]);
}
