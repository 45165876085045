import {
  CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_ERRORED,
  CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_SUCCEEDED,
  CITY_TEAM_MEMBERS_ASK_DELETE_CONFIRMATION,
  CITY_TEAM_MEMBERS_CANCEL_DELETE, CITY_TEAM_MEMBERS_DELETE_ERRORED, CITY_TEAM_MEMBERS_DELETE_SUCCEEDED,
  CITY_TEAM_MEMBERS_DISMISS_ADD_TEAM_MEMBER,
  CITY_TEAM_MEMBERS_OPEN_ADD_TEAM_MEMBER,
  CITY_TEAM_MEMBERS_SUBMIT_ADD_TEAM_MEMBER, CITY_TEAM_MEMBERS_SUBMIT_DELETE,
} from '../../../../constants/views/cities/components/CityTeamMembersComponentConstants';
import {
  ADD_TEAM_MEMBER_MODAL_NAME,
  CONFIRM_DELETE_TEAM_MEMBER_MODAL_NAME,
} from '../../../../../views/app-views/cities/components/CityTeamMembersComponent';
import { CityTeamMemberInput } from '../../../../../graphql/teamMembers';
import { CityTeamMember } from '../../../../../models/teamMembers';
import { RouterLocationChangeAction } from '../../../../Router';

export type CityTeamMembersComponentAction = ReturnType<typeof openAddTeamMemberModal
  | typeof dismissAddTeamMemberModal
  | typeof addNewCityTeamMember
  | typeof addNewCityTeamMemberSucceeded
  | typeof addNewCityTeamMemberErrored
  | typeof askCityTeamMemberConfirmation
  | typeof cancelCityTeamMemberDeletion
  | typeof deleteCityTeamMember
  | typeof deleteCityTeamMemberSucceeded
  | typeof deleteCityTeamMemberErrored>
  | RouterLocationChangeAction;

export function openAddTeamMemberModal() {
  return <const>{
    type: CITY_TEAM_MEMBERS_OPEN_ADD_TEAM_MEMBER,
    visibleModal: ADD_TEAM_MEMBER_MODAL_NAME,
  };
}

export function dismissAddTeamMemberModal() {
  return <const>{
    type: CITY_TEAM_MEMBERS_DISMISS_ADD_TEAM_MEMBER,
    visibleModal: null,
    submitting: false,
    errorMessage: null,
    input: null,
  };
}

export function addNewCityTeamMember(input: CityTeamMemberInput) {
  return <const>{
    type: CITY_TEAM_MEMBERS_SUBMIT_ADD_TEAM_MEMBER,
    submitting: true,
    input,
    errorMessage: null,
  };
}

export function addNewCityTeamMemberSucceeded() {
  return <const>{
    type: CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_SUCCEEDED,
    submitting: false,
    input: null,
  };
}

export function addNewCityTeamMemberErrored(errorMessage: string) {
  return <const>{
    type: CITY_TEAM_MEMBERS_ADD_TEAM_MEMBER_ERRORED,
    submitting: false,
    errorMessage,
  };
}

export function askCityTeamMemberConfirmation(cityTeamMember: CityTeamMember) {
  return <const>{
    type: CITY_TEAM_MEMBERS_ASK_DELETE_CONFIRMATION,
    cityTeamMemberToDelete: cityTeamMember,
    visibleModal: CONFIRM_DELETE_TEAM_MEMBER_MODAL_NAME,
  };
}

export function cancelCityTeamMemberDeletion() {
  return <const>{
    type: CITY_TEAM_MEMBERS_CANCEL_DELETE,
    visibleModal: null,
    cityTeamMemberToDelete: null,
  };
}

export function deleteCityTeamMember(cityTeamMember: CityTeamMember) {
  return <const>{
    type: CITY_TEAM_MEMBERS_SUBMIT_DELETE,
    cityTeamMemberToDelete: cityTeamMember,
    submitting: true,
  };
}

export function deleteCityTeamMemberSucceeded() {
  return <const>{
    type: CITY_TEAM_MEMBERS_DELETE_SUCCEEDED,
    submitting: false,
  };
}

export function deleteCityTeamMemberErrored(errorMessage: string) {
  return <const>{
    type: CITY_TEAM_MEMBERS_DELETE_ERRORED,
    errorMessage,
    submitting: false,
  };
}
