import { Tag } from 'antd';
import { CityStatus } from '../../models/places';
import IntlMessage from '../util-components/IntlMessage';

const cityStatusMap = new Map([
  [CityStatus.DRAFT, 'default'],
  [CityStatus.ACTIVE, 'yellow'],
  [CityStatus.PUBLISHED, 'green'],
]);

type CityStatusProps = {
  status: CityStatus;
  size?: 'sm' | 'md'
}

const CityStatusTag = ({ status, size }: CityStatusProps) => {
  return (
    <Tag key={status} color={cityStatusMap.get(status)} className={`font-size-${size ?? 'sm'}`}>
      <IntlMessage id={`tag.city_status.${status}`} />
    </Tag>
  )
};

export default CityStatusTag;
