import { Alert, Button, Modal } from 'antd';
import { User } from '../../../../models';
import IntlMessage from '../../../../components/util-components/IntlMessage';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { intlFormatMessage } from '../../../../utils/intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  cancelUserLockedAction,
  startUserLock, startUserUnlock,
  userLockedActionConfirmed,
} from '../../../../redux/actions/views/users/components/UserLockedAction';
import UserLockedTag from '../../../../components/shared-components/UserLockedTag';
import SidebarInfoRow from '../../../../components/shared-components/SidebarInfoRow';

export const USER_LOCKED_CONFIRM_MODAL = 'modal.userLockedConfirmation';

type UserBaseLockActionButtonProps = {
  user?: User,
  type: 'default' | 'primary'
  danger: boolean,
  confirmTitle: string,
  actionButtonText: string,
  onClick: () => void,
  alertMessage: string,
  confirmationMessage: string,
}

const UserBaseLockActionButton = ({
                                    user,
                                    type,
                                    danger,
                                    onClick,
                                    confirmTitle,
                                    actionButtonText,
                                    alertMessage,
                                    confirmationMessage,
                                  }: UserBaseLockActionButtonProps) => {
  const dispatch = useDispatch();
  const { visibleModal } = useSelector((state: RootState) => state.modal);
  const { lockedSubmitting } = useSelector((state: RootState) => state.views.users.detail.userLocked);

  return (
    <>
      <Button ghost danger={danger} size='small' type={type}
              onClick={() => onClick()}> {actionButtonText} </Button>
      <Modal title={confirmTitle}
             visible={visibleModal === USER_LOCKED_CONFIRM_MODAL}
             okButtonProps={{ danger, loading: lockedSubmitting }}
             okText={actionButtonText}
             onCancel={() => dispatch(cancelUserLockedAction())}
             onOk={() => dispatch(userLockedActionConfirmed(user?.id ?? ''))}>
        <div className='text-center font-weight-bold font-size-md mb-4'>
          {confirmationMessage}
        </div>
        <Alert message={alertMessage} type='warning' showIcon />
      </Modal>
    </>
  );
};

const UserLockButton = ({ user }: { user?: User }) => {
  const dispatch = useDispatch();

  return (
    <>
      <UserBaseLockActionButton danger={true} type='default'
                                user={user}
                                onClick={() => dispatch(startUserLock())}
                                confirmTitle={intlFormatMessage('modal.user_locked.lock.title')}
                                actionButtonText={intlFormatMessage('button.lock')}
                                alertMessage={intlFormatMessage('component.user_locked.lock_warning')}
                                confirmationMessage={intlFormatMessage('component.user_locked.lock_confirm', { user: user?.name ?? '' })} />
    </>
  );
};

const UserUnlockButton = ({ user }: { user?: User }) => {
  const dispatch = useDispatch();

  return (
    <>
      <UserBaseLockActionButton danger={false} type='primary'
                                user={user}
                                onClick={() => dispatch(startUserUnlock())}
                                confirmTitle={intlFormatMessage('modal.user_locked.unlock.title')}
                                actionButtonText={intlFormatMessage('button.unlock')}
                                alertMessage={intlFormatMessage('component.user_locked.unlock_warning')}
                                confirmationMessage={intlFormatMessage('component.user_locked.unlock_confirm', { user: user?.name ?? '' })} />
    </>
  );
};

const UserLockComponent = ({ user, ...props }: { user?: User, className?: string }) => {
  const icon = !user?.locked
    ? <CheckCircleOutlined className='mr-2' />
    : <CloseCircleOutlined className='mr-2' />;

  return (
    <>
      <SidebarInfoRow title={<>{icon} <IntlMessage id='page.user.label.state' /></>} {...props}>
        <UserLockedTag locked={user?.locked} className='mr-2 font-size-md' />
        {!user?.locked ? <UserLockButton user={user} /> : <UserUnlockButton user={user} />}
      </SidebarInfoRow>
    </>
  );
};

export default UserLockComponent;
