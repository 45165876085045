import { Alert, Button, Modal } from 'antd';
import IntlMessage from '../../../../components/util-components/IntlMessage';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  addNewCityFromPlaceResponse,
  addNewCityPlaceResponded,
  dismissNewCityModal,
  openNewCityModal,
} from '../../../../redux/actions/views/components/AddCityModalViewActions';
import { useIntl } from 'react-intl';
import GooglePlacesAutocomplete from 'react-google-places-autocomplete';
import { GMAPS_API_KEY } from '../../../../configs/AppConfig';

export type AutocompletePlaceResponse = {
  label: string;
  value: {
    description: string;
    place_id: string;
  }
}

const AddCityModal = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const addCityModalViewModel = useSelector((state: RootState) => state.views.addCityModalView);
  const locale = useSelector((state: RootState) => state.theme.locale);

  return (
    <>
      <Button type='primary' ghost={true} onClick={() => dispatch(openNewCityModal())}>
        <IntlMessage id='button.new_city' />
      </Button>
      <Modal title={intl.formatMessage({ id: 'modal.title.new_city' })}
             visible={addCityModalViewModel.visible}
             onCancel={() => dispatch(dismissNewCityModal())}
             onOk={() => dispatch(addNewCityFromPlaceResponse(addCityModalViewModel.autocompletePlaceResponse as unknown as AutocompletePlaceResponse))}
             okButtonProps={{ disabled: null === addCityModalViewModel.autocompletePlaceResponse }}
             confirmLoading={addCityModalViewModel.submitting}>
        {addCityModalViewModel.errorMessage ? (
          <Alert message={addCityModalViewModel.errorMessage} type='error' className='mb-4' />
        ) : ('')}
        <GooglePlacesAutocomplete apiKey={GMAPS_API_KEY}
                                  apiOptions={{ language: locale }}
                                  autocompletionRequest={{
                                    componentRestrictions: {
                                      country: ['es'],
                                    },
                                    types: ['(cities)'],
                                  }}
                                  selectProps={{
                                    value: addCityModalViewModel.autocompletePlaceResponse,
                                    onChange: (response: AutocompletePlaceResponse) => {
                                      dispatch(addNewCityPlaceResponded(response));
                                    },
                                  }} />
      </Modal>
    </>
  );
};

export default AddCityModal;
