import { gql } from '@apollo/client';

export const GET_EXPORT_TASKS = gql`
  query GetExportTasks {
    exportTasks {
      id
      state
      progress {
        actual
        total
      }
      created
      updated
    }
  }
`;

export const GET_EXPORT_TASK = gql`
  query GetExportTasks($id: ID!) {
    exportTask(id: $id) {
      id
      state
      progress {
        actual
        total
      }
      created
      updated
    }
  }
`;

export const CREATE_EXPORT_TASK = gql`
  mutation CreateExportTask {
    createExportTask {
      id
      state
    }
  }
`;
