import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { History } from 'history';
import Auth from './Auth';
import Theme from './Theme';
import Form from './Form';
import Data from './Data';
import Views from './views';
import modalsReducer from './ModalsReducer';
import generalSettingsReducer from './views/settings/GeneralSettingsReducer';
import alertsReducer from './alertsReducer';
import { statsReducer } from './stats';
import { forgotPasswordReducer } from './auth/forgotPasswordReducer';
import { resetPasswordReducer } from './auth/resetPasswordReducer';

const createReducers = (history: History) => combineReducers({
  router: connectRouter(history),
  theme: Theme,
  auth: Auth,
  form: Form,
  data: Data,
  views: Views,
  modal: modalsReducer,
  generalSettings: generalSettingsReducer,
  alerts: alertsReducer,
  stats: statsReducer,
  forgotPassword: forgotPasswordReducer,
  resetPassword: resetPasswordReducer,
});

export default createReducers;
