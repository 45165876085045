import { PublishedCitiesAction } from '../../actions/stats/publishedCities';
import {
  PUBLISHED_CITIES_FETCH_COMPLETED,
  PUBLISHED_CITIES_FETCH_FAILED,
  PUBLISHED_CITIES_FETCH_START,
} from '../../constants/stats/publishedCities';
import { FetchState } from '../../constants/fetch';

export type PublishedCitiesStatsState = {
  status: FetchState,
  cities: number | null;
}

const initState: PublishedCitiesStatsState = {
  status: FetchState.IDLE,
  cities: null,
}

export const publishedCitiesStatsReducer = (state = initState, action: PublishedCitiesAction): PublishedCitiesStatsState => {
  switch (action.type) {
    case PUBLISHED_CITIES_FETCH_START:
      return {
        ...state,
        status: FetchState.LOADING,
      }
    case PUBLISHED_CITIES_FETCH_COMPLETED:
      return {
        ...state,
        status: FetchState.SUCCEEDED,
        cities: action.count,
      }
    case PUBLISHED_CITIES_FETCH_FAILED:
      return {
        ...state,
        status: FetchState.FAILED,
      }
    default:
      return {
        ...state,
      }
  }
}
