import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { ADD_CITY_SUBMIT_NEW_CITY } from '../../../constants/views/components/AddCityModalViewConstants';
import {
  addNewCityEndedWithErrors,
  addNewCityFromPlaceResponse,
  addNewCitySucceeded,
  dismissNewCityModal,
} from '../../../actions/views/components/AddCityModalViewActions';
import { CityService } from '../../../../services/CityService';
import { queryThroughNetwork } from '../../../actions';
import { ApolloError } from '@apollo/client';

function* submitNewCity() {
  yield takeLatest(ADD_CITY_SUBMIT_NEW_CITY, function* (payload: ReturnType<typeof addNewCityFromPlaceResponse>) {
    try {
      yield call(CityService.add, payload.autocompletePlaceResponse.value.place_id);
      yield put(addNewCitySucceeded());
      yield put(queryThroughNetwork());
      yield put(dismissNewCityModal());
    } catch (e) {
      const message = e instanceof ApolloError ? e.message : e;
      yield put(addNewCityEndedWithErrors(message));
    }
  });
}

export default function* addCityModalViewSagas() {
  yield all([
    fork(submitNewCity),
  ]);
}
