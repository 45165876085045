import { IssueGroupsAction } from '../../../../actions/views/normative/components/IssueGroupsActions';
import {
  ISSUE_GROUPS_COMPONENT_DISMISS_EDIT_ISSUE_GROUP_MODAL,
  ISSUE_GROUPS_COMPONENT_DISMISS_NEW_ISSUE_GROUP_MODAL,
  ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_ERRORED,
  ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_SUCCEEDED,
  ISSUE_GROUPS_COMPONENT_NEW_ISSUE_GROUP_SUCCEEDED,
  ISSUE_GROUPS_COMPONENT_OPEN_EDIT_ISSUE_GROUP_MODAL,
  ISSUE_GROUPS_COMPONENT_OPEN_NEW_ISSUE_GROUP_MODAL,
  ISSUE_GROUPS_COMPONENT_SUBMIT_EDIT_ISSUE_GROUP,
  ISSUE_GROUPS_COMPONENT_SUBMIT_ISSUE_GROUP_ERRORED,
  ISSUE_GROUPS_COMPONENT_SUBMIT_NEW_ISSUE_GROUP,
} from '../../../../constants/views/normative/components/IssueGroupsComponent';
import { IssueGroup } from '../../../../../models';

export type IssueGroupsComponentState = {
  submitting: boolean;
  resetForm: boolean;
  errorMessage: string | null;
  selectedIssueGroup: IssueGroup | null;
};

const initState: IssueGroupsComponentState = {
  submitting: false,
  resetForm: false,
  errorMessage: null,
  selectedIssueGroup: null,
};

const issueGroupsComponentReducer = (state = initState, action: IssueGroupsAction): IssueGroupsComponentState => {
  switch (action.type) {
    case ISSUE_GROUPS_COMPONENT_OPEN_NEW_ISSUE_GROUP_MODAL:
    case ISSUE_GROUPS_COMPONENT_DISMISS_NEW_ISSUE_GROUP_MODAL:
      return {
        ...state,
        resetForm: action.resetForm,
        errorMessage: null,
      }
    case ISSUE_GROUPS_COMPONENT_SUBMIT_NEW_ISSUE_GROUP:
    case ISSUE_GROUPS_COMPONENT_NEW_ISSUE_GROUP_SUCCEEDED:
      return {
        ...state,
        submitting: action.submitting,
      }
    case ISSUE_GROUPS_COMPONENT_SUBMIT_ISSUE_GROUP_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
        errorMessage: action.errorMessage,
      }
    case ISSUE_GROUPS_COMPONENT_OPEN_EDIT_ISSUE_GROUP_MODAL:
    case ISSUE_GROUPS_COMPONENT_DISMISS_EDIT_ISSUE_GROUP_MODAL:
      return {
        ...state,
        resetForm: action.resetForm,
        selectedIssueGroup: action.issueGroup,
      }
    case ISSUE_GROUPS_COMPONENT_SUBMIT_EDIT_ISSUE_GROUP:
    case ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_SUCCEEDED:
      return {
        ...state,
        submitting: action.submitting,
      }
    case ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
        errorMessage: action.errorMessage,
      }
    default:
      return {
        ...state,
      }
  }
}

export default issueGroupsComponentReducer;
