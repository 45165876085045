import { BaseModel, Image, IssueGroup, IssueType, User, ResolvedIssue } from './index';
import { City, PlaceCoordinates, Route } from './places';

export enum IssueStatus {
  NEW = 'new',
  TO_VALIDATE = 'to validate',
  VALIDATED = 'validated',
  DISCARDED = 'discarded',
  RESOLVED = 'resolved',
  PENDING = 'pending',
  ACTIVE = 'validated'
}

export const USER_ISSUES_STATUSES = [
  IssueStatus.NEW,
  IssueStatus.TO_VALIDATE,
  IssueStatus.VALIDATED,
  IssueStatus.DISCARDED,
  IssueStatus.RESOLVED
];

export const MAPPING_ISSUES_STATUSES = [
  IssueStatus.ACTIVE,
  IssueStatus.DISCARDED,
  IssueStatus.RESOLVED
];

export enum IssueAction {
  NO_ACTION = 'no_action',
  CHECK = 'check',
  VALIDATE = 'validate',
  DISCARD = 'discard',
  RESOLVE = 'resolve',
}

export type IssueTransition = {
  action: IssueAction,
  nextStatus: IssueStatus,
};

const DISCARD = { action: IssueAction.DISCARD, nextStatus: IssueStatus.DISCARDED };
const CHECK = { action: IssueAction.CHECK, nextStatus: IssueStatus.TO_VALIDATE };
// const VALIDATE = { action: IssueAction.VALIDATE, nextStatus: IssueStatus.VALIDATED };
const RESOLVE = { action: IssueAction.RESOLVE, nextStatus: IssueStatus.RESOLVED };

export const issueStatusGraph = new Map<IssueStatus, IssueTransition[]>([
  [IssueStatus.NEW, [CHECK, DISCARD]],
  [IssueStatus.TO_VALIDATE, [DISCARD]],
  [IssueStatus.VALIDATED, [RESOLVE, DISCARD]],
]);

export enum IssueLocation {
  POINT = 'point',
  SUBSECTION = 'subsection',
}

export type Issue = BaseModel & {
  start: number;
  end: number;
  description: string;
  status: IssueStatus;
  locationType: IssueLocation;
  route: Route;
  city: City;
  issueGroup: IssueGroup;
  issueTypes: IssueType[];
  user: User;
  images: Image[];
  mark: PlaceCoordinates,
  resolvedIssueTypes?: ResolvedIssue[]
}

export type IssueOutOfZone = BaseModel & {
  route: Route,
  number: number;
}

export type IssueStats = {
  mapping: number;
  new: number;
  toValidate: number;
  validated: number;
}
