export const SIGNIN = 'SIGNIN';
export const AUTHENTICATED = 'AUTHENTICATED';
export const SIGNOUT = 'SIGNOUT';
export const SIGNOUT_SUCCESS = 'SIGNOUT_SUCCESS'
export const SIGNUP = 'SIGNUP';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS'
export const SHOW_AUTH_MESSAGE = 'SHOW_AUTH_MESSAGE'
export const HIDE_AUTH_MESSAGE = 'HIDE_AUTH_MESSAGE'
export const SHOW_LOADING = 'SHOW_LOADING'
export const AUTH_TOKEN = 'auth_token'
export const CHECK_WHO_AM_I = 'CHECK_WHO_AM_I'
export const APP_USER_FETCHED = 'APP_USER_FETCHED'
export const APP_USER_NOT_LOGGED_IN = 'APP_USER_NOT_LOGGED_IN';

export const UNAUTHORIZED = 'auth/errors/UNAUTHORIZED';

export const AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL = 'auth/forgot_password/SUBMIT_EMAIL';
export const AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_SUCCEEDED = 'auth/forgot_password/SUBMIT_EMAIL_SUCCEEDED';
export const AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_FAILED = 'auth/forgot_password/SUBMIT_EMAIL_FAILED';
export const AUTH_FORGOT_PASSWORD_RESET_STATE = 'auth/forgot_password/RESET_STATE';

export const AUTH_RESET_PASSWORD_CHECK_TOKEN = 'auth/reset_password/CHECK_TOKEN';
export const AUTH_RESET_PASSWORD_CHECK_TOKEN_RESULT = 'auth/reset_password/CHECK_TOKEN_RESULT';
export const AUTH_RESET_PASSWORD_CHECK_TOKEN_ERROR = 'auth/reset_password/error/CHECK_TOKEN_ERROR';
export const AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD = 'auth/reset_password/SUBMIT_NEW_PASSWORD';
export const AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCEEDED = 'auth/reset_password/success/SUBMIT_NEW_PASSWORD';
export const AUTH_RESET_PASSWORD_SUBMIT_NEW_PASSWORD_FAILED = 'auth/reset_password/error/SUBMIT_NEW_PASSWORD';
