import { User } from '../../../../../models';
import { SendNotificationToUserAction } from '../../../../actions/views/users/components/SendNotificationToUserAction';
import {
  SEND_NOTIFICATION_TO_USER_CANCEL,
  SEND_NOTIFICATION_TO_USER_DISMISS_MODAL,
  SEND_NOTIFICATION_TO_USER_OPEN_MODAL,
  SEND_NOTIFICATION_TO_USER_START,
  SEND_NOTIFICATION_TO_USER_SUBMIT,
  SEND_NOTIFICATION_TO_USER_SUBMIT_FAILED,
  SEND_NOTIFICATION_TO_USER_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/users/components/SendNotificationToUserConstants';

export type SendNotificationToUserState = {
  user: null | User;
  submitting: boolean;
  open: boolean;
}

const initState: SendNotificationToUserState = {
  user: null,
  submitting: false,
  open: false,
};

export default function sendNotificationToUserReducer(state = initState, action: SendNotificationToUserAction): SendNotificationToUserState {
  switch (action.type) {
    case SEND_NOTIFICATION_TO_USER_START:
      return {
        ...state,
        user: action.user,
      }
    case SEND_NOTIFICATION_TO_USER_OPEN_MODAL:
      return {
        ...state,
        open: true,
      }
    case SEND_NOTIFICATION_TO_USER_DISMISS_MODAL:
      return {
        ...state,
        open: false,
      }
    case SEND_NOTIFICATION_TO_USER_CANCEL:
      return {
        ...state,
        user: null,
      }
    case SEND_NOTIFICATION_TO_USER_SUBMIT:
      return {
        ...state,
        submitting: true,
      }
    case SEND_NOTIFICATION_TO_USER_SUBMIT_SUCCEEDED:
    case SEND_NOTIFICATION_TO_USER_SUBMIT_FAILED:
      return {
        ...state,
        submitting: false,
      }
    default:
      return {
        ...state,
      };
  }
}
