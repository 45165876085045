import {
  ADMIN_USERS_ADD_ADMIN_USER_CANCEL,
  ADMIN_USERS_ADD_ADMIN_USER_DISMISS_MODAL,
  ADMIN_USERS_ADD_ADMIN_USER_OPEN_MODAL,
  ADMIN_USERS_ADD_ADMIN_USER_START,
  ADMIN_USERS_ADD_ADMIN_USER_SUBMIT,
  ADMIN_USERS_ADD_ADMIN_USER_SUBMIT_ERRORED,
  ADMIN_USERS_ADD_ADMIN_USER_SUBMIT_SUCCEEDED,
} from '../../../constants/views/settings/AdminUsersListConstants';
import { ADD_ADMIN_USER_MODAL } from '../../../../views/app-views/settings/components/AddAdminUserButton';
import { AdminUserInput } from '../../../../services/AdminUserService';

export type AddAdminUserAction = ReturnType<typeof startAddAdminUser
  | typeof cancelAddAdminUser
  | typeof openAddAdminUserModal
  | typeof dismissAddAdminUserModal
  | typeof submitNewAdminUser
  | typeof newAdminUserSubmissionSucceeded
  | typeof newAdminUserSubmissionErrored>

export function startAddAdminUser() {
  return <const>{
    type: ADMIN_USERS_ADD_ADMIN_USER_START,
  };
}

export function cancelAddAdminUser() {
  return <const>{
    type: ADMIN_USERS_ADD_ADMIN_USER_CANCEL,
  };
}

export function openAddAdminUserModal() {
  return <const>{
    type: ADMIN_USERS_ADD_ADMIN_USER_OPEN_MODAL,
    visibleModal: ADD_ADMIN_USER_MODAL,
  };
}

export function dismissAddAdminUserModal() {
  return <const>{
    type: ADMIN_USERS_ADD_ADMIN_USER_DISMISS_MODAL,
    visibleModal: null,
  };
}

export function submitNewAdminUser(input: AdminUserInput) {
  return <const>{
    type: ADMIN_USERS_ADD_ADMIN_USER_SUBMIT,
    submitting: true,
    input,
  };
}

export function newAdminUserSubmissionSucceeded() {
  return <const>{
    type: ADMIN_USERS_ADD_ADMIN_USER_SUBMIT_SUCCEEDED,
    submitting: false,
  };
}

export function newAdminUserSubmissionErrored(errorMessage?: string) {
  return <const>{
    type: ADMIN_USERS_ADD_ADMIN_USER_SUBMIT_ERRORED,
    errorMessage,
    submitting: false,
  };
}
