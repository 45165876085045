import { apolloClient } from '../clients/ApolloClient';
import { AppAlert, UnreadAppAlerts } from '../models/admin';
import {
  DELETE_MANY_ALERTS,
  GET_APP_ALERTS_READ,
  GET_APP_ALERTS_UNREAD,
  GET_UNREAD_APP_ALERTS_COUNT,
  MARK_ALERT_AS_READ, MARK_MANY_ALERTS_AS_READ, MARK_MANY_ALERTS_AS_UNREAD,
} from '../graphql/appAlerts';

export class AppAlertsService {
  static unread(): Promise<UnreadAppAlerts> {
    return apolloClient.query<{ appAlertsUnreadCount: UnreadAppAlerts }>({
      query: GET_UNREAD_APP_ALERTS_COUNT,
    })
      .then(({ data }) => data?.appAlertsUnreadCount ?? { unread: 0 });
  }

  static markAsRead(id: string): Promise<AppAlert | undefined> {
    return apolloClient.mutate<{ markAlertAsRead: AppAlert }>({
      mutation: MARK_ALERT_AS_READ,
      variables: { id },
      refetchQueries: [
        { query: GET_UNREAD_APP_ALERTS_COUNT },
        { query: GET_APP_ALERTS_UNREAD },
        { query: GET_APP_ALERTS_READ },
      ],
    }).then(({ data }) => data?.markAlertAsRead);
  }

  static markManyAsRead(ids: string[]): Promise<AppAlert[] | undefined> {
    return apolloClient.mutate<{ markManyAlertsAsRead: AppAlert[] }>({
      mutation: MARK_MANY_ALERTS_AS_READ,
      variables: { ids },
      refetchQueries: [
        { query: GET_UNREAD_APP_ALERTS_COUNT },
        { query: GET_APP_ALERTS_UNREAD },
        { query: GET_APP_ALERTS_READ },
      ],
    }).then(({ data }) => data?.markManyAlertsAsRead);
  }

  static markManyAsUnread(ids: string[]): Promise<AppAlert[] | undefined> {
    return apolloClient.mutate<{ markManyAlertsAsUnread: AppAlert[] }>({
      mutation: MARK_MANY_ALERTS_AS_UNREAD,
      variables: { ids },
      refetchQueries: [
        { query: GET_UNREAD_APP_ALERTS_COUNT },
        { query: GET_APP_ALERTS_UNREAD },
        { query: GET_APP_ALERTS_READ },
      ],
    }).then(({ data }) => data?.markManyAlertsAsUnread);
  }

  static deleteMany(ids: string[]): Promise<number | undefined> {
    return apolloClient.mutate<{ deleted: number }>({
      mutation: DELETE_MANY_ALERTS,
      variables: { ids },
      refetchQueries: [
        { query: GET_UNREAD_APP_ALERTS_COUNT },
        { query: GET_APP_ALERTS_UNREAD },
        { query: GET_APP_ALERTS_READ },
      ],
    }).then(({ data }) => data?.deleted);
  }
}
