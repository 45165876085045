import { all } from 'redux-saga/effects';
import Auth from './Auth';
import Notifications from './Notifications';
import Views from './views';
import modalSaga from './ModalSagas';
import alertsSagas from './alertsSagas';
import statsSagas from './stats';

export default function* rootSaga() {
  yield all([
    Auth(),
    Notifications(),
    Views(),
    modalSaga(),
    alertsSagas(),
    statsSagas(),
  ]);
}
