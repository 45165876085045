import { BaseModel, Normative } from './index';

export enum CityStatus {
  NEW = 'new',
  DRAFT = 'draft',
  ACTIVE = 'active',
  PUBLISHED = 'published',
}

export type City = BaseModel & {
  normative?: Normative;
  name: string;
  data: PlaceDetail;
  status: CityStatus;
  newIssues: number;
  issuesToValidate: number;
  outOfZoneIssuesCount: number;
}

export type Route = BaseModel & {
  name: string;
  data: PlaceDetail;
}

export type PlaceId = string;

export type PlaceDetailResponse = {
  html_attributions: [];
  status: string;
  result: PlaceDetail;
};

export type PlacePhoto = {
  height: number;
  width: number;
  html_attributions: string[];
  photo_reference: string;
};

export type PlaceCoordinates = {
  lat: number;
  lng: number;
};

export type PlaceGeometry = {
  location: PlaceCoordinates;
  viewport: {
    northeast: PlaceCoordinates;
    southwest: PlaceCoordinates;
  };
};

export type PlaceType =
  | 'locality'
  | 'political'
  | 'administrative_area_level_2'
  | 'administrative_area_level_1'
  | 'country'
  | 'postal_code'
  | 'route';

export type PlaceAddressComponent = {
  long_name: string;
  short_name: string;
  types: PlaceType[];
};

export type PlaceDetail = {
  address_components: PlaceAddressComponent[];
  adr_address: string;
  formatted_address: string;
  geometry: PlaceGeometry;
  icon: string;
  name: string;
  photos: PlacePhoto[];
  place_id: PlaceId;
  reference: string;
  types: PlaceType[];
  url: string;
  utc_offset: number;
  vicinity: string;
};

export type CityGeneralData = {
  id: string;
  normative?: string;
  status?: string;
  name?: string;
}

export enum CityStatusAction {
  NO_ACTION = 'no_action',
  TO_DRAFT = 'to_draft',
  ACTIVATE = 'activate',
  PUBLISH = 'publish',
  TO_NEW = 'to_new',
}


export type CityStatusTransition = {
  action: CityStatusAction,
  nextStatus: CityStatus,
}

const CITY_TO_DRAFT: CityStatusTransition = <const>{ action: CityStatusAction.TO_DRAFT, nextStatus: CityStatus.DRAFT };
const ACTIVATE_CITY: CityStatusTransition = <const>{ action: CityStatusAction.ACTIVATE, nextStatus: CityStatus.ACTIVE };
const PUBLISH_CITY: CityStatusTransition = <const>{ action: CityStatusAction.PUBLISH, nextStatus: CityStatus.PUBLISHED };
const CITY_TO_NEW: CityStatusTransition = <const>{ action: CityStatusAction.TO_NEW, nextStatus: CityStatus.NEW };

export const cityStatusGraph = new Map<CityStatus, CityStatusTransition[]>([
  [CityStatus.NEW, [CITY_TO_DRAFT]],
  [CityStatus.DRAFT, [CITY_TO_NEW, ACTIVATE_CITY, PUBLISH_CITY]],
  [CityStatus.ACTIVE, [CITY_TO_NEW, CITY_TO_DRAFT, PUBLISH_CITY]],
  [CityStatus.PUBLISHED, [CITY_TO_NEW, CITY_TO_DRAFT, ACTIVATE_CITY]],
])
