import { ProfileSettingsActions } from '../../../actions/views/profile/ProfileSettingsActions';
import {
  CHANGE_PASSWORD_FORM_RESET_DONE,
  CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_ERRORED,
  CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_INFO,
  CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCEEDED,
  EDIT_PROFILE_SUBMIT_NEW_PROFILE_ERRORED,
  EDIT_PROFILE_SUBMIT_NEW_PROFILE_INFO,
  EDIT_PROFILE_SUBMIT_NEW_PROFILE_SUCCEEDED,
} from '../../../constants/views/profile/ProfileSettingsConstants';

export type ProfileSettingsState = {
  submittingProfile: boolean;
  submittingPassword: boolean;
  resetForm: boolean;
}

const initState: ProfileSettingsState = {
  submittingProfile: false,
  submittingPassword: false,
  resetForm: false,
}

const profileSettingsReducer = (state = initState, action: ProfileSettingsActions): ProfileSettingsState => {
  switch(action.type) {
    case EDIT_PROFILE_SUBMIT_NEW_PROFILE_INFO:
    case EDIT_PROFILE_SUBMIT_NEW_PROFILE_SUCCEEDED:
    case EDIT_PROFILE_SUBMIT_NEW_PROFILE_ERRORED:
      return {
        ...state,
        submittingProfile: action.submitting,
      }
    case CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_INFO:
    case CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_ERRORED:
      return {
        ...state,
        submittingPassword: action.submitting,
      }
    case CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCEEDED:
      return {
        ...state,
        submittingPassword: action.submitting,
        resetForm: action.resetForm,
      }
    case CHANGE_PASSWORD_FORM_RESET_DONE:
      return {
        ...state,
        resetForm: action.resetForm,
      }
    default:
      return {
        ...state,
      }
  }
}

export default profileSettingsReducer;
