import {
  AUTH_FORGOT_PASSWORD_RESET_STATE,
  AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL,
  AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_FAILED,
  AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_SUCCEEDED,
} from '../../constants/Auth';
import { ForgotPasswordAction } from '../../actions/auth/ForgotPasswordAction';

type ForgotPasswordState = {
  submitting: boolean;
  showSuccessMessage: boolean;
  showErrorMessage: boolean;
  email: string | null;
}

const initState: ForgotPasswordState = {
  submitting: false,
  email: null,
  showSuccessMessage: false,
  showErrorMessage: false,
}

export const forgotPasswordReducer = (state = initState, action: ForgotPasswordAction): ForgotPasswordState => {
  switch (action.type) {
    case AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL:
      return {
        ...state,
        email: action.email,
        submitting: true,
        showSuccessMessage: false,
        showErrorMessage: false,
      }

    case AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_SUCCEEDED:
      return {
        ...state,
        submitting: false,
        showSuccessMessage: true,
      }
    case AUTH_FORGOT_PASSWORD_SUBMIT_EMAIL_FAILED:
      return {
        ...state,
        submitting: false,
        showErrorMessage: true,
      }
    case AUTH_FORGOT_PASSWORD_RESET_STATE:
      return {
        ...initState,
      }
    default:
      return {
        ...state,
      }
  }
}
