import { all, call, put, takeEvery, delay } from 'redux-saga/effects';
import { APP_USER_FETCHED, AUTHENTICATED } from '../constants/Auth';
import { AppAlertsService } from '../../services/AppAlertsService';
import {
  deleteAlert,
  deletedManyAlertsSuccessfully,
  deleteManyAlerts,
  markAlertAsReadMarked,
  markAlertAsReadSubmit,
  markAlertAsUnread,
  markAsReadMultipleAlerts,
  markAsUnreadMultipleAlerts,
  markedAsReadManyAlertsSuccessfully,
  markedAsUnreadManyAlertsSuccessfully,
  updateUnreadAlerts,
} from '../actions/AlertsAction';
import {
  ALERTS_DELETE,
  ALERTS_DELETE_MANY_SUBMIT,
  ALERTS_DELETE_MANY_SUCCESSFULLY,
  ALERTS_MARK_AS_READ_MANY_MARKED_SUCCESSFULLY,
  ALERTS_MARK_AS_READ_MANY_SUBMIT,
  ALERTS_MARK_AS_READ_MARKED,
  ALERTS_MARK_AS_READ_SUBMIT,
  ALERTS_MARK_AS_UNREAD,
  ALERTS_MARK_AS_UNREAD_MANY_MARKED_SUCCESSFULLY,
  ALERTS_MARK_AS_UNREAD_MANY_SUBMIT,
} from '../constants/Alerts';
import { goBack, push } from 'connected-react-router';
import { appRoutes } from '../../configs/AppRoutes';

function* fetchUnreadAlerts() {
  try {
    yield delay(500);
    const { unread } = yield call(AppAlertsService.unread);
    yield put(updateUnreadAlerts(unread));
  } catch (e) {
  }
}

function* markAlertAsReadSaga({ alertId }: ReturnType<typeof markAlertAsReadSubmit>) {
  try {
    yield call(AppAlertsService.markAsRead, alertId);
    yield put(markAlertAsReadMarked());
  } catch (e) {
  }
}

function* markMultipleAlertsAsReadSaga({ alertIds }: ReturnType<typeof markAsReadMultipleAlerts>) {
  try {
    yield call(AppAlertsService.markManyAsRead, alertIds);
    yield put(markedAsReadManyAlertsSuccessfully());
  } catch (e) {

  }
}

function* markMultipleAlertsAsUnreadSaga({ alertIds }: ReturnType<typeof markAsReadMultipleAlerts>) {
  try {
    yield call(AppAlertsService.markManyAsUnread, alertIds);
    yield put(markedAsUnreadManyAlertsSuccessfully());
  } catch (e) {

  }
}

function* deleteManyAlertsSaga({ alertIds }: ReturnType<typeof deleteManyAlerts>) {
  try {
    yield call(AppAlertsService.deleteMany, alertIds);
    yield put(deletedManyAlertsSuccessfully());
  } catch (e) {
  }
}

function* markAlertAsUnreadSaga({ alertId }: ReturnType<typeof markAlertAsUnread>) {
  try {
    yield put(markAsUnreadMultipleAlerts([alertId]));
    yield put(push(appRoutes.path('alerts')));
  } catch (e) {
  }
}

function* deleteAlertSaga({ alertId }: ReturnType<typeof deleteAlert>) {
  try {
    yield put(deleteManyAlerts([alertId]));
    yield put(goBack());
  } catch (e) {
  }
}

export default function* alertsSagas() {
  yield all([
    takeEvery(APP_USER_FETCHED, fetchUnreadAlerts),
    takeEvery(AUTHENTICATED, fetchUnreadAlerts),
    takeEvery(ALERTS_MARK_AS_READ_MARKED, fetchUnreadAlerts),
    takeEvery(ALERTS_MARK_AS_READ_MANY_MARKED_SUCCESSFULLY, fetchUnreadAlerts),
    takeEvery(ALERTS_MARK_AS_UNREAD_MANY_MARKED_SUCCESSFULLY, fetchUnreadAlerts),
    takeEvery(ALERTS_DELETE_MANY_SUCCESSFULLY, fetchUnreadAlerts),
    takeEvery(ALERTS_MARK_AS_READ_SUBMIT, markAlertAsReadSaga),
    takeEvery(ALERTS_MARK_AS_READ_MANY_SUBMIT, markMultipleAlertsAsReadSaga),
    takeEvery(ALERTS_MARK_AS_UNREAD_MANY_SUBMIT, markMultipleAlertsAsUnreadSaga),
    takeEvery(ALERTS_DELETE_MANY_SUBMIT, deleteManyAlertsSaga),
    takeEvery(ALERTS_MARK_AS_UNREAD, markAlertAsUnreadSaga),
    takeEvery(ALERTS_DELETE, deleteAlertSaga),
  ]);
}
