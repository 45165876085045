import { Alert, Button, Form, Input, Modal } from 'antd';
import IntlMessage from '../../../../components/util-components/IntlMessage';
import { intlFormatMessage } from '../../../../utils/intl';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelAddAdminUser,
  startAddAdminUser,
  submitNewAdminUser,
} from '../../../../redux/actions/views/settings/AdminUsersListAction';
import { RootState } from '../../../../redux/store';
import { getRequiredRule } from '../../../../utils/forms';
import { useForm } from 'antd/es/form/Form';
import { useEffect } from 'react';
import { AdminUserInput } from '../../../../services/AdminUserService';

const layout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 },
};

export const ADD_ADMIN_USER_MODAL = 'modal.addAdminUser';

const AddAdminUserButton = () => {
  const dispatch = useDispatch();
  const { visibleModal } = useSelector((state: RootState) => state.modal);
  const { submitting } = useSelector((state: RootState) => state.views.adminUsersList.add);
  const [form] = useForm();

  useEffect(() => {
    if (null !== visibleModal) {
      form.resetFields();
    }
  }, [form, visibleModal]);

  const onFinish = (values: AdminUserInput) => dispatch(submitNewAdminUser(values));

  return (
    <>
      <Button type='primary' onClick={() => dispatch(startAddAdminUser())}>
        <IntlMessage id='button.add_admin_user' />
      </Button>
      <Modal title={intlFormatMessage('modal.title.add_admin_user')}
             visible={ADD_ADMIN_USER_MODAL === visibleModal}
             okButtonProps={{ loading: submitting }}
             onOk={() => form.submit()}
             onCancel={() => dispatch(cancelAddAdminUser())}>
        <Form {...layout} form={form} onFinish={onFinish}>
          <Alert className='mb-4' type='info' message={intlFormatMessage('alert.info.add_admin_user')} showIcon />
          <Form.Item name='name' label={intlFormatMessage('form.label.name')}
                     rules={[getRequiredRule()]}>
            <Input />
          </Form.Item>
          <Form.Item name='email' label={intlFormatMessage('form.label.email')}
                     rules={[getRequiredRule(), { type: 'email' }]}>
            <Input type='email' />
          </Form.Item>
          <Form.Item name='password' label={intlFormatMessage('form.label.password')}
                     rules={[getRequiredRule()]}>
            <Input.Password />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default AddAdminUserButton;
