import { all, call, fork, put, takeLatest } from 'redux-saga/effects';
import { IssueGroupService } from '../../../../../services/IssueGroupService';
import {
  dismissEditIssueGroupModal,
  dismissNewIssueGroupModal, editIssueGroupErrored, editIssueGroupSucceeded, newIssueGroupErrored,
  newIssueGroupSucceeded, submitEditedIssueGroup,
  submitNewIssueGroup,
} from '../../../../actions/views/normative/components/IssueGroupsActions';
import {
  ISSUE_GROUPS_COMPONENT_SUBMIT_EDIT_ISSUE_GROUP,
  ISSUE_GROUPS_COMPONENT_SUBMIT_NEW_ISSUE_GROUP,
} from '../../../../constants/views/normative/components/IssueGroupsComponent';
import { queryThroughNetwork } from '../../../../actions';

export default function* issueGroupsSagas() {
  yield all([
    fork(function* () {
      yield takeLatest(
        ISSUE_GROUPS_COMPONENT_SUBMIT_NEW_ISSUE_GROUP,
        function* ({ input }: ReturnType<typeof submitNewIssueGroup>) {
          try {
            yield call(IssueGroupService.createIssueGroup, input);
            yield put(queryThroughNetwork());
            yield put(newIssueGroupSucceeded());
            yield put(dismissNewIssueGroupModal());
          } catch (e) {
            yield put(newIssueGroupErrored(e.message));
          }
        },
      );
    }),
    fork(function* () {
      yield takeLatest(
        ISSUE_GROUPS_COMPONENT_SUBMIT_EDIT_ISSUE_GROUP,
        function* ({ id, input }: ReturnType<typeof submitEditedIssueGroup>) {
          try {
            yield call(IssueGroupService.edit, id, input);
            yield put(queryThroughNetwork());
            yield put(editIssueGroupSucceeded());
            yield put(dismissEditIssueGroupModal());
          } catch (e) {
            yield put(editIssueGroupErrored(e.message));
          }
        }
      )
    }),
  ]);
}
