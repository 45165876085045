import { gql } from '@apollo/client';
import { AdminUser } from '../models/admin';
import { apolloClient } from '../clients/ApolloClient';
import { GET_ADMIN_USERS } from '../graphql/adminUsers';

export type AdminUserInput = {
  name: string;
  email: string;
  password: string;
}

export type EditAdminUserProfileInput = Pick<AdminUserInput, 'name'>

export type ChangePasswordInput = {
  currentPassword?: string;

  newPassword: string;
}

export type ChangePasswordResponse = {
  user: AdminUser,
  accessToken: string,
  refreshToken: string,
}

const ADD_ADMIN_USER = gql`
  mutation AddAdminUser($input: AdminUserInput!) {
    addAdminUser(input: $input) {
      id
      name
      email
    }
  }
`;

const EDIT_OWN_PROFILE = gql`
  mutation EditOwnProfile($input: AdminUserProfileInput!) {
    editOwnProfile(input: $input) {
      id 
      name
    }
  }
`;

const EDIT_ADMIN_USER_PROFILE = gql`
  mutation EditAdminUserProfile($id: ID!, $input: AdminUserProfileInput!) {
    editAdminUserProfile(id: $id, input: $input) {
      id 
      name
    }
  }
`;

const CHANGE_PASSWORD = gql`
  mutation ChangePassword($input: ChangePasswordInput!) {
    changePassword(input: $input) {
      id 
      name
    }
  }
`;

const CHANGE_ADMIN_USER_PASSWORD = gql`
  mutation ChangeAdminUserPassword($id: ID!, $input: ChangeAdminUserPasswordInput!) {
    changeAdminUserPassword(id: $id, input: $input) {
      id 
      name
    }
  }
`;

const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!) {
    resetPassword(email: $email)
  }
`;

const CHECK_RESET_PASSWORD_TOKEN = gql`
  query CheckResetPasswordToken($token: String!) {
    checkResetPasswordToken(token: $token)
  }
`;

const NEW_PASSWORD_WITH_TOKEN = gql`
  mutation NewPasswordWithToken($input: ChangePasswordWithTokenInput!) {
    setNewPasswordWithToken(input: $input) {
      user {
        id
        name
      }
      accessToken
      refreshToken
    }
  }
`;

const DELETE_ADMIN_USER = gql`
  mutation DeleteAdminUser($id: ID!) {
    deleteAdminUser(id: $id)
  }
`;

export class AdminUserService {
  static addAdminUser(input: AdminUserInput): Promise<AdminUser | undefined> {
    return apolloClient.mutate<{ addAdminUser: AdminUser }>({
      mutation: ADD_ADMIN_USER,
      variables: { input },
      refetchQueries: [{ query: GET_ADMIN_USERS }],
    }).then(({ data }) => data?.addAdminUser);
  }

  static editOwnProfile(input: EditAdminUserProfileInput): Promise<AdminUser | undefined> {
    return apolloClient.mutate<{ editOwnProfile: AdminUser }>({
      mutation: EDIT_OWN_PROFILE,
      variables: { input },
    }).then(({ data }) => data?.editOwnProfile);
  }

  static editProfile(id: string, input: EditAdminUserProfileInput): Promise<AdminUser | undefined> {
    return apolloClient.mutate<{ editOwnProfile: AdminUser }>({
      mutation: EDIT_ADMIN_USER_PROFILE,
      variables: { id, input },
    }).then(({ data }) => data?.editOwnProfile);
  }

  static changePassword(input: ChangePasswordInput): Promise<AdminUser | undefined> {
    return apolloClient.mutate<{ changePassword: AdminUser }>({
      mutation: CHANGE_PASSWORD,
      variables: { input },
    }).then(({ data }) => data?.changePassword);
  }

  static changeAdminUserPassword(id: string,  input: ChangePasswordInput): Promise<AdminUser | undefined> {
    return apolloClient.mutate<{ changeAdminUserPassword: AdminUser }>({
      mutation: CHANGE_ADMIN_USER_PASSWORD,
      variables: { id, input },
    }).then(({ data }) => data?.changeAdminUserPassword);
  }

  static resetPassword(email: string): Promise<null> {
    return apolloClient.mutate<{ resetPassword: boolean }>({
      mutation: RESET_PASSWORD,
      variables: { email },
    }).then();
  }

  static checkResetPasswordToken(token: string): Promise<boolean> {
    return apolloClient.query<{ checkResetPasswordToken: boolean }>({
      query: CHECK_RESET_PASSWORD_TOKEN,
      variables: { token },
    }).then(({ data: { checkResetPasswordToken } }) => checkResetPasswordToken);
  }

  static setNewPasswordWithToken(token: string, newPassword: string): Promise<ChangePasswordResponse | undefined> {
    return apolloClient.mutate<{ setNewPasswordWithToken: ChangePasswordResponse }>({
      mutation: NEW_PASSWORD_WITH_TOKEN,
      variables: { input: { token, newPassword } },
    }).then(({ data }) => data?.setNewPasswordWithToken);
  }

  static deleteAdminUser(id: string): Promise<boolean> {
    return apolloClient.mutate<{ deleteAdminUser: boolean }>({
      mutation: DELETE_ADMIN_USER,
      variables: { id },
      refetchQueries: [
        { query: GET_ADMIN_USERS }
      ]
    }).then(({ data, errors }) => {
      if (errors) {
        throw new Error(errors[0].message);
      }

      return data?.deleteAdminUser || false;
    })
  }
}
