import {
  REMOVABLE_AVATAR_DELETE, REMOVABLE_AVATAR_DELETE_FAILED,
  REMOVABLE_AVATAR_DELETE_SUCCEEDED,
} from '../../../../constants/views/users/components/RemovableAvatarConstants';

export type RemovableAvatarAction = ReturnType<typeof deleteUserAvatar
  | typeof deleteUserAvatarSucceeded
  | typeof deleteUserAvatarFailed>

export function deleteUserAvatar(userId: string) {
  return <const>{
    type: REMOVABLE_AVATAR_DELETE,
    userId,
  }
}

export function deleteUserAvatarSucceeded() {
  return <const>{
    type: REMOVABLE_AVATAR_DELETE_SUCCEEDED,
  }
}

export function deleteUserAvatarFailed() {
  return <const>{
    type: REMOVABLE_AVATAR_DELETE_FAILED,
  }
}
