import { all, fork } from 'redux-saga/effects';
import cityNormativeInfoSagas from './components/CityNormativeInfoSagas';
import cityStatusInfoSagas from './components/CityStatusInfoSagas';
import cityNameSagas from './components/CityNameSagas';

export default function* cityDetailViewSagas() {
  yield all([
    fork(cityNormativeInfoSagas),
    fork(cityStatusInfoSagas),
    fork(cityNameSagas),
  ]);
}
