import { queryThroughNetwork } from '../actions';
import { QUERY_DEFAULT_POLICY, QUERY_THROUGH_NETWORK } from '../constants/Data';

export const FETCH_POLICY_CACHE_FIRST = 'cache-first';
export const FETCH_POLICY_NETWORK_ONLY = 'network-only';

type FetchPolicyType = 'cache-first' | 'network-only';

export type DataState = {
  fetchPolicy: FetchPolicyType;
}

const initState: DataState = {
  fetchPolicy: FETCH_POLICY_CACHE_FIRST,
};

type Action = ReturnType<typeof queryThroughNetwork>;

const data = (state = initState, action: Action): DataState => {
  switch (action.type) {
    case QUERY_THROUGH_NETWORK:
      return {
        ...state,
        fetchPolicy: FETCH_POLICY_NETWORK_ONLY,
      };
    case QUERY_DEFAULT_POLICY:
      return {
        ...initState,
      };
    default:
      return state;
  }
};

export default data;
