import { Button, Form, Input, Modal } from 'antd';
import IntlMessage from '../../../../components/util-components/IntlMessage';
import { NotificationOutlined } from '@ant-design/icons';
import { User } from '../../../../models';
import { useDispatch, useSelector } from 'react-redux';
import {
  cancelSendNotificationToUser,
  startSendNotificationToUser, submitNewNotificationToUser,
} from '../../../../redux/actions/views/users/components/SendNotificationToUserAction';
import { RootState } from '../../../../redux/store';
import { intlFormatMessage } from '../../../../utils/intl';
import { getRequiredRule } from '../../../../utils/forms';
import TextArea from 'antd/es/input/TextArea';
import { useForm } from 'antd/es/form/Form';
import { UserNotificationInput } from '../../../../services/NotificationService';
import { useEffect } from 'react';

export const SEND_NOTIFICATION_TO_USER_MODAL_NAME = 'modal.sendNotificationToUser';

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
};

const SendNotificationToUserModal = () => {
  const dispatch = useDispatch();
  const [form] = useForm();
  const { open, user, submitting } = useSelector((state: RootState) => state.views.users.detail.sendNotification);
  const { visibleModal } = useSelector((state: RootState) => state.modal);

  useEffect(() => {
    if (open) {
      form.resetFields();
    }
  }, [open, form]);

  const onFinish = (values: Pick<UserNotificationInput, 'title' | 'message'>) => {
    if (!user) {
      return;
    }

    dispatch(submitNewNotificationToUser({
      user: user.id,
      ...values,
    }));
  };

  return (
    <Modal title={<><NotificationOutlined className='mr-2' /><IntlMessage id='button.send_notification' /></>}
           visible={visibleModal === SEND_NOTIFICATION_TO_USER_MODAL_NAME}
           okText={<IntlMessage id='button.send' />}
           onOk={() => form.submit()}
           okButtonProps={{ loading: submitting }}
           onCancel={() => dispatch(cancelSendNotificationToUser())}>
      <Form {...layout} form={form} onFinish={onFinish}>
        <Form.Item label={intlFormatMessage('form.label.to')}>
          {user?.name}
        </Form.Item>
        <Form.Item name='title' label={intlFormatMessage('form.label.title')} rules={[getRequiredRule()]}>
          <Input autoFocus />
        </Form.Item>
        <Form.Item name='message' label={intlFormatMessage('form.label.message')} rules={[getRequiredRule()]}>
          <TextArea />
        </Form.Item>
      </Form>
    </Modal>
  );
};

type SendNotificationToUserProps = {
  user: User | undefined;
}

const SendNotificationToUser = ({ user }: SendNotificationToUserProps) => {
  const dispatch = useDispatch();

  return (
    <>
      <Button size='small' ghost type='primary' block disabled={!user}
              onClick={() => user && dispatch(startSendNotificationToUser(user))}>
        <NotificationOutlined className='mr-2' />
        <IntlMessage id='button.send_notification' />
      </Button>
      <SendNotificationToUserModal />
    </>
  );
};

export default SendNotificationToUser;
