import { connect, useDispatch } from 'react-redux';
import { checkWhoAmI } from '../../redux/actions';

const InitializeApp = () => {
  const dispatch = useDispatch();
  dispatch(checkWhoAmI());

  return (
    <></>
  )
}

export default connect()(InitializeApp);
