import { AdminUser } from '../../../../models/admin';
import {
  DELETE_ADMIN_USER_ASK_CONFIRM,
  DELETE_ADMIN_USER_CANCEL,
  DELETE_ADMIN_USER_CONFIRM,
  DELETE_ADMIN_USER_DISMISS_MODAL,
  DELETE_ADMIN_USER_FAILED,
  DELETE_ADMIN_USER_OPEN_MODAL,
  DELETE_ADMIN_USER_SUCCEEDED,
} from '../../../constants/views/settings/DeleteAdminUserConstants';
import { CONFIRM_DELETE_ADMIN_USER_MODAL } from '../../../../views/app-views/settings/components/DeleteAdminUser';

export type DeleteAdminUserAction = ReturnType<typeof askForConfirmationToDeleteAdminUser
  | typeof openDeleteAdminUserConfirmationModal
  | typeof dismissDeleteAdminUserConfirmationModal
  | typeof confirmDeleteAdminUser
  | typeof cancelDeleteAdminUser
  | typeof deleteAdminUserSucceeded
  | typeof deleteAdminUserFailed>;

export function askForConfirmationToDeleteAdminUser(adminUser: AdminUser) {
  return <const>{
    type: DELETE_ADMIN_USER_ASK_CONFIRM,
    adminUser,
  };
}

export function openDeleteAdminUserConfirmationModal() {
  return <const>{
    type: DELETE_ADMIN_USER_OPEN_MODAL,
    visibleModal: CONFIRM_DELETE_ADMIN_USER_MODAL,
  };
}

export function dismissDeleteAdminUserConfirmationModal() {
  return <const>{
    type: DELETE_ADMIN_USER_DISMISS_MODAL,
  };
}

export function confirmDeleteAdminUser(id: string) {
  return <const>{
    type: DELETE_ADMIN_USER_CONFIRM,
    id,
  };
}

export function cancelDeleteAdminUser() {
  return <const>{
    type: DELETE_ADMIN_USER_CANCEL,
  }
}

export function deleteAdminUserSucceeded(successMessage?: string) {
  return <const>{
    type: DELETE_ADMIN_USER_SUCCEEDED,
    successMessage,
  }
}

export function deleteAdminUserFailed(errorMessage?: string) {
  return <const>{
    type: DELETE_ADMIN_USER_FAILED,
    errorMessage,
  }
}
