import {
  ISSUE_GROUPS_COMPONENT_DISMISS_EDIT_ISSUE_GROUP_MODAL,
  ISSUE_GROUPS_COMPONENT_DISMISS_NEW_ISSUE_GROUP_MODAL,
  ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_ERRORED,
  ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_SUCCEEDED,
  ISSUE_GROUPS_COMPONENT_NEW_ISSUE_GROUP_SUCCEEDED,
  ISSUE_GROUPS_COMPONENT_OPEN_EDIT_ISSUE_GROUP_MODAL,
  ISSUE_GROUPS_COMPONENT_OPEN_NEW_ISSUE_GROUP_MODAL,
  ISSUE_GROUPS_COMPONENT_SUBMIT_EDIT_ISSUE_GROUP,
  ISSUE_GROUPS_COMPONENT_SUBMIT_ISSUE_GROUP_ERRORED,
  ISSUE_GROUPS_COMPONENT_SUBMIT_NEW_ISSUE_GROUP,
} from '../../../../constants/views/normative/components/IssueGroupsComponent';
import { EditIssueGroupInput, IssueGroupInput } from '../../../../../services/IssueGroupService';
import { NEW_ISSUE_GROUP_MODAL_NAME } from '../../../../../views/app-views/issue-types/normatives/components/NewIssueGroup';
import { IssueGroup } from '../../../../../models';
import { EDIT_ISSUE_GROUP_MODAL_NAME } from '../../../../../views/app-views/issue-types/normatives/components/EditIssueGroup';

export type IssueGroupsAction = ReturnType<typeof openNewIssueGroupModal
  | typeof dismissNewIssueGroupModal
  | typeof submitNewIssueGroup
  | typeof newIssueGroupSucceeded
  | typeof newIssueGroupErrored
  | typeof openEditIssueGroupModal
  | typeof dismissEditIssueGroupModal
  | typeof submitEditedIssueGroup
  | typeof editIssueGroupSucceeded
  | typeof editIssueGroupErrored>;

export function openNewIssueGroupModal() {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_OPEN_NEW_ISSUE_GROUP_MODAL,
    visibleModal: NEW_ISSUE_GROUP_MODAL_NAME,
    resetForm: false,
  }
}

export function dismissNewIssueGroupModal() {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_DISMISS_NEW_ISSUE_GROUP_MODAL,
    visibleModal: null,
    resetForm: true,
  }
}

export function submitNewIssueGroup(input: IssueGroupInput) {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_SUBMIT_NEW_ISSUE_GROUP,
    submitting: true,
    input,
  }
}

export function newIssueGroupSucceeded() {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_NEW_ISSUE_GROUP_SUCCEEDED,
    submitting: false,
  }
}

export function newIssueGroupErrored(errorMessage: string) {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_SUBMIT_ISSUE_GROUP_ERRORED,
    submitting: false,
    errorMessage,
  }
}

export function openEditIssueGroupModal(issueGroup: IssueGroup) {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_OPEN_EDIT_ISSUE_GROUP_MODAL,
    visibleModal: EDIT_ISSUE_GROUP_MODAL_NAME,
    resetForm: false,
    issueGroup,
  }
}

export function dismissEditIssueGroupModal() {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_DISMISS_EDIT_ISSUE_GROUP_MODAL,
    visibleModal: null,
    resetForm: true,
    issueGroup: null,
  }
}

export function submitEditedIssueGroup(id: string, input: EditIssueGroupInput) {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_SUBMIT_EDIT_ISSUE_GROUP,
    submitting: true,
    id,
    input,
  }
}

export function editIssueGroupSucceeded() {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_SUCCEEDED,
    submitting: false,
  }
}

export function editIssueGroupErrored(errorMessage: string) {
  return <const>{
    type: ISSUE_GROUPS_COMPONENT_EDIT_ISSUE_GROUP_ERRORED,
    submitting: false,
    errorMessage,
  }
}
