import { AdminUser } from '../../../../models/admin';
import { DeleteAdminUserAction } from '../../../actions/views/settings/DeleteAdminUserAction';
import {
  DELETE_ADMIN_USER_ASK_CONFIRM,
  DELETE_ADMIN_USER_CANCEL,
  DELETE_ADMIN_USER_CONFIRM,
  DELETE_ADMIN_USER_FAILED,
  DELETE_ADMIN_USER_SUCCEEDED,
} from '../../../constants/views/settings/DeleteAdminUserConstants';

export type DeleteAdminUserState = {
  adminUser: AdminUser | null;
  submitting: boolean;
}

const initDeleteAdminUserState: DeleteAdminUserState = {
  adminUser: null,
  submitting: false,
}

const deleteAdminUserReducer = (state = initDeleteAdminUserState, action: DeleteAdminUserAction): DeleteAdminUserState => {
  switch (action.type) {
    case DELETE_ADMIN_USER_ASK_CONFIRM:
      return {
        ...state,
        adminUser: action.adminUser,
      }
    case DELETE_ADMIN_USER_CANCEL:
      return {
        ...state,
        adminUser: null,
      }
    case DELETE_ADMIN_USER_CONFIRM:
      return {
        ...state,
        submitting: true,
      }
    case DELETE_ADMIN_USER_SUCCEEDED:
      return {
        ...state,
        adminUser: null,
        submitting: false,
      }
    case DELETE_ADMIN_USER_FAILED:
      return {
        ...state,
        submitting: false,
      }
    default: {
      return {
        ...state,
      }
    }
  }
}

export default deleteAdminUserReducer;
