import {
  USER_NAME_DISMISS_EDIT,
  USER_NAME_START_EDIT,
  USER_NAME_SUBMIT, USER_NAME_SUBMIT_FAILED,
  USER_NAME_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/users/components/UserNameConstants';

export type UserNameAction = ReturnType<typeof startUserNameEdit
  | typeof dismissUserNameEdit
  | typeof submitNewUserName
  | typeof submitNewUserNameSucceeded
  | typeof submitNewUserNameFailed>;

export function startUserNameEdit() {
  return <const>{
    type: USER_NAME_START_EDIT,
  };
}

export function dismissUserNameEdit() {
  return <const>{
    type: USER_NAME_DISMISS_EDIT,
  };
}

export function submitNewUserName(userId: string, newName: string) {
  return <const>{
    type: USER_NAME_SUBMIT,
    userId,
    newName,
  };
}

export function submitNewUserNameSucceeded() {
  return <const>{
    type: USER_NAME_SUBMIT_SUCCEEDED,
  };
}

export function submitNewUserNameFailed() {
  return <const>{
    type: USER_NAME_SUBMIT_FAILED,
  };
}
