export const ISSUE_TYPES_COMPONENT_NEW_TYPE_OPEN_MODAL = 'component/issue_types/action/modal/open/NEW_TYPE_MODAL';
export const ISSUE_TYPES_COMPONENT_DISMISS_TYPE_OPEN_MODAL = 'component/issue_types/action/modal/dismiss/NEW_TYPE_MODAL';
export const ISSUE_TYPES_COMPONENT_SUBMIT_NEW_ISSUE_TYPE = 'component/issue_types/edit/submit/NEW_TYPE_MODAL';
export const ISSUE_TYPES_COMPONENT_NEW_ISSUE_TYPE_SUCCEEDED = 'component/issue_types/edit/success/NEW_TYPE_MODAL';
export const ISSUE_TYPES_COMPONENT_SUBMIT_ISSUE_TYPE_ERRORED = 'component/issue_types/edit/error/NEW_TYPE_MODAL';

export const ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_OPEN_MODAL = 'component/issue_types/action/modal/open/EDIT_ISSUE_TYPE_MODAL';
export const ISSUE_TYPES_COMPONENT_EDIT_EDIT_TYPE_DISMISS_MODAL = 'component/issue_types/action/modal/dismiss/EDIT_ISSUE_TYPE_MODAL';
export const ISSUE_TYPES_COMPONENT_SUBMIT_EDIT_ISSUE_TYPE = 'component/issue_types/edit/submit/EDIT_ISSUE_TYPE';
export const ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_SUCCEEDED = 'component/issue_types/edit/success/EDIT_ISSUE_TYPE';
export const ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_ERRORED = 'component/issue_types/edit/error/EDIT_ISSUE_TYPE';

export const ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE = 'component/issue_types/submit/REMOVE_ISSUE_TYPE';
export const ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE_SUCCEEDED = 'component/issue_types/remove/success/REMOVE_ISSUE_TYPE_SUCCEEDED';
export const ISSUE_TYPES_COMPONENT_REMOVE_ISSUE_TYPE_ERRORED = 'component/issue_types/remove/error/REMOVE_ISSUE_TYPE_ERRORED';
