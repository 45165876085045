import {
  AUTH_TOKEN,
  AUTHENTICATED,
  SHOW_AUTH_MESSAGE,
  HIDE_AUTH_MESSAGE,
  SIGNOUT_SUCCESS,
  SIGNUP_SUCCESS,
  SHOW_LOADING,
  APP_USER_FETCHED,
  APP_USER_NOT_LOGGED_IN, UNAUTHORIZED, SIGNOUT,
} from '../constants/Auth';

export type AppUser = {
  id: string;
  name: string;
  email: string;
};

export type AuthState = {
  loading: boolean;
  message: string;
  showMessage: boolean;
  redirect: string;
  token: string | null;
  user: AppUser | null;
  checkedAuthentication: boolean;
};

const initState: AuthState = {
  loading: false,
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
  user: null,
  checkedAuthentication: false,
};

const auth = (state = initState, action: any): AuthState => {
  switch (action.type) {
    case APP_USER_FETCHED:
      return {
        ...state,
        user: action.user,
        checkedAuthentication: true,
      };
    case APP_USER_NOT_LOGGED_IN:
      return {
        ...state,
        checkedAuthentication: true,
      };
    case UNAUTHORIZED:
      return {
        ...state,
        user: null,
        redirect: action.redirect,
      };
    case AUTHENTICATED:
      return {
        ...state,
        loading: false,
        token: action.token,
        user: action.user,
      };
    case SHOW_AUTH_MESSAGE:
      return {
        ...state,
        message: action.message,
        showMessage: true,
        loading: false,
      };
    case HIDE_AUTH_MESSAGE:
      return {
        ...state,
        message: '',
        showMessage: false,
      };
    case SIGNOUT_SUCCESS: {
      return {
        ...state,
        token: null,
        redirect: '/',
        loading: false,
      };
    }
    case SIGNUP_SUCCESS: {
      return {
        ...state,
        loading: false,
        token: action.token,
      };
    }
    case SHOW_LOADING: {
      return {
        ...state,
        loading: true,
      };
    }
    case SIGNOUT: {
      return {
        ...state,
        user: null,
        token: null,
      };
    }
    default:
      return state;
  }
};

export default auth;
