import {
  ISSUE_DESCRIPTION_DISMISS,
  ISSUE_DESCRIPTION_START_EDIT,
  ISSUE_DESCRIPTION_SUBMIT_NEW,
  ISSUE_DESCRIPTION_SUBMIT_NEW_ERRORED,
  ISSUE_DESCRIPTION_SUBMIT_NEW_SUCCEEDED,
} from '../../../../constants/views/issues/components/IssueDescriptionConstants';

export type IssueDescriptionAction = ReturnType<typeof startIssueDescriptionEdit
  | typeof dismissIssueDescriptionEdit
  | typeof submitNewIssueDescription
  | typeof submitNewIssueDescriptionSucceeded
  | typeof submitNewIssueDescriptionErrored>

export function startIssueDescriptionEdit() {
  return <const>{
    type: ISSUE_DESCRIPTION_START_EDIT,
    editing: true,
  };
}

export function dismissIssueDescriptionEdit() {
  return <const>{
    type: ISSUE_DESCRIPTION_DISMISS,
    editing: false,
  };
}

export function submitNewIssueDescription(issueId: string, description: string) {
  return <const>{
    type: ISSUE_DESCRIPTION_SUBMIT_NEW,
    submitting: true,
    issueId,
    description,
  };
}

export function submitNewIssueDescriptionErrored() {
  return <const>{
    type: ISSUE_DESCRIPTION_SUBMIT_NEW_ERRORED,
    submitting: false,
  };
}

export function submitNewIssueDescriptionSucceeded() {
  return <const>{
    type: ISSUE_DESCRIPTION_SUBMIT_NEW_SUCCEEDED,
    submitting: false,
    editing: false,
  };
}
