import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import { useIntl } from 'react-intl';
import { intlFormatMessage } from '../../../../../utils/intl';
import {
  dismissAddNewIssueTypeModal,
  openAddNewIssueTypeModal, submitNewIssueType,
} from '../../../../../redux/actions/views/normative/components/IssueTypesActions';
import { IssueTypeInput } from '../../../../../services/IssueTypeService';
import { Normative } from '../../../../../models';
import IssueTypeForm from './IssueTypeForm';

export const NEW_ISSUE_TYPE_MODAL_NAME = 'modal/new_issue_type';

export type NewIssueTypeProps = {
  normative?: Normative;
}

const NewIssueType = ({ normative }: NewIssueTypeProps) => {
  const intl = useIntl();
  const dispatch = useDispatch();

  const { visibleModal } = useSelector((state: RootState) => state.modal);

  function onFinish(input: IssueTypeInput) {
    if (normative) {
      dispatch(submitNewIssueType(input, normative.id));
    }
  }

  return (
    <>
      <Button size='small' type='primary' ghost={true} onClick={() =>
        dispatch(openAddNewIssueTypeModal())
      }>
        {intlFormatMessage('button.new_issue_type')}
      </Button>
      <Modal title={intl.formatMessage({ id: 'modal.title.new_issue_type' })}
             visible={visibleModal === NEW_ISSUE_TYPE_MODAL_NAME}
             onCancel={() => dispatch(dismissAddNewIssueTypeModal())}
             footer={false}>
        <IssueTypeForm onFinish={onFinish}
                       onCancel={() => dispatch(dismissAddNewIssueTypeModal())}
                       okLabel='button.add'
                       normative={normative} />
      </Modal>
    </>
  );
};

export default NewIssueType;
