import { CityContact, CityContract, CityManagementData } from '../models/admin';
import { apolloClient } from '../clients/ApolloClient';
import { EDIT_CITY_MANAGEMENT_DATA, GET_CITY_MANAGEMENT_DATA } from '../graphql/cityManagementData';

export type CityManagementDataInput = {
  contact: CityContact;
  contract: CityContract;
  note: string | null;
}

export class CityManagementDataService {
  static get(cityId: string): Promise<CityManagementData | undefined> {
    return apolloClient.query<{ cityManagementData: CityManagementData }>({
      query: GET_CITY_MANAGEMENT_DATA,
      variables: { cityId },
    }).then(({ data }) => data?.cityManagementData);
  }

  static edit(id: string, input: CityManagementDataInput): Promise<CityManagementData | undefined> {
    return apolloClient.mutate<{ editCityManagementData: CityManagementData }>({
      mutation: EDIT_CITY_MANAGEMENT_DATA,
      variables: { id, input },
    }).then(({ data }) => data?.editCityManagementData);
  }
}
