import React, { lazy } from 'react';
import PrivateRoute from '../../../components/util-components/PrivateRoute';
import { appRoutes } from '../../../configs/AppRoutes';
import { CitiesListType } from './CitiesList';

const CitiesList = lazy(
  () => import('./CitiesList'),
);

export const CityViews = () => {
  return (
    <>
      <PrivateRoute
        path={appRoutes.pathDefinition('cities')}
        exact
        component={CitiesList}
      />

      <PrivateRoute
        path={appRoutes.pathDefinition('notAssociatedCities')}
        exact
        component={() => <CitiesList type={CitiesListType.NOT_ASSOCIATED} />}
      />

      <PrivateRoute
        path={appRoutes.pathDefinition('cityView')}
        exact
        component={lazy(
          () => import('./CityDetail'),
        )}
      />

      <PrivateRoute
        path={appRoutes.pathDefinition('cityUsersIssues')}
        exact
        component={lazy(
          () => import ('./issues/CityUsersIssuesList')
        )}
      />

      <PrivateRoute
        path={appRoutes.pathDefinition('cityMappingIssues')}
        exact
        component={lazy(
          () => import ('./issues/CityMappingIssuesList')
        )}
      />
    </>
  )
}

export default CityViews;
