import {
  ISSUE_STATUS_CHANGE_NOT_IMPLEMENTED_ERROR,
  ISSUE_STATUS_CHANGE_SUBMISSION_ERRORED,
  ISSUE_STATUS_CHANGE_SUCCEEDED,
  ISSUE_STATUS_CHANGED,
} from '../../../constants/views/issues/components/IssueStatusConstants';
import { IssueStatusAction } from '../../../actions/views/issues/components/IssueStatusAction';
import { IssueDescriptionAction } from '../../../actions/views/issues/components/IssueDescriptionAction';
import {
  ISSUE_DESCRIPTION_DISMISS,
  ISSUE_DESCRIPTION_START_EDIT,
  ISSUE_DESCRIPTION_SUBMIT_NEW,
  ISSUE_DESCRIPTION_SUBMIT_NEW_ERRORED,
  ISSUE_DESCRIPTION_SUBMIT_NEW_SUCCEEDED,
} from '../../../constants/views/issues/components/IssueDescriptionConstants';
import { IssueGroupComponentAction } from '../../../actions/views/issues/components/IssueGroupComponentAction';
import {
  ISSUE_GROUP_COMPONENT_DISMISS_EDIT,
  ISSUE_GROUP_COMPONENT_START_EDIT,
  ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES,
  ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES_ERRORED,
  ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES_SUCCEEDED,
} from '../../../constants/views/issues/components/IssueGroupComponentConstants';
import { LOCATION_CHANGE, LocationChangeAction } from 'connected-react-router';
import { Image } from '../../../../models';
import { ISSUE_IMAGE_COMPONENT_IMAGES_LOADED, RESOLVED_ISSUE_IMAGE_COMPONENT_IMAGES_LOADED } from '../../../constants/views/issues/components/IssueImageConstants';
import { IssueImageComponentAction } from '../../../actions/views/issues/components/IssueImageComponentAction';

export type IssueDescriptionState = {
  editing: boolean;
  submitting: boolean;
}

export type IssueGroupState = {
  editing: boolean;
  submitting: boolean;
}

export type IssueDetailState = {
  submitting: boolean,
  submittingStatus: boolean,
  errorMessage: string | null;
  description: IssueDescriptionState;
  issueGroup: IssueGroupState;
  images: Image[],
  resolvedImages?: Image[]
};

const initState: IssueDetailState = {
  submitting: false,
  submittingStatus: false,
  errorMessage: null,
  description: {
    editing: false,
    submitting: false,
  },
  issueGroup: {
    editing: false,
    submitting: false,
  },
  images: [],
  resolvedImages: []
};

const issueDetailReducer = (
  state = initState,
  action: IssueStatusAction | IssueDescriptionAction | IssueGroupComponentAction | LocationChangeAction | IssueImageComponentAction,
): IssueDetailState => {
  switch (action.type) {
    case ISSUE_STATUS_CHANGE_SUBMISSION_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
        submittingStatus: action.submitting,
        errorMessage: action.errorMessage,
      };
    case ISSUE_STATUS_CHANGED:
      return {
        ...state,
        submittingStatus: action.submittingStatus,
      };
    case ISSUE_STATUS_CHANGE_NOT_IMPLEMENTED_ERROR:
    case ISSUE_STATUS_CHANGE_SUCCEEDED:
      return {
        ...state,
        submittingStatus: action.submittingStatus,
      };
    case ISSUE_DESCRIPTION_START_EDIT:
    case ISSUE_DESCRIPTION_DISMISS:
      return {
        ...state,
        description: {
          ...state.description,
          editing: action.editing,
        },
      };
    case ISSUE_DESCRIPTION_SUBMIT_NEW:
    case ISSUE_DESCRIPTION_SUBMIT_NEW_ERRORED:
      return {
        ...state,
        description: {
          ...state.description,
          submitting: action.submitting,
        },
      };
    case ISSUE_DESCRIPTION_SUBMIT_NEW_SUCCEEDED:
      return {
        ...state,
        description: {
          ...state.description,
          submitting: action.submitting,
          editing: action.editing,
        },
      };
    case ISSUE_GROUP_COMPONENT_START_EDIT:
    case ISSUE_GROUP_COMPONENT_DISMISS_EDIT:
      return {
        ...state,
        issueGroup: {
          ...state.issueGroup,
          editing: action.editing,
        },
      };
    case ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES:
    case ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES_SUCCEEDED:
    case ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES_ERRORED:
      return {
        ...state,
        issueGroup: {
          ...state.issueGroup,
          submitting: action.submitting,
        },
      };
    case ISSUE_IMAGE_COMPONENT_IMAGES_LOADED:
      return {
        ...state,
        images: action.images,
      };
    case RESOLVED_ISSUE_IMAGE_COMPONENT_IMAGES_LOADED:
      return {
        ...state,
        resolvedImages: action.images,
      };
    case LOCATION_CHANGE:
      return {
        ...initState,
      };
    default:
      return {
        ...state,
      };
  }
};

export default issueDetailReducer;
