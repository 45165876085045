import { City, CityGeneralData } from '../models/places';
import { gql } from '@apollo/client/core';
import { apolloClient } from '../clients/ApolloClient';
import { CitiesListType } from '../views/app-views/cities/CitiesList';
import { GET_PUBLISHED_CITIES_COUNT } from '../graphql/cities';

export const CITY_DATA_FRAGMENT = gql`
  fragment Data on City {
    data {
      geometry {
        location {
          lat
          lng
        }
      }
    }
  }
`;

export const GET_CITIES = gql`
query GetCities($filter: String) {
  cities(filter: $filter) {
    id
    name
    status
    normative {
      id
      name
    }
    issuesToValidate
    newIssues
  }
}
`;

export const GET_ASSOCIATED_CITIES = gql`
query GetAssociatedCities {
  cities(filter: "${CitiesListType.ASSOCIATED}") {
    id
    name
    status
    normative {
      id
      name
    }
    issuesToValidate
    newIssues
  }
}
`;

export const GET_ASSOCIATED_CITIES_MIN = gql`
query GetAssociatedCities {
  cities(filter: "${CitiesListType.ASSOCIATED}") {
    id
    name
  }
}
`;

export const GET_NOT_ASSOCIATED_CITIES = gql`
query GetNotCities {
  cities(filter: "${CitiesListType.NOT_ASSOCIATED}") {
    id
    name
    outOfZoneIssuesCount
  }
}
`;

const ADD_CITY = gql`
  ${CITY_DATA_FRAGMENT}
  mutation AddCity($id: String!) {
    addCity(id: $id) {
      id
      name
      ...Data
    }
  }
`;

const EDIT_CITY = gql`
  ${CITY_DATA_FRAGMENT}
  
  mutation EditCity($id: ID!, $data: CityData!) {
    editCity(id: $id, cityData: $data) {
      id
      name
      ...Data
    }
  }
`;

export const GET_CITY = gql`
  query GetCity($id: ID!) {
    city(id: $id) {
      id
      name
    }
  }
`;

export class CityService {
  static add(placeId: string): Promise<City | undefined> {
    return apolloClient.mutate<{ addCity: City }>({
      mutation: ADD_CITY,
      variables: { id: placeId },
      refetchQueries: [
        { query: GET_CITIES },
        { query: GET_NOT_ASSOCIATED_CITIES },
        { query: GET_ASSOCIATED_CITIES },
      ],
    })
      .then(({ data }) => data?.addCity);
  }

  static edit(data: CityGeneralData): Promise<City | undefined> {
    return apolloClient.mutate<{ editCity: City }>({
      mutation: EDIT_CITY,
      variables: {
        id: data.id,
        data: {
          normative: data.normative,
          status: data.status?.toLocaleUpperCase(),
          name: data.name,
        },
      },
      refetchQueries: [
        { query: GET_CITIES },
        { query: GET_NOT_ASSOCIATED_CITIES },
        { query: GET_ASSOCIATED_CITIES },
        { query: GET_PUBLISHED_CITIES_COUNT },
      ],
    })
      .then(({ data }) => data?.editCity);
  }

  static countPublishedCities(): Promise<number | undefined> {
    return apolloClient.query<{ publishedCitiesCount: { cities: number } }>({
      query: GET_PUBLISHED_CITIES_COUNT,
    }).then(({ data }) => data?.publishedCitiesCount.cities)
  }
}
