import {
  FORM_SUBMISSION_ENDED_WITH_ERRORS,
  FORM_SUBMISSION_SUCCEEDED,
} from '../constants/Form';
import {
  formSubmissionEndedWithErrors,
  formSubmissionSucceeded,
} from '../actions';

export type FormState = {
  data: { [key: string]: any } | null;
  error: string;
  submitting: boolean;
  success: boolean;
};

const initState: FormState = {
  data: null,
  error: '',
  submitting: false,
  success: false,
};

type Action = ReturnType<
  | typeof formSubmissionEndedWithErrors
  | typeof formSubmissionSucceeded
>;

const form = (state = initState, action: Action): FormState => {
  switch (action.type) {
    case FORM_SUBMISSION_ENDED_WITH_ERRORS:
      return {
        ...state,
        submitting: action.submitting,
        error: action.error,
      };
    case FORM_SUBMISSION_SUCCEEDED:
      return {
        ...initState,
      }
    default:
      return state;
  }
};

export default form;
