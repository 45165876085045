import { AlertsAction } from '../actions/AlertsAction';
import { ALERTS_UPDATE_UNREAD } from '../constants/Alerts';

type AlertsState = {
  unreadCount: number;
}

const initState: AlertsState = {
  unreadCount: 0,
};

const alertsReducer = (state = initState, action: AlertsAction): AlertsState => {
  switch (action.type) {
    case ALERTS_UPDATE_UNREAD:
      return {
        ...state,
        unreadCount: action.unreadCount,
      };
    default:
      return {
        ...state,
      };
  }
};

export default alertsReducer;
