import { ConfigValue, ConfigValueType } from '../models/config';
import { gql } from '@apollo/client';
import { apolloClient } from '../clients/ApolloClient';
import { GET_CONFIG_VALUES } from '../graphql/config';

const SET_CONFIG_VALUE = gql`
  mutation SetConfigValue($key: String!, $input: ConfigValueInput!) {
    setConfigValue(key: $key, input: $input) {
      id
      key
      value
    }
  }
`;

export class ConfigValueService {
  static set(key: ConfigValueType, value: string): Promise<ConfigValue | undefined> {
    return apolloClient.mutate<{ setConfigValue: ConfigValue }>({
      mutation: SET_CONFIG_VALUE,
      variables: { key, input: { value } },
      refetchQueries: [{ query: GET_CONFIG_VALUES }],
    }).then(({ data }) => data?.setConfigValue);
  }
}
