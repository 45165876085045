import { IssueTypesAction } from '../../../../actions/views/normative/components/IssueTypesActions';
import {
  ISSUE_TYPES_COMPONENT_DISMISS_TYPE_OPEN_MODAL,
  ISSUE_TYPES_COMPONENT_EDIT_EDIT_TYPE_DISMISS_MODAL,
  ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_ERRORED,
  ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_OPEN_MODAL,
  ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_SUCCEEDED,
  ISSUE_TYPES_COMPONENT_NEW_ISSUE_TYPE_SUCCEEDED,
  ISSUE_TYPES_COMPONENT_NEW_TYPE_OPEN_MODAL,
  ISSUE_TYPES_COMPONENT_SUBMIT_EDIT_ISSUE_TYPE,
  ISSUE_TYPES_COMPONENT_SUBMIT_ISSUE_TYPE_ERRORED,
  ISSUE_TYPES_COMPONENT_SUBMIT_NEW_ISSUE_TYPE,
} from '../../../../constants/views/normative/components/IssueTypesComponent';
import { IssueType } from '../../../../../models';

export type IssueTypesComponentState = {
  resetForm: boolean;
  submitting: boolean;
  errorMessage: string | null;
  selectedIssueType: IssueType | null;
}

const initState: IssueTypesComponentState = {
  resetForm: true,
  submitting: false,
  errorMessage: null,
  selectedIssueType: null,
}

const issueTypesComponentReducer = (state = initState, action: IssueTypesAction): IssueTypesComponentState => {
  switch (action.type) {
    case ISSUE_TYPES_COMPONENT_NEW_TYPE_OPEN_MODAL:
    case ISSUE_TYPES_COMPONENT_DISMISS_TYPE_OPEN_MODAL:
      return {
        ...state,
        resetForm: action.resetForm,
        errorMessage: null,
      }
    case ISSUE_TYPES_COMPONENT_SUBMIT_NEW_ISSUE_TYPE:
    case ISSUE_TYPES_COMPONENT_SUBMIT_EDIT_ISSUE_TYPE:
      return {
        ...state,
        submitting: action.submitting,
      }
    case ISSUE_TYPES_COMPONENT_NEW_ISSUE_TYPE_SUCCEEDED:
    case ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_SUCCEEDED:
      return {
        ...state,
        submitting: action.submitting,
      }
    case ISSUE_TYPES_COMPONENT_SUBMIT_ISSUE_TYPE_ERRORED:
    case ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_ERRORED:
      return {
        ...state,
        submitting: action.submitting,
        errorMessage: action.errorMessage,
      }
    case ISSUE_TYPES_COMPONENT_EDIT_ISSUE_TYPE_OPEN_MODAL:
    case ISSUE_TYPES_COMPONENT_EDIT_EDIT_TYPE_DISMISS_MODAL:
      return {
        ...state,
        resetForm: action.resetForm,
        errorMessage: null,
        selectedIssueType: action.selectedIssueType,
      }
    default:
      return {
        ...state,
      }
  }
}

export default issueTypesComponentReducer;
