import { all, call, delay, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  EXPORT_ISSUE_CREATE_NEW_TASK,
  EXPORT_ISSUE_IN_PROGRESS_RECEIVED,
} from '../../../constants/views/issues/ExportIssuesConstants';
import {
  exportTaskInProgressReceived,
  newExportTaskFailed,
  newExportTaskSucceeded,
} from '../../../actions/views/issues/ExportIssuesAction';
import { ExportTaskService } from '../../../../services/ExportTaskService';
import { ExportTask, ExportTaskState } from '../../../../models/exportIssues';

function* handleCreateNewExportTaskSaga() {
  try {
    yield call(ExportTaskService.create);
    yield put(newExportTaskSucceeded('notification.export_task.success.created'));
  } catch (e) {
    yield put(newExportTaskFailed());
  }
}

function* handleCheckExportTaskProgress({ exportTaskId }: ReturnType<typeof exportTaskInProgressReceived>) {
  yield delay(1_000);
  const exportTask: ExportTask = yield call(ExportTaskService.get, exportTaskId, false);
  if (exportTask.state !== ExportTaskState.COMPLETED) {
    yield put(exportTaskInProgressReceived(exportTaskId));
  }
}

export default function* exportIssuesSagas() {
  yield all([
    takeLatest(EXPORT_ISSUE_CREATE_NEW_TASK, handleCreateNewExportTaskSaga),
    takeEvery(EXPORT_ISSUE_IN_PROGRESS_RECEIVED, handleCheckExportTaskProgress),
  ]);
}
