import { combineReducers } from 'redux';
import deleteUserReducer from './components/DeleteUserReducer';
import { userCityTeamMembershipReducer } from './components/UserCityTeamMembershipReducer';
import { addUserCityTeamMembershipReducer } from './components/AddUserCityTeamMembershipReducer';
import { userLockedReducer } from './components/UserLockedReducer';
import userNameReducer from './components/UserNameReducer';
import userRoleReducer from './components/UserRoleReducer';
import sendNotificationToUserReducer from './components/SendNotificationToUserReducer';

const usersReducers = combineReducers({
  delete: deleteUserReducer,
  detail: combineReducers({
    userCityTeamMembership: userCityTeamMembershipReducer,
    addCityTeamMembership: addUserCityTeamMembershipReducer,
    userLocked: userLockedReducer,
    name: userNameReducer,
    role: userRoleReducer,
    sendNotification: sendNotificationToUserReducer,
  }),
});

export default usersReducers;
