import { Normative } from '../../../../../models';
import { Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import {
  dismissEditIssueGroupModal,
  submitEditedIssueGroup,
} from '../../../../../redux/actions/views/normative/components/IssueGroupsActions';
import IssueGroupForm from './IssueGroupForm';
import intl from '../../../../../utils/intl';

export const EDIT_ISSUE_GROUP_MODAL_NAME = 'modal/edit_issue_group';

type EditIssueGroupProps = {
  normative?: Normative;
}

const EditIssueGroup = ({ normative }: EditIssueGroupProps) => {
  const dispatch = useDispatch();
  const { visibleModal } = useSelector((state: RootState) => state.modal);
  const { selectedIssueGroup } = useSelector((state: RootState) => state.views.issueGroupsComponent);

  const onFinish = (input: any) => {
    if (selectedIssueGroup) {
      dispatch(submitEditedIssueGroup(selectedIssueGroup.id, { ...input, icon: input.icon?.file }));
    }
  }

  return (
    <Modal title={intl.formatMessage({ id: 'modal.title.edit_issue_group' }, { title: selectedIssueGroup?.name })}
           visible={visibleModal === EDIT_ISSUE_GROUP_MODAL_NAME}
           onCancel={() => dispatch(dismissEditIssueGroupModal())}
           footer={false}>
      <IssueGroupForm normative={normative}
                      onFinish={onFinish}
                      onCancel={() => dispatch(dismissEditIssueGroupModal())}
                      okLabel='button.update' />
    </Modal>
  );
};

export default EditIssueGroup;
