import {
  CHANGE_PASSWORD_FORM_RESET_DONE,
  CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_ERRORED,
  CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_INFO, CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCEEDED,
  EDIT_PROFILE_SUBMIT_NEW_PROFILE_ERRORED,
  EDIT_PROFILE_SUBMIT_NEW_PROFILE_INFO,
  EDIT_PROFILE_SUBMIT_NEW_PROFILE_SUCCEEDED,
} from '../../../constants/views/profile/ProfileSettingsConstants';
import { EditProfileInput } from '../../../../views/app-views/profile/EditProfile';
import { ChangePasswordInput } from '../../../../services/AdminUserService';

export type ProfileSettingsActions = ReturnType<typeof submitNewProfileInfo
  | typeof newProfileInfoSubmissionSucceeded
  | typeof newProfileInfoSubmissionErrored
  | typeof submitNewPassword
  | typeof newPasswordSubmissionSucceeded
  | typeof newPasswordSubmissionErrored
  | typeof changePasswordFormCleared>

export function submitNewProfileInfo(id: string, input: EditProfileInput) {
  return <const>{
    type: EDIT_PROFILE_SUBMIT_NEW_PROFILE_INFO,
    submitting: true,
    id,
    input,
  }
}

export function newProfileInfoSubmissionSucceeded() {
  return <const>{
    type: EDIT_PROFILE_SUBMIT_NEW_PROFILE_SUCCEEDED,
    submitting: false,
  }
}

export function newProfileInfoSubmissionErrored() {
  return <const>{
    type: EDIT_PROFILE_SUBMIT_NEW_PROFILE_ERRORED,
    submitting: false,
  }
}

export function submitNewPassword(id: string, input: ChangePasswordInput) {
  return <const>{
    type: CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_INFO,
    submitting: true,
    id,
    input,
  }
}

export function newPasswordSubmissionSucceeded() {
  return <const>{
    type: CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_SUCCEEDED,
    resetForm: true,
    submitting: false,
  }
}

export function newPasswordSubmissionErrored(errorMessage: string) {
  return <const>{
    type: CHANGE_PASSWORD_SUBMIT_NEW_PASSWORD_ERRORED,
    submitting: false,
    errorMessage,
  }
}

export function changePasswordFormCleared() {
  return <const>{
    type: CHANGE_PASSWORD_FORM_RESET_DONE,
    resetForm: false,
  }
}
