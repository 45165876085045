import {
  CITY_MANAGEMENT_DATA_FETCH_COMPLETED,
  CITY_MANAGEMENT_DATA_FETCH_FAILED,
  CITY_MANAGEMENT_DATA_FETCH_START, CITY_MANAGEMENT_DATA_RESET,
  CITY_MANAGEMENT_DATA_SUBMIT_FAILED,
  CITY_MANAGEMENT_DATA_SUBMIT_START,
  CITY_MANAGEMENT_DATA_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/cities/components/CityManagementData';
import { CityManagementData } from '../../../../../models/admin';
import { CityManagementDataInput } from '../../../../../services/CityManagementDataService';

export type CityManagementDataAction = ReturnType<typeof fetchCityManagementDataStart
  | typeof fetchCityManagementDataCompleted
  | typeof fetchCityManagementDataFailed
  | typeof submitCityManagementDataStart
  | typeof submitCityManagementDataSucceeded
  | typeof submitCityManagementDataFailed
  | typeof resetCityManagementData>

export function fetchCityManagementDataStart(cityId: string) {
  return <const>{
    type: CITY_MANAGEMENT_DATA_FETCH_START,
    cityId,
  };
}

export function fetchCityManagementDataCompleted(cityManagementData: CityManagementData) {
  return <const>{
    type: CITY_MANAGEMENT_DATA_FETCH_COMPLETED,
    cityManagementData,
  };
}

export function fetchCityManagementDataFailed() {
  return <const>{
    type: CITY_MANAGEMENT_DATA_FETCH_FAILED,
  };
}

export function submitCityManagementDataStart(id: string, input: CityManagementDataInput) {
  return <const>{
    type: CITY_MANAGEMENT_DATA_SUBMIT_START,
    id,
    input,
  };
}

export function submitCityManagementDataSucceeded(cityManagementData: CityManagementData) {
  return <const>{
    type: CITY_MANAGEMENT_DATA_SUBMIT_SUCCEEDED,
    cityManagementData,
  }
}

export function submitCityManagementDataFailed(errorMessage?: string) {
  return <const>{
    type: CITY_MANAGEMENT_DATA_SUBMIT_FAILED,
    errorMessage,
  }
}

export function resetCityManagementData() {
  return <const>{
    type: CITY_MANAGEMENT_DATA_RESET,
  }
}
