import { gql } from '@apollo/client/core';

export const GET_CONFIG_VALUES = gql`
  query GetConfigValues {
    configValues {
      id
      key
      value
    }
  }
`;
