import { Alert, Button, Card, Col, Divider, Form, List, Modal, Row, Select } from 'antd';
import IntlMessage from '../../../../components/util-components/IntlMessage';
import { DeleteOutlined, TeamOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  addNewCityTeamMember,
  askCityTeamMemberConfirmation,
  cancelCityTeamMemberDeletion,
  deleteCityTeamMember,
  dismissAddTeamMemberModal,
  openAddTeamMemberModal,
} from '../../../../redux/actions/views/cities/components/CityTeamMembersComponentActions';
import { gql } from '@apollo/client/core';
import { useQuery } from '@apollo/client';
import { User } from '../../../../models';
import { useIntl } from 'react-intl';
import { CityTeamMember, CityTeamMemberType } from '../../../../models/teamMembers';
import { CityTeamMemberInput, GET_CITY_TEAM_MEMBERS } from '../../../../graphql/teamMembers';
import { useForm } from 'antd/es/form/Form';
import { Link } from "react-router-dom";
import { appRoutes } from '../../../../configs/AppRoutes';

const GET_TEAM_MEMBERS = gql`
  query GetTeamMembers {
    teamMembers {
      id
      name
    }
  }
`;

export const ADD_TEAM_MEMBER_MODAL_NAME = 'modal.addTeamMember';
export const CONFIRM_DELETE_TEAM_MEMBER_MODAL_NAME = 'modal.confirmTeamMemberDeletion';

type AddTeamMemberProps = Pick<CityTeamMemberComponentProps, 'cityId'> & { cityTeamMembers: CityTeamMember[] }

const AddTeamMember = ({ cityId, cityTeamMembers }: AddTeamMemberProps) => {
  const intl = useIntl();
  const [addMemberForm] = useForm();
  const dispatch = useDispatch();
  const {
    visibleModal,
    submitting,
    errorMessage,
  } = useSelector((state: RootState) => state.views.cityTeamMembersComponent);

  const teamMembersQuery = useQuery<{ teamMembers: User[] }>(GET_TEAM_MEMBERS);
  const teamMembers: User[] = teamMembersQuery.data?.teamMembers ?? [];
  const availableTeamMembers = teamMembers.filter(
    (teamMember) => -1 === cityTeamMembers.map((item) => item.user.id).indexOf(teamMember.id)
  );

  const onAddMemberFinish = (values: Omit<CityTeamMemberInput, 'city'>) => {
    dispatch(addNewCityTeamMember({ city: cityId, ...values }));
    addMemberForm.resetFields();
  };



  return (
    <>
      <Button
        size='small'
        type='primary'
        ghost={true}
        onClick={() => dispatch(openAddTeamMemberModal())}
        disabled={availableTeamMembers.length === 0}
      >
        <IntlMessage id='component.city_team.button.add_member' />
      </Button>
      <Modal title={<IntlMessage id='component.city_team.modal.add_member.title' />}
             visible={visibleModal === ADD_TEAM_MEMBER_MODAL_NAME}
             onCancel={() => dispatch(dismissAddTeamMemberModal())}
             footer={false}
      >
        <Form onFinish={onAddMemberFinish} form={addMemberForm}>
          {errorMessage ? <Alert message={errorMessage} type='error' closable className='mb-4' /> : ''}
          <Form.Item label={intl.formatMessage({ id: 'component.city_team.modal.add_member.label_type' })}
                     name='type'
                     required={true}
                     rules={[{ required: true, message: intl.formatMessage({ id: 'form.errors.required' }) }]}>
            <Select>
              {Object.keys(CityTeamMemberType).map((key) => (
                <Select.Option value={key} key={key}>
                  <IntlMessage id={`cityTeamMember.type.${key.toLowerCase()}`} />
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item label={<IntlMessage id='component.city_team.modal.add_member.label_member' />}
                     name='user'
                     required={true}
                     rules={[{ required: true, message: intl.formatMessage({ id: 'form.errors.required' }) }]}>
            <Select showSearch
                    filterOption={(input, option) =>
                      option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }>
              {availableTeamMembers.map((teamMember) => (
                <Select.Option value={teamMember.id} key={teamMember.id}>{teamMember.name}</Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Divider />
          <div className='text-right'>
            <Button className='mr-2'
                    onClick={() => dispatch(dismissAddTeamMemberModal())}>
              <IntlMessage id='button.cancel' />
            </Button>
            <Button className='mr-2' type='primary' htmlType='submit' disabled={submitting}>
              <IntlMessage id='button.add' />
            </Button>
          </div>
        </Form>
      </Modal>
    </>
  )
}

type CityTeamMemberComponentProps = {
  cityId: string;
}

type TeamMemberListProps = {
  title: string,
  teamMembers: CityTeamMember[],
  type: CityTeamMemberType,
}

const TeamMemberList = ({ title, type, teamMembers }: TeamMemberListProps) => {
  const dispatch = useDispatch();

  const getCityTeamMembersByType = (type: CityTeamMemberType) => {
    return teamMembers
      .filter((member) => member.type === type)
      .sort((a, b) => a.user.name.localeCompare(b.user.name));
  };

  return (
    <List header={<h3 className='font-size-md mb-0'><IntlMessage id={title} /></h3>}
          bordered
          size='large'
          dataSource={getCityTeamMembersByType(type)}
          renderItem={(item: CityTeamMember) => (
            <List.Item actions={[
              <DeleteOutlined onClick={() => dispatch(askCityTeamMemberConfirmation(item))} />,
            ]}>
              <Link to={appRoutes.path('userDetail', { id: item.user.id })}>
                {item.user.name}
              </Link>
            </List.Item>
          )} />
  );
};

const CityTeamMembersComponent = ({ cityId }: CityTeamMemberComponentProps) => {
  const dispatch = useDispatch();
  const intl = useIntl();
  const {
    visibleModal,
    submitting,
    cityTeamMemberToDelete,
  } = useSelector((state: RootState) => state.views.cityTeamMembersComponent);

  const cityTeamMembersQuery = useQuery<{ cityTeamMembers: CityTeamMember[] }>(GET_CITY_TEAM_MEMBERS, { variables: { city: cityId } });
  const cityTeamMembers: CityTeamMember[] = cityTeamMembersQuery.data?.cityTeamMembers ?? [];

  return (
    <>
      <Card>
        <Row>
          <Col span={24} className='d-flex align-items-center justify-content-between'>
            <h3 className='font-size-md mb-0 font-weight-bolder'><TeamOutlined /> <IntlMessage id='component.city_team.title' /></h3>
            <AddTeamMember cityId={cityId} cityTeamMembers={cityTeamMembers} />
          </Col>
        </Row>
        <Divider />
        <Row gutter={12}>
          <Modal title={intl.formatMessage({ id: 'modal.title.confirmation' })}
                 visible={visibleModal === CONFIRM_DELETE_TEAM_MEMBER_MODAL_NAME && cityTeamMemberToDelete !== null}
                 okButtonProps={{ danger: true }}
                 okText={intl.formatMessage({ id: 'button.confirm' })}
                 onOk={() => dispatch(deleteCityTeamMember(cityTeamMemberToDelete as CityTeamMember))}
                 onCancel={() => dispatch(cancelCityTeamMemberDeletion())}
                 confirmLoading={submitting}>
            <IntlMessage id='component.city_team.modal.remove_member.message'
                         values={{ teamMember: cityTeamMemberToDelete?.user.name, role: cityTeamMemberToDelete?.type }} />
          </Modal>
          <Col span={12}>
            <TeamMemberList title='component.city_team.supervisors' teamMembers={cityTeamMembers}
                            type={CityTeamMemberType.SUPERVISOR} />
          </Col>
          <Col span={12}>
            <TeamMemberList title='component.city_team.managers' teamMembers={cityTeamMembers}
                            type={CityTeamMemberType.MANAGER} />
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default CityTeamMembersComponent;
