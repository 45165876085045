import { Button, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../../redux/store';
import {
  dismissNewIssueGroupModal,
  openNewIssueGroupModal, submitNewIssueGroup,
} from '../../../../../redux/actions/views/normative/components/IssueGroupsActions';
import IntlMessage from '../../../../../components/util-components/IntlMessage';
import { Normative } from '../../../../../models';
import IssueGroupForm from './IssueGroupForm';
import { intlFormatMessage } from '../../../../../utils/intl';

export const NEW_ISSUE_GROUP_MODAL_NAME = 'modal/new_issue_group';

type NewIssueGroupProps = {
  normative?: Normative;
}

const NewIssueGroup = ({ normative }: NewIssueGroupProps) => {
  const dispatch = useDispatch();
  const { visibleModal } = useSelector((state: RootState) => state.modal);

  const onFinish = (values: any) => {
    dispatch(
      submitNewIssueGroup({
        normative: normative?.id,
        ...values,
        icon: values.icon?.file,
      }),
    );
  };

  return (
    <>
      <Button
        size='small'
        type='primary'
        ghost={true}
        onClick={() =>
          dispatch(openNewIssueGroupModal())
        }
      >
        <IntlMessage id='button.new_issue_group' />
      </Button>
      <Modal title={intlFormatMessage('modal.title.new_issue_group')}
             visible={visibleModal === NEW_ISSUE_GROUP_MODAL_NAME}
             footer={false}
             onCancel={() => dispatch(dismissNewIssueGroupModal())}>
        <IssueGroupForm normative={normative}
                        onFinish={onFinish}
                        okLabel='button.add'
                        onCancel={() => dispatch(dismissNewIssueGroupModal())} />
      </Modal>
    </>
  );
};

export default NewIssueGroup;
