import { CityStatusInfoAction } from '../../../../actions/views/cities/components/CityStatusInfoAction';
import {
  CITY_STATUS_INFO_SUBMIT,
  CITY_STATUS_INFO_SUBMIT_FAILED,
  CITY_STATUS_INFO_SUBMIT_SUCCEEDED,
  CITY_STATUS_INFO_EDIT_CANCEL,
  CITY_STATUS_INFO_EDIT_START,
} from '../../../../constants/views/cities/components/CityStatusInfo';

type CityStatusInfoState = {
  submitting: boolean,
  edit: boolean,
}

const initState: CityStatusInfoState = {
  submitting: false,
  edit: false,
};

const cityStatusInfoReducer = (state = initState, action: CityStatusInfoAction): CityStatusInfoState => {
  switch (action.type) {
    case CITY_STATUS_INFO_SUBMIT:
      return {
        ...state,
        submitting: true,
      }
    case CITY_STATUS_INFO_SUBMIT_SUCCEEDED:
      return {
        ...state,
        edit: false,
        submitting: false,
      }
    case CITY_STATUS_INFO_SUBMIT_FAILED:
      return {
        ...state,
        submitting: false,
      }
    case CITY_STATUS_INFO_EDIT_START:
      return {
        ...state,
        edit: true,
      }
    case CITY_STATUS_INFO_EDIT_CANCEL:
      return {
        ...state,
        edit: false,
      }
    default:
      return {
        ...state,
      };
  }
};

export default cityStatusInfoReducer;
