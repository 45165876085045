import { UserNameAction } from '../../../../actions/views/users/components/UserNameAction';
import {
  USER_NAME_DISMISS_EDIT,
  USER_NAME_START_EDIT,
  USER_NAME_SUBMIT,
  USER_NAME_SUBMIT_FAILED,
  USER_NAME_SUBMIT_SUCCEEDED,
} from '../../../../constants/views/users/components/UserNameConstants';

export type UserNameState = {
  submitting: boolean;
  edit: boolean;
}

const initState: UserNameState = {
  submitting: false,
  edit: false,
}

export default function userNameReducer(state = initState, action: UserNameAction): UserNameState {
  switch (action.type) {
    case USER_NAME_START_EDIT:
      return {
        ...state,
        edit: true,
      }
    case USER_NAME_DISMISS_EDIT:
      return {
        ...state,
        edit: false,
      }
    case USER_NAME_SUBMIT:
      return {
        ...state,
        submitting: true,
      }
    case USER_NAME_SUBMIT_SUCCEEDED:
    case USER_NAME_SUBMIT_FAILED:
      return {
        ...state,
        submitting: false,
      }
    default:
      return {
        ...state,
      }
  }
}
