import { Badge } from 'antd';
import { BellOutlined } from '@ant-design/icons';
import { RootState } from '../../redux/store';
import { useSelector } from 'react-redux';

const NavAlerts = () => {
  const { unreadCount } = useSelector((state: RootState) => state.alerts);

  return (
    <Badge count={unreadCount}>
      <BellOutlined className='nav-icon' />
    </Badge>
  )
}

export default NavAlerts;
