import { Form, Modal, Select } from 'antd';
import { intlFormatMessage } from '../../../../utils/intl';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../../../redux/store';
import {
  cancelEditUserCityTeamMembership,
  editUserCityTeamMembershipSubmit,
} from '../../../../redux/actions/views/users/components/UserCityTeamMembershipAction';
import { getRequiredRule } from '../../../../utils/forms';
import { CityTeamMemberType } from '../../../../models/teamMembers';
import { CityTeamMemberInput } from '../../../../graphql/teamMembers';
import { useForm } from 'antd/es/form/Form';
import IntlMessage from '../../../../components/util-components/IntlMessage';
import { useEffect } from 'react';
import { GET_ASSOCIATED_CITIES_MIN } from '../../../../services/CityService';
import { City } from '../../../../models/places';
import { useQuery } from '@apollo/client';

export const EDIT_USER_CITY_TEAM_MEMBERSHIP_MODAL = 'modal.editUserCityTeamMembership';

const EditUserTeamMembershipComponent = () => {
  const dispatch = useDispatch();
  const { visibleModal } = useSelector((state: RootState) => state.modal);
  const { dataToEdit, editSubmitting } = useSelector((state: RootState) => state.views.users.detail.userCityTeamMembership);
  const { data, loading } = useQuery<{ cities: City[] }>(GET_ASSOCIATED_CITIES_MIN);
  const associatedCities = data?.cities ?? [];

  const [form] = useForm();

  useEffect(() => {
    form.setFieldsValue({
      city: dataToEdit?.city.id,
      type: dataToEdit?.type.toUpperCase(),
    });
  }, [form, dataToEdit]);

  const onFinish = ({ type }: Omit<CityTeamMemberInput, 'user'>) => {
    if (!dataToEdit) {
      return;
    }

    dispatch(editUserCityTeamMembershipSubmit(dataToEdit.id, {
      user: dataToEdit.user.id,
      city: dataToEdit.city.id,
      type,
    }));
  };

  return (
    <Modal title={intlFormatMessage('component.user_city_memberships.edit_membership_modal.title')}
           visible={visibleModal === EDIT_USER_CITY_TEAM_MEMBERSHIP_MODAL}
           onOk={() => form.submit()}
           okButtonProps={{ loading: editSubmitting }}
           onCancel={() => dispatch(cancelEditUserCityTeamMembership())}>
      <Form onFinish={onFinish} form={form}>
        <Form.Item label={intlFormatMessage('form.label.city')}
                   name='city'
                   rules={[getRequiredRule()]}>
          <Select loading={loading} disabled>
            {associatedCities.map((city) => (
              <Select.Option value={city.id} key={city.id}>
                {city.name}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item label={intlFormatMessage('form.label.type')}
                   name='type'
                   required={true}
                   rules={[{ required: true, message: intlFormatMessage('form.errors.required') }]}>
          <Select>
            {Object.keys(CityTeamMemberType).map((key) => (
              <Select.Option value={key} key={key}>
                <IntlMessage id={`cityTeamMember.type.${key.toLowerCase()}`} />
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default EditUserTeamMembershipComponent;
