import { all, call, fork, put, takeEvery, takeLatest } from 'redux-saga/effects';
import { ISSUE_DESCRIPTION_SUBMIT_NEW } from '../../../constants/views/issues/components/IssueDescriptionConstants';
import {
  submitNewIssueDescription,
  submitNewIssueDescriptionErrored, submitNewIssueDescriptionSucceeded,
} from '../../../actions/views/issues/components/IssueDescriptionAction';
import { IssueService } from '../../../../services/IssueService';
import { ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES } from '../../../constants/views/issues/components/IssueGroupComponentConstants';
import {
  dismissIssueGroupComponentEdit,
  newIssueGroupAndTypesSubmissionErrored,
  newIssueGroupAndTypesSubmissionSucceeded,
  submitNewIssueGroupAndTypes,
} from '../../../actions/views/issues/components/IssueGroupComponentAction';
import {
  ISSUE_IMAGE_COMPONENT_REMOVE_IMAGE,
  ISSUE_IMAGE_COMPONENT_START_UPLOAD,
} from '../../../constants/views/issues/components/IssueImageConstants';
import {
  issueImagesLoaded,
  issueImageUploadErrored,
  issueImageUploadSucceeded,
  removeIssueImage,
  removeIssueImageErrored,
  removeIssueImageSucceeded,
  startIssueImageUpload,
} from '../../../actions/views/issues/components/IssueImageComponentAction';
import { Issue } from '../../../../models/issues';

function* changeIssueDescriptionSaga() {
  yield takeLatest(ISSUE_DESCRIPTION_SUBMIT_NEW, function* ({
                                                              issueId,
                                                              description,
                                                            }: ReturnType<typeof submitNewIssueDescription>) {
    try {
      yield call(IssueService.edit, issueId, { description });
      yield put(submitNewIssueDescriptionSucceeded());
    } catch (e) {
      yield put(submitNewIssueDescriptionErrored());
    }
  });
}

function* changeIssueGroupAndTypesSaga() {
  yield takeLatest(ISSUE_GROUP_COMPONENT_SUBMIT_NEW_ISSUE_GROUP_AND_TYPES, function* ({
                                                                                        issueId,
                                                                                        input,
                                                                                      }: ReturnType<typeof submitNewIssueGroupAndTypes>) {
    try {
      yield call(IssueService.edit, issueId, input);
      yield put(newIssueGroupAndTypesSubmissionSucceeded());
      yield put(dismissIssueGroupComponentEdit());
    } catch (e) {
      yield put(newIssueGroupAndTypesSubmissionErrored(e.message));
    }
  });
}

function* uploadIssueImageSaga() {
  yield takeEvery(ISSUE_IMAGE_COMPONENT_START_UPLOAD, function* (payload: ReturnType<typeof startIssueImageUpload>) {
    try {
      const issue: Issue | null = yield call(IssueService.addImage, payload.issueId, payload.image);
      yield put(issueImagesLoaded(issue?.images || []));
      yield put(issueImageUploadSucceeded('notification.issue.images.upload_success'));
    } catch (e) {
      yield put(issueImageUploadErrored(e.message));
    }
  });
}

function* removeIssueImageSaga() {
  yield takeEvery(ISSUE_IMAGE_COMPONENT_REMOVE_IMAGE, function* ({
                                                                   issueId,
                                                                   filename,
                                                                 }: ReturnType<typeof removeIssueImage>) {
    try {
      const issue: Issue | null = yield call(IssueService.removeImage, issueId, filename);
      yield put(issueImagesLoaded(issue?.images || []));
      yield put(removeIssueImageSucceeded('notification.issue.images.remove_success'));
    } catch (e) {
      yield put(removeIssueImageErrored(e.message));
    }
  });
}

export default function* issueDetailSagas() {
  yield all([
    fork(changeIssueDescriptionSaga),
    fork(changeIssueGroupAndTypesSaga),
    fork(uploadIssueImageSaga),
    fork(removeIssueImageSaga),
  ]);
}
