import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  dismissUserNameEdit,
  submitNewUserName, submitNewUserNameFailed,
  submitNewUserNameSucceeded,
} from '../../../../actions/views/users/components/UserNameAction';
import { USER_NAME_SUBMIT } from '../../../../constants/views/users/components/UserNameConstants';
import { UserService } from '../../../../../services/UserService';

function* handleSubmitNewUserName({ userId, newName }: ReturnType<typeof submitNewUserName>) {
  try {
    yield call(UserService.edit, userId, { name: newName });
    yield put(submitNewUserNameSucceeded());
    yield put(dismissUserNameEdit());
  } catch (e) {
    yield put(submitNewUserNameFailed());
  }
}

export default function* userNameSagas() {
  yield all([
    takeLatest(USER_NAME_SUBMIT, handleSubmitNewUserName),
  ]);
}
