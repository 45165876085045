import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  dismissUserRoleEdit,
  submitNewUserRole, submitNewUserRoleFailed,
  submitNewUserRoleSucceeded,
} from '../../../../actions/views/users/components/UserRoleAction';
import { USER_ROLE_SUBMIT } from '../../../../constants/views/users/components/UserRoleConstants';
import { UserService } from '../../../../../services/UserService';

function* handleNewUserRoleSaga({ userId, newRole }: ReturnType<typeof submitNewUserRole>) {
  try {
    yield call(UserService.edit, userId, { role: newRole });
    yield put(submitNewUserRoleSucceeded());
    yield put(dismissUserRoleEdit());
  } catch (e) {
    yield put(submitNewUserRoleFailed());
  }
}

export default function* userRoleSagas() {
  yield all([
    takeLatest(USER_ROLE_SUBMIT, handleNewUserRoleSaga),
  ]);
}
