import antdEsES from 'antd/es/locale/es_ES';
import esMsg from '../locales/es_ES.json';

const EsLang = {
  antd: antdEsES,
  locale: 'es-ES',
  messages: {
    ...esMsg
  }
};

export default EsLang;
