import {
  NORMATIVE_DETAIL_CANCEL_NAME_EDIT,
  NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_CHANGED, NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_RESET,
  NORMATIVE_DETAIL_ISSUE_GROUP_VISIBILITY_CHANGED,
  NORMATIVE_DETAIL_START_NAME_EDIT,
  NORMATIVE_DETAIL_SUBMIT_ERROR,
  NORMATIVE_DETAIL_SUBMIT_NAME,
  NORMATIVE_DETAIL_SUBMIT_SUCCESSFUL,
  NORMATIVE_FETCHED,
} from '../../constants/views/NormativeDetailConstants';
import { IssueGroupVisibility, Normative } from '../../../models';
import { formSubmissionEndedWithErrors, formSubmissionSucceeded } from '../Form';

export type NormativeDetailAction = ReturnType<typeof normativeFetched
  | typeof startNormativeNameEdit
  | typeof cancelNormativeNameEdit
  | typeof submitNormativeEdit
  | typeof normativeEditSubmissionEndedSuccessfully
  | typeof normativeEditSubmissionEndedWithError
  | typeof formSubmissionSucceeded
  | typeof formSubmissionEndedWithErrors
  | typeof normativeDetailIssueGroupFilterChanged
  | typeof setIssueGroupVisibility
  | typeof resetIssueGroupFilter>;

export function normativeFetched(normative: Normative) {
  return <const>{
    type: NORMATIVE_FETCHED,
    normative,
  }
}

export function startNormativeNameEdit() {
  return <const>{
    type: NORMATIVE_DETAIL_START_NAME_EDIT,
  };
}

export function cancelNormativeNameEdit() {
  return <const>{
    type: NORMATIVE_DETAIL_CANCEL_NAME_EDIT,
  };
}

export function submitNormativeEdit(id: string, input: { name: string }) {
  return <const>{
    type: NORMATIVE_DETAIL_SUBMIT_NAME,
    id,
    input,
  };
}

export function normativeEditSubmissionEndedSuccessfully() {
  return <const>{
    type: NORMATIVE_DETAIL_SUBMIT_SUCCESSFUL,
  };
}

export function normativeEditSubmissionEndedWithError(errorMessage: string) {
  return <const>{
    type: NORMATIVE_DETAIL_SUBMIT_ERROR,
    errorMessage,
  };
}

export function normativeDetailIssueGroupFilterChanged(issueGroupFilter: string | undefined) {
  return <const>{
    type: NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_CHANGED,
    issueGroupFilter,
  }
}

export function setIssueGroupVisibility(visibility: IssueGroupVisibility) {
  return <const>{
    type: NORMATIVE_DETAIL_ISSUE_GROUP_VISIBILITY_CHANGED,
    showAllIssueGroups: visibility,
  };
}

export function resetIssueGroupFilter() {
  return <const>{
    type: NORMATIVE_DETAIL_ISSUE_GROUP_FILTER_RESET,
  }
}
